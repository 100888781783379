<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
  </div>
  <div v-else>
    <Modal :open="showModal" title="Add New Writeup Question">
      <div class="my-14 px-2">
        <div class="w-60">
          <label for="name" class="block text-sm font-bold text-gray-700">Select Worktype </label>
          <select
            class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
            v-model="selectedWorktype"
          >
            <option v-for="option in newWriteupQuestion?.selectOptions" :value="option">
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="py-4">
          <label for="name" class="block text-sm font-bold text-gray-700">Question Template</label>
          <p class="mt-1 text-xs leading-6 text-gray-500">
            Template should contain all mentioned properties wrapped in curly braces.
          </p>
          <p class="mb-3 text-xs text-gray-500" v-html="'Weed Killer with area of {{area}}'"></p>
          <input
            type="text"
            class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
            v-model="newQuestionData.template"
            placeholder="Enter template text"
          />
          <div v-if="Object.keys(newQuestionData).length !== 0">
            <p class="py-2 block text-sm font-bold text-gray-700">Properties</p>
            <div class="flex">
              <div v-for="(value, key) in newQuestionData.templateBindings" :key="key" class="mr-6">
                <span class="flex select-none items-center text-gray-500 sm:text-sm">{{
                  key
                }}</span>
                <select
                  v-if="key == 'smr'"
                  v-model="newQuestionData.inputVariables[key]"
                  class="block w-16 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                >
                  <option v-for="option in ['s', 'm', 'r']" :value="option">
                    {{ option }}
                  </option>
                </select>
                <select
                  v-else-if="key == 'number_of_coats'"
                  v-model="newQuestionData.inputVariables[key]"
                  class="block w-16 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                >
                  <option v-for="option in ['1', '2']" :value="option">
                    {{ option }}
                  </option>
                </select>
                <input
                  v-else
                  type="text"
                  class="block w-16 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                  v-model="newQuestionData.inputVariables[key]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
        @click="handleModalCancel"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
        @click="handleAddQuestion"
      >
        Submit
      </button>
    </Modal>
    <ConfirmationModal
      :showModal="confirmationModal.open"
      :title="confirmationModal.title"
      :text="confirmationModal.text"
      @close="confirmationModal.open = false"
      @confirm="handleModalConfirm"
    />
    <div id="printSection">
      <InfoBar class="print-hidden" message="You can drag and drop selected scoped items" />
      <WriteupSection
        v-for="section in getWriteupSections.result.value.writeupSections.nodes.filter(
          (item) => item.questions.length
        )"
        :title="section.name"
        :populatedQuestions="section.submittedQuestions"
        :subsection="section.children"
        :questions="section.questions"
        @update-data="handleUpdateData"
        @refetch-data="refetchData"
        @add-writeup-question="addNewWriteupQuestion"
        @add-new-writeup-question="handleAddNewWriteupQuestion"
        @update-questions="handleUpdateQuestions"
      />
    </div>
    <div class="grid grid-cols-4 items-center px-12">
      <div class="flex items-center text-sm font-medium text-gray-500">Proposal Map</div>
      <div class="p-6 w-96">
        <div v-if="proposalByIdQuery.result.value.proposal.mapImageUrl">
          <img
            class="size-20 mb-10 rounded-lg"
            :src="proposalByIdQuery.result.value.proposal.mapImageUrl"
            alt="image description"
          />
        </div>
        <dd class="bg-primary-50 mt-1 text-sm text-gray-900 sm:mt-0">
          <ul role="list" class="rounded-md border border-gray-200">
            <li class="flex items-center py-3 pl-3 pr-4 text-sm">
              <div class="flex w-0 flex-1 items-center px-5">
                <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                <span class="ml-2 w-0 flex-1 truncate"></span>
              </div>
              <div class="ml-4">
                <input
                  id="logo"
                  class="block w-full text-sm text-primary-600 cursor-pointer"
                  type="file"
                  ref="fileInput"
                  @change="uploadProposalMap"
                />
              </div>
              <div v-if="proposalByIdQuery.result.value.proposal.mapImageUrl" class="flex">
                <EyeIcon
                  @click="handlePreviewImage"
                  class="h-4 w-4 mx-1 text-gray-500 cursor-pointer"
                />
                <TrashIcon
                  @click="handleRemoveImage"
                  class="h-4 w-4 mx-1 text-gray-500 cursor-pointer"
                />
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </div>

    <div class="px-10 pb-10 flex justify-start col-span-2">
      <button
        @click="handleSubmit"
        :disabled="disableSubmitButton"
        :class="{
          'cursor-not-allowed': disableSubmitButton,
        }"
        type="submit"
        class="inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { useRoute } from 'vue-router'
import { PaperClipIcon, EyeIcon, TrashIcon } from '@heroicons/vue/20/solid'
import axios from 'axios'

import Loader from '@/components/layout/Loader.vue'
import WriteupSection from '@/components/layout/WriteupSection.vue'
import Modal from '@/components/layout/Modal.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'
import GET_PROPOSAL_BY_ID from '../../graphql/queries/getProposalById.gql'
import GET_WRITEUP_QUESTIONS from '../../graphql/queries/getWriteupQuestions.gql'
import GET_WRITEUP_SECTIONS from '../../graphql/queries/getWriteupSections.gql'
import GET_CONSTRUCTION_WORK_DATA from '../../graphql/queries/getConstructionWorkData.gql'
import CREATE_WRITEUP from '../../graphql/mutations/createWriteup.gql'
import UPDATE_WRITEUP from '../../graphql/mutations/updateWriteup.gql'
import GET_PROPOSAL_SERVICE from '../../graphql/queries/getProposalService.gql'
import REMOVE_WRITEUP_QUESTION from '../../graphql/mutations/removeWriteupQuestion.gql'
import CREATE_PROPOSALS_SERVICES_ITEM from '../../graphql/mutations/createProposalsServicesItem.gql'
import CREATE_MISC_SERVICE from '../../graphql/mutations/createMiscService.gql'
import GET_GRADING_FIELD_DATA from '../../graphql/queries/getGradingDemoFieldData.gql'
import UPDATE_PROPOSAL_WORK from '../../graphql/mutations/updateProposalWork.gql'

import { toSnakeCase } from '../../utils/utility_methods'
import { getCookie } from '../../utils/utility_methods'
import ConfirmationModal from '../modals/ConfirmationModal.vue'
import InfoBar from '@/components/layout/InfoBar.vue'

const route = useRoute()
const getWriteupQuestions = useQuery(GET_WRITEUP_QUESTIONS)
const getWriteupSections = useQuery(GET_WRITEUP_SECTIONS, {
  proposalId: route.params.id,
})
const createWriteup = useMutation(CREATE_WRITEUP)
const updateWriteup = useMutation(UPDATE_WRITEUP)
const proposalRemoveWriteupQuestion = useMutation(REMOVE_WRITEUP_QUESTION)
const proposalServices = useQuery(GET_PROPOSAL_SERVICE, {
  id: route.params.id,
})
const proposalsServicesMiscCreate = useMutation(CREATE_MISC_SERVICE)
const proposalsServicesItemCreate = useMutation(CREATE_PROPOSALS_SERVICES_ITEM)

const updateProposalWork = useMutation(UPDATE_PROPOSAL_WORK)
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const constructionWorkData = useQuery(GET_CONSTRUCTION_WORK_DATA)
const miscDataQuery = useQuery(GET_GRADING_FIELD_DATA)

const payloadData = ref({})
const fileInput = ref(null)
const editedQuestions = ref([])
const showModal = ref(false)
const newWriteupQuestion = ref(null)
const selectedWorktype = ref(null)
const newQuestionData = ref({})
const showLoading = ref(false)
const confirmationModal = ref({
  open: false,
  proceed: false,
  title: 'Writeup Questions',
  text: 'There are some uncomplete writeup questions whose values are missing. Do you want to Proceed',
})

onMounted(() => {
  window.addEventListener('keydown', handleKeyDown)
})
onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeyDown)
})

const handleKeyDown = (e) => {
  if ((e.ctrlKey || e.metaKey) && e.key === 'p') {
    e.preventDefault()
    printSection()
  }
}

const printSection = () => {
  const printContent = document.getElementById('printSection').innerHTML
  const printWindow = window.open('', '_blank')
  const tailwindCssLink =
    '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/tailwindcss@latest/dist/tailwind.min.css" type="text/css" />'

  printWindow.document.write(`
      <html>
        <head>
          <title>Writeup</title>
          ${tailwindCssLink}
          <style>
          .print-hidden { display: none !important; }
          .print-show { display: inline !important; }
        </style>
        </head>
        <body>
          ${printContent}
        </body>
      </html>`)
  printWindow.document.close()
  printWindow.focus()
  printWindow.print()
}

const loading = computed(() => {
  return (
    miscDataQuery.loading.value ||
    getWriteupQuestions.loading.value ||
    getWriteupSections.loading.value ||
    proposalByIdQuery.loading.value ||
    proposalServices.loading.value ||
    constructionWorkData.loading.value ||
    showLoading.value
  )
})

const showConfirmationModal = computed(
  () =>
    (!questionValuesFilled.value &&
      !confirmationModal.value.proceed &&
      editedQuestions.value.length == 0) ||
    (editedQuestions.value.length !== 0 &&
      !editQuestionValuesFilled.value &&
      !confirmationModal.value.proceed)
)

watch(selectedWorktype, (value) => {
  if (value) {
    newQuestionData.value = {
      ...value,
      inputVariables: value.properties.reduce(
        (obj, cur) => ({ ...obj, [toSnakeCase(cur.name)]: null }),
        {}
      ),
      templateBindings: value.properties.reduce(
        (obj, cur) => ({ ...obj, [toSnakeCase(cur.name)]: null }),
        {}
      ),
      template: '',
      writeupSection: newWriteupQuestion.value.service,
      newQuestion: true,
    }
  }
})

watch(getWriteupSections.result, (value) => {
  initializePayload()
})

const handleUpdateData = async (questionItem) => {
  if (questionItem.populatedTemplate && !questionItem.showInProposal) {
    showLoading.value = true
    await proposalRemoveWriteupQuestion.mutate({
      input: { ids: [questionItem.id] },
    })
    showLoading.value = false
    await getWriteupSections.refetch()
  } else {
    if (questionItem.showInProposal && Object.keys(questionItem.inputVariables).length !== 0) {
      payloadData.value[questionItem.writeupSection.name] = [
        ...payloadData.value[questionItem.writeupSection.name],
        questionItem,
      ]
    } else if (
      questionItem.showInProposal &&
      Object.keys(questionItem.inputVariables).length == 0
    ) {
      payloadData.value[questionItem.writeupSection.name] = [
        ...payloadData.value[questionItem.writeupSection.name],
        questionItem,
      ]
    } else {
      payloadData.value = {
        ...payloadData.value,
        [questionItem.writeupSection.name]: payloadData.value[
          questionItem.writeupSection.name
        ].filter((filterItem) => filterItem.id !== questionItem.id),
      }
    }
  }
}

const refetchData = async () => {
  await getWriteupSections.refetch()
}

const handleSubmit = async () => {
  if (showConfirmationModal.value) {
    confirmationModal.value.open = true
  } else {
    showLoading.value = true
    if (editedQuestions.value.length !== 0) {
      await Promise.all(
        editedQuestions.value.map(async (question) => {
          await updateWriteup.mutate({
            input: {
              input: {
                id: question.id,
                templateBindings: JSON.stringify(question.inputVariables),
                template: question.template,
              },
            },
          })
        })
      )
    } else {
      await Promise.all(
        Object.keys(payloadData.value).map(async (sectioneName) => {
          if (payloadData.value[sectioneName].length) {
            await Promise.all(
              payloadData.value[sectioneName].map(async (item, index) => {
                const services = item.lchWork
                  ? item.lchWork.services.nodes.map((service) => service.id)
                  : [item.services[0].id]
                const createWriteupResponse = await createWriteup.mutate({
                  input: {
                    input: {
                      proposalId: route.params.id,
                      proposalsServiceIds: services.map((service) => {
                        return proposalServices.result.value.proposalsService.nodes.find(
                          (serviceItem) => serviceItem.service.id === service
                        ).id
                      }),
                      writeupQuestionId: item.id,
                      template: item.template,
                      templateBindings: JSON.stringify(item.inputVariables),
                      position:
                        getWriteupQuestionPosition(item.writeupSection) == 0 ||
                        !getWriteupQuestionPosition(item.writeupSection)
                          ? index + 1
                          : getWriteupQuestionPosition(item.writeupSection) + 1 + index,
                    },
                  },
                })
                if (
                  item.workTypes.nodes[0]?.name == 'Static Asphalt' ||
                  item.workTypes.nodes[0]?.name == 'Static Seal Coat' ||
                  item.workTypes.nodes[0]?.name == 'Static Concrete'
                ) {
                  await updateProposalWork.mutate({
                    input: {
                      input: {
                        id: createWriteupResponse.data.writeupCreate.submittedQuestion
                          .proposalsServicesWorks.nodes[0].id,
                        hidden: true,
                      },
                    },
                  })
                }
                if (item.workTypes.nodes[0]?.material?.name == 'Saw Cut') {
                  const miscellaneousItem = item.services.some(
                    (service) => service.name === 'Asphalt'
                  )
                    ? 'Asphalt Saw Cutting'
                    : 'Concrete Saw Cutting'
                  const miscellaneousCost =
                    miscDataQuery.result.value.gradingMiscellaneousCost.nodes?.filter(
                      (miscItem) => miscItem.name == miscellaneousItem
                    )[0]
                  const createMiscService = await proposalsServicesMiscCreate.mutate({
                    input: {
                      input: {
                        miscellaneousCostId: miscellaneousCost.id,
                        name: miscellaneousCost.name,
                        quantity:
                          createWriteupResponse.data.writeupCreate.submittedQuestion
                            .proposalsServicesWorks.nodes[0].quantity,
                        costPerUnit: miscellaneousCost.costPerUnit,
                        unitOfMeasurement: miscellaneousCost.unitOfMeasurement,
                      },
                    },
                  })
                  await proposalsServicesItemCreate.mutate({
                    input: {
                      input: {
                        proposalsServiceId:
                          proposalServices.result.value.proposalsService.nodes.find(
                            (serviceItem) => serviceItem.service.name === 'Grading'
                          ).id,
                        itemId:
                          createMiscService.data.proposalsServicesMiscCreate
                            .proposalsServicesMisc[0].id,
                      },
                    },
                  })
                }
              })
            )
          }
        })
      )
    }
    confirmationModal.value.open = false
    confirmationModal.value.proceed = false
    showLoading.value = false
    initializePayload()
    editedQuestions.value = []
    showModal.value = false
    selectedWorktype.value = null
    newQuestionData.value = {}
    await proposalByIdQuery.refetch()
    await getWriteupSections.refetch()
  }
}

const handleModalCancel = () => {
  showModal.value = false
  newWriteupQuestion.value = null
}

const handleAddQuestion = async () => {
  payloadData.value[newQuestionData.value.writeupSection.name] = [newQuestionData.value]
  await handleSubmit()
}

const addNewWriteupQuestion = async (questionData) => {
  payloadData.value[questionData.writeupSection.name] = [questionData]
  await handleSubmit()
}

const handleAddNewWriteupQuestion = (work, section, service) => {
  const sectionData = getWriteupSectionData(section ? section.title : service)[0]
  newWriteupQuestion.value = {
    selectOptions: constructionWorkData.result.value[work]['nodes'],
    service: { id: sectionData.id, name: sectionData.name },
  }
  showModal.value = true
}

const getWriteupSectionData = (sectionName) => {
  return getWriteupSections.result.value.writeupSections.nodes.filter(
    (section) => section.name == sectionName
  )
}

const getWriteupQuestionPosition = (questionSection) =>
  findSectionById(questionSection)?.submittedQuestions.length

const findSectionById = (questionSection) => {
  const sections = getWriteupSections.result.value.writeupSections.nodes
  for (const section of sections) {
    if (section.id === questionSection.id) {
      return section
    } else {
      const foundChild = section.children?.find((childObj) => childObj.id === questionSection.id)
      if (foundChild) {
        return foundChild
      }
    }
  }
  return null
}

const editQuestionValuesFilled = computed(() => {
  if (editedQuestions.value.length > 0) {
    return editedQuestions.value.every((obj) => {
      for (const key in obj.inputVariables) {
        if (obj.inputVariables[key] == '') {
          return false
        }
      }
      return Object.keys(obj.inputVariables).length == obj.templateBoundProperties.length
    })
  }
  return false
})

const questionValuesFilled = computed(() => {
  return Object.values(payloadData.value).some((array) => {
    if (array.length > 0) {
      return array.every((obj) => {
        let result = {}
        for (let key in JSON.parse(obj.templateBindings)) {
          if (
            !obj.inputVariables.hasOwnProperty(key) &&
            JSON.parse(obj.templateBindings)[key] !== null &&
            JSON.parse(obj.templateBindings)[key] !== ''
          ) {
            result[key] = JSON.parse(obj.templateBindings)[key]
          }
        }

        for (const key in obj.inputVariables) {
          if (obj.inputVariables[key] == '') {
            return false
          }
        }
        if (Object.keys(obj.inputVariables).length && Object.keys(result).length) {
          return true
        }

        return (
          Object.keys(obj.inputVariables).length ==
          Object.keys(JSON.parse(obj.templateBindings)).length
        )
      })
    }
    return false
  })
})

const disableSubmitButton = computed(
  () =>
    !Object.values(payloadData.value).some((item) => item.length) &&
    editedQuestions.value.length == 0
)

const initializePayload = () => {
  getWriteupSections.result.value.writeupSections.nodes.forEach((item) => {
    payloadData.value[item.name] = []
    item.children.forEach((child) => {
      payloadData.value[child.name] = []
    })
  })
}

const handleUpdateQuestions = (data) => {
  data.forEach((item) => {
    const exists = editedQuestions.value.some(
      (existingItem) => existingItem.id === item.id && existingItem.itemId === item.itemId
    )
    if (!exists) {
      editedQuestions.value.push(item)
    }
  })
}

const handleModalConfirm = () => {
  confirmationModal.value.proceed = true
  handleSubmit()
}

const uploadProposalMap = async () => {
  const file = fileInput.value.files[0]
  const formData = new FormData()
  formData.append('proposal[images]', file)
  try {
    await axios.post(
      `/proposals/${proposalByIdQuery.result.value.proposal.id}/attach_files`,
      formData,
      {
        headers: {
          'X-CSRF-Token': getCookie('csrf_token'),
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    await proposalByIdQuery.refetch()
  } catch (error) {
    console.log(error)
  }
}

const handlePreviewImage = () => {
  window.open(proposalByIdQuery.result.value.proposal.mapImageUrl, '_blank')
}

const handleRemoveImage = async () => {
  try {
    await axios.post(
      `/proposals/${proposalByIdQuery.result.value.proposal.id}/remove_images`,
      {},
      {
        headers: {
          'X-CSRF-Token': getCookie('csrf_token'),
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    await proposalByIdQuery.refetch()
  } catch (error) {
    console.log(error)
  }
}
</script>
