<template>
  <select
    v-model="model"
    @input="$emit('onInput', $event)"
    @change="$emit('onChange', $event)"
    class="w-24 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
    :class="{ 'border-gray-400': !error, 'border-red-400 ': error }"
  >
    <option class="text-xs text-red-200" disabled value="undefined">select</option>
    <option v-for="option in options" :value="option" :key="option" :selected="option == model">
      {{ option }}
    </option>
  </select>
  <p v-if="error" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
    {{ error }}
  </p>
</template>

<script setup>
const model = defineModel({ required: true })
const emit = defineEmits(['onChange', 'onInput'])

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    required: false,
  },
  error: {
    type: String,
    default: '',
  },
})
</script>
