<script setup>
import PropertyBaseFormInput from './PropertyBaseFormInput.vue'
import PropertyBaseSelectInput from './PropertyBaseFormSelect.vue'

const SelectType = 'select'

const propertyValue = defineModel()
const props = defineProps({
  property: {
    type: Object,
  },
  data: {
    type: Object,
  },
  handleTextWidth: Function,
  handleInput: Function,
})
</script>

<template>
  <div class="inline-block" v-if="property">
    <div v-if="property?.type === SelectType">
      <PropertyBaseSelectInput
        v-model="propertyValue"
        :options="property.defaultValue"
        :label="property.variable"
        @on-change="handleInput(data.element, data.part)"
        @on-input="handleTextWidth($event, data.element, data.part, data.sectionQuestions)"
      />
    </div>
    <div v-else>
      <PropertyBaseFormInput
        v-model="propertyValue"
        :type="property.type"
        :label="property.variable"
        :placeholder="property.variable"
        @on-change="handleInput(data.element, data.part)"
        @on-input="handleTextWidth($event, data.element, data.part, data.sectionQuestions)"
      />
    </div>
  </div>
</template>
