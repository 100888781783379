<template>
  <Sidebar v-if="!loginRoute">
    <router-view />
  </Sidebar>
  <router-view v-else />
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import Sidebar from '@/components/layout/Sidebar.vue'

const router = useRouter()
const loginRoute = computed(() => {
  const path = router.currentRoute.value.path
  return (
    path === '/login' ||
    path === '/sign_up' ||
    path === '/password/reset' ||
    path === '/reset_password'
  )
})
</script>
