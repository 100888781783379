<template>
  <Loader v-if="materialsCostDataQuery.loading.value || companyDataQuery.loading.value" />
  <div v-else>
    <Modal :open="showModal" :title="materialsCostDetails.name">
      <form>
        <div class="grid grid-cols-2 gap-4 my-10">
          <div class="w-60">
            <label for="name" class="block text-sm font-medium text-gray-700">Cost Per Unit </label>
            <div class="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
              <input
                type="number"
                v-model="materialsCostDetails.costPerUnit"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="Enter Cost Per Unit"
              />
            </div>
          </div>
          <div class="w-60">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Unit of Measurement
            </label>
            <div class="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
              <input
                type="text"
                v-model="materialsCostDetails.unitOfMeasurement"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="Enter Cost Per Unit"
              />
            </div>
          </div>
          <div class="flex">
            <div class="ml-3 text-sm">
              <label for="name" class="block text-sm font-medium text-gray-700"
                >Variable Cost
              </label>
            </div>
            <div class="flex h-5 items-center pl-3">
              <input
                type="checkbox"
                :checked="materialsCostDetails.variableCost"
                v-model="materialsCostDetails.variableCost"
                class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
              />
            </div>
          </div>
        </div>
      </form>

      <div class="flex justify-end mx-5 py-4">
        <button
          type="button"
          class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
          @click="showModal = false"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
          @click="handleSubmit"
        >
          Submit
        </button>
      </div>
    </Modal>
    <div class="flex my-4 px-1 flex-row justify-between items-center">
      <h1 class="text-2xl font-semibold text-gray-900">Materials Cost</h1>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-primary-50">
                <tr>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Name
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Cost Per Unit
                  </th>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Variable Cost
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Unit Of Measurement
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="material in materialsCostDataQuery.result.value.materials.nodes"
                  :key="material.id"
                >
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ material.name }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ material.cost ? material.cost.costPerUnit : '---' }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ material.cost ? material.cost.variableCost : '---' }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ material.cost ? material.cost.unitOfMeasurement : '---' }}
                  </td>

                  <td class="relative whitespace-nowrap py-4 text-sm font-medium">
                    <div class="flex">
                      <div class="px-1">
                        <PencilSquareIcon
                          @click="handleEdit(material)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/20/solid'
import Loader from '@/components/layout/Loader.vue'

import Modal from '@/components/layout/Modal.vue'
import GET_MATERIALS_COST_DATA from '../../graphql/queries/getAllMaterialsCost.gql'
import UPDATE_MATERIALS_COST_DATA from '../../graphql/mutations/admin/updateMaterialsCost.gql'
import CREATE_MATERIALS_COST_DATA from '../../graphql/mutations/admin/createMaterialsCost.gql'
import GET_COMPANY_DATA from '../../graphql/queries/getCompanyData.gql'

const showModal = ref(false)
const materialsCostDetails = ref({
  variableCost: false,
  costPerUnit: null,
})
const editModal = ref(false)
const updateMaterialCost = ref(true)

const updateMaterialsCost = useMutation(UPDATE_MATERIALS_COST_DATA)
const createMaterialsCost = useMutation(CREATE_MATERIALS_COST_DATA)
const materialsCostDataQuery = useQuery(GET_MATERIALS_COST_DATA)
const companyDataQuery = useQuery(GET_COMPANY_DATA)

const handleSubmit = async () => {
  if (editModal.value) {
    try {
      if (updateMaterialCost.value) {
        await updateMaterialsCost.mutate({
          input: {
            input: {
              id: materialsCostDetails.value.costId,
              costPerUnit: parseFloat(materialsCostDetails.value.costPerUnit),
              variableCost: materialsCostDetails.value.variableCost,
              unitOfMeasurement: materialsCostDetails.value.unitOfMeasurement,
            },
          },
        })
      } else {
        await createMaterialsCost.mutate({
          input: {
            input: {
              materialId: materialsCostDetails.value.id,
              organizationId: companyDataQuery.result.value.organization.id,
              unitOfMeasurement: materialsCostDetails.value.unitOfMeasurement,
              costPerUnit: parseFloat(materialsCostDetails.value.costPerUnit),
              variableCost: materialsCostDetails.value.variableCost,
            },
          },
        })
      }
      showModal.value = false
      await materialsCostDataQuery.refetch()
    } catch (error) {
      console.log('error ', error)
    }
  }
}

const handleEdit = (item) => {
  if (!item.cost) {
    updateMaterialCost.value = false
  }
  editModal.value = true
  materialsCostDetails.value = {
    name: item.name,
    id: item.id,
    costId: item.cost?.id,
    costPerUnit: item.cost ? item.cost.costPerUnit : 0,
    variableCost: item.cost ? item.cost.variableCost : false,
    unitOfMeasurement: item.cost ? item.cost.unitOfMeasurement : '',
  }
  showModal.value = true
}
</script>
