export const estimationTabs = [
  { name: 'mappingTool', displayName: 'Mapping', path: 'mapping-tool', current: false },
  { name: 'Writeup', displayName: 'Writeup', path: `writeup`, current: false },
  { name: 'Asphalt', displayName: 'Asphalt/Grading', path: `asphalt`, current: true },
  { name: 'Concrete', displayName: 'Concrete/Grading', path: 'concrete', current: false },
  { name: 'Grading', displayName: 'Demo/Grading', path: 'grading-demo', current: false },
  { name: 'Seal Coating', displayName: 'Seal Coat', path: 'seal-coat', current: false },
  { name: 'Striping', displayName: 'Striping', path: 'striping', current: false },
  { name: 'subcontractors', displayName: 'Subcontractors', path: 'subcontractors', current: false },
  { name: 'FinalBid', displayName: 'Final Bid Total', path: 'final-bid', current: false },
  { name: 'Options', displayName: 'Options', path: 'options', current: false },
]

export const finalBidTab = {
  Asphalt: [
    {
      show: true,
      name: 'Construction Materials',
      subTotal: '0',
      markup: 'materialMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'constructionTotal',
    },
    {
      show: true,
      name: 'Asphalt Equipment',
      subTotal: '0',
      markup: 'equipmentMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'equipmentTotal',
    },
    {
      show: true,
      name: 'Asphalt Labor',
      subTotal: '0',
      markup: 'laborMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'laborerTotal',
    },
    {
      show: true,
      name: 'Grading Equipment',
      subTotal: '0',
      markup: 'gradingEquipmentMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'gradingEquipment',
    },
    {
      show: true,
      name: 'Grading Labor',
      subTotal: '0',
      markup: 'gradingLaborMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'gradingLabor',
    },
    {
      show: true,
      name: 'Load Cut Haul',
      subTotal: '0',
      markup: 'lchMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'lchTotal',
    },
    {
      show: true,
      name: 'Miscellaneous Cost',
      subTotal: '0',
      markup: 'miscellaneousMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'miscellaneousCostTotal',
    },
    {
      show: true,
      name: 'Subcontractor Cost',
      subTotal: '0',
      markup: 'subcontractorMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'subcontractorTotal',
    },

    {
      show: false,
      name: 'Total Markup',
      subTotal: '0',
      markup: 'totalMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'totals',
    },
  ],

  Concrete: [
    {
      show: true,
      name: 'Concrete Materials',
      subTotal: '0',
      markup: 'materialMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'constructionTotal',
    },
    {
      show: true,
      name: 'Concrete Equipment',
      subTotal: '0',
      markup: 'equipmentMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'equipmentTotal',
    },
    {
      show: true,
      name: 'Concrete Labor	',
      subTotal: '0',
      markup: 'laborMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'laborerTotal',
    },

    {
      show: true,
      name: 'Grading Equipment',
      subTotal: '0',
      markup: 'gradingEquipmentMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'gradingEquipment',
    },
    {
      show: true,
      name: 'Grading Labor',
      subTotal: '0',
      markup: 'gradingLaborMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'gradingLabor',
    },
    {
      show: true,
      name: 'Load Cut Haul',
      subTotal: '0',
      markup: 'lchMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'lchTotal',
    },
    {
      show: true,
      name: 'Miscellaneous Cost',
      subTotal: '0',
      markup: 'miscellaneousMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'miscellaneousCostTotal',
    },
    {
      show: true,
      name: 'Subcontractor Cost',
      subTotal: '0',
      markup: 'subcontractorMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'subcontractorTotal',
    },

    {
      show: false,
      name: 'Total Markup',
      subTotal: '0',
      markup: 'totalMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'totals',
    },
  ],

  Grading: [
    {
      show: true,
      name: 'Grading Equipment',
      subTotal: '0',
      markup: 'equipmentMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'equipmentTotal',
    },
    {
      show: true,
      name: 'Grading Labor',
      subTotal: '0',
      markup: 'laborMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'laborerTotal',
    },
    {
      show: true,
      name: 'Load Cut Haul',
      subTotal: '0',
      markup: 'lchMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'lchTotal',
    },

    {
      show: true,
      name: 'Miscellaneous Costs',
      subTotal: '0',
      markup: 'miscellaneousMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'miscellaneousCostTotal',
    },
    {
      show: true,
      name: 'Subcontractor Cost',
      subTotal: '0',
      markup: 'subcontractorMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'subcontractorTotal',
    },
    {
      show: false,
      name: 'Total Markup',
      subTotal: '0',
      markup: 'totalMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'totals',
    },
  ],

  SealCoating: [
    {
      show: true,
      name: 'Seal Coat Materials',
      subTotal: '0',
      markup: 'materialMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'constructionTotal',
    },
    {
      show: true,
      name: 'Seal Coat Equipment',
      subTotal: '0',
      markup: 'equipmentMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'equipmentTotal',
    },
    {
      show: true,
      name: 'Seal Coat Labor',
      subTotal: '0',
      markup: 'laborMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'laborerTotal',
    },
    {
      show: true,
      name: 'Miscellaneous Cost',
      subTotal: '0',
      markup: 'miscellaneousMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'miscellaneousCostTotal',
    },
    {
      show: true,
      name: 'Subcontractor Cost',
      subTotal: '0',
      markup: 'subcontractorMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'subcontractorTotal',
    },
    {
      show: false,
      name: 'Total Markup',
      subTotal: '0',
      markup: 'totalMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'totals',
    },
  ],

  Striping: [
    {
      show: true,
      name: 'Striping',
      subTotal: '0',
      markup: 'stripingMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'constructionTotal',
    },
    {
      show: true,
      name: 'Miscellaneous Cost',
      subTotal: '0',
      markup: 'miscellaneousMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'miscellaneousCostTotal',
    },
    {
      show: true,
      name: 'Subcontractor Cost',
      subTotal: '0',
      markup: 'subcontractorMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'subcontractorTotal',
    },
    {
      show: false,
      name: 'Total Markup',
      subTotal: '0',
      markup: 'totalMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'totals',
    },
  ],

  subcontractorsCost: [
    {
      show: true,
      name: 'Subcontractor',
      subTotal: '0',
      markup: 'subcontractorMarkup',
      markupAmount: '0',
      cost: '0',
      total: 'total',
    },
  ],

  totalEstimatedHardCost: [
    { name: 'Total Material Costs', percentage: 0.0, amount: 0.0, key: 'constructionTotal' },
    { name: 'Total Equipment Costs', percentage: 0.0, amount: 0.0, key: 'equipmentTotal' },
    { name: 'Total Labor Costs', percentage: 0.0, amount: 0.0, key: 'laborerTotal' },
    {
      name: 'Total Miscellaneous Costs',
      percentage: 0.0,
      amount: 0.0,
      key: 'miscellaneousCostTotal',
    },
    { name: 'Total Striping Costs', percentage: 0.0, amount: 0.0, key: 'stripingTotal' },
    { name: 'Total Subcontractor Costs', percentage: 0.0, amount: 0.0, key: 'subcontractorTotal' },
    { name: 'Total Load Cut Haul Cost', percentage: 0.0, amount: 0.0, key: 'lchTotal' },
    {
      name: 'General Admin Cost',
      percentage: 0.0,
      amount: 0.0,
      key: 'generalAdminCost',
      edit: true,
    },
    { name: 'Current Total Mark Up % and $', percentage: 0.0, amount: 0.0, key: 'markupTotal' },
    // {
    //   name: 'Additional Mark Up % (Owners Discretion)',
    //   percentage: 0.0,
    //   amount: 0.0,
    //   key: 'additionalMarkupPercentage',
    //   edit: true,
    // },
    {
      name: 'Discount %',
      percentage: 0.0,
      amount: 0.0,
      key: 'discountPercentage',
      edit: true,
    },
    { name: 'Total Estimate / Proposal', percentage: 0.0, amount: 0.0, key: 'estimationTotal' },
  ],

  totalMaterialCosts: [
    { name: 'Permits', checked: false },
    { name: 'Water Costs', checked: false },
    { name: 'Compaction Testing', checked: false },
    { name: 'Grading', checked: false },
    { name: 'Soil Testing', checked: false },
    { name: 'SWPPS', checked: false },
    { name: 'Traffic Control', checked: false },
    { name: 'Prevailing Wages', checked: false },
    { name: 'Adjusting Utilities', checked: false },
    { name: 'Adjusting Manholes', checked: false },
    { name: 'Cosmetic Purposes', checked: false },
    { name: 'No Warranty', checked: false },
  ],
}

export const tabColumns = (publicWorks) => {
  return {
    constructionMaterials: {
      columns: [
        { name: 'name', displayName: 'Material', edit: false },
        { name: 'rate', displayName: 'Estimated SQ FT / LN FT', edit: true },
        { name: 'hours', displayName: 'Depth In Inches	', edit: true },
        { name: 'rate', displayName: 'Qty Of Material	', edit: false },
        { name: 'price', displayName: 'Unit of Measurement', edit: false },
        { name: 'price', displayName: 'Cost Per Unit	', edit: true, cost: true },
        { name: 'hours', displayName: 'Extended Cost', edit: false },
        { displayName: 'Action', edit: false },
      ],
    },
    equipment: {
      columns: [
        {
          name: 'name',
          displayName: 'Equipment',
          edit: true,
          type: 'text',
        },
        { name: 'quantity', displayName: 'Quantity', edit: true },
        { name: 'hoursPerDay', displayName: 'Hours Per Day', edit: true },
        { name: 'totalDaysOnJob', displayName: 'Total Days On Job', edit: true },
        { name: 'costPerUnit', displayName: 'Rate Per Hour', edit: false, cost: true },
        {
          name: 'equipmentCost',
          displayName: 'Equipment Cost',
          edit: false,
          initialValue: 0,
          cost: true,
        },
        { name: 'fuelChargePercentage', displayName: 'Fuel Charge', edit: false },
        { name: 'totalCost', displayName: 'Total Cost', edit: false, initialValue: 0, cost: true },
        { displayName: 'Action', edit: false },
      ],
    },
    labor: {
      columns: [
        { name: 'name', displayName: 'Labor', edit: true, type: 'text' },
        { name: 'quantity', displayName: 'Quantity Of Men', edit: true },
        { name: 'hoursOnSite', displayName: 'Hours On Site', edit: true },
        { name: 'daysOnSite', displayName: 'Days On Job Site', edit: true },
        { name: 'totalHours', displayName: 'Total Hours', edit: false, initialValue: 0 },
        {
          name: publicWorks ? 'publicWorksCostPerUnit' : 'costPerUnit',
          displayName: 'Cost Per Hour',
          edit: false,
          cost: true,
        },
        {
          name: 'regularHours',
          displayName: 'Regular Paid Hours',
          edit: false,
          initialValue: 0,
        },
        {
          name: 'overtimeHours',
          displayName: 'Overtime Paid Hours',
          edit: false,
          initialValue: 0,
        },
        {
          name: 'overtimeCostPerUnit',
          displayName: 'Overtime Rate/hour',
          edit: false,
          nestedKey: 'laborer',
          cost: true,
        },
        {
          name: 'totalRegularPay',
          displayName: 'Total Regular Pay',
          edit: false,
          initialValue: 0,
          cost: true,
        },
        {
          name: 'totalOvertimePay',
          displayName: 'Total Overtime Pay',
          edit: false,
          initialValue: 0,
          cost: true,
        },
        {
          name: 'totalCost',
          displayName: 'Total Cost',
          edit: false,
          initialValue: 0,
          nestedKey: 'laborer',
          cost: true,
        },
        { displayName: 'Action', edit: false },
      ],
    },
    miscellaneousCost: {
      columns: [
        {
          name: 'name',
          displayName: 'Description',
          edit: true,
          custom: true,
          type: 'text',
        },
        { name: 'quantity', displayName: 'Quantity Of Unit', edit: true, custom: true },
        {
          name: 'unitOfMeasurement',
          displayName: 'Unit Of Measurement',
          edit: true,
          custom: true,
          type: 'text',
        },
        { name: 'costPerUnit', displayName: 'Cost Per Unit', edit: true, custom: true, cost: true },
        { name: 'totalCost', displayName: 'Total Cost', edit: false, cost: true },
        { displayName: 'Action', edit: false },
      ],
    },
    gradingDemoEquipment: {
      columns: [
        {
          name: 'name',
          displayName: 'Equipment',
          edit: true,
          type: 'text',
          nestedKey: 'equipment',
        },
        { name: 'quantity', displayName: 'Equipment Quantity', edit: true },
        { name: 'hoursPerDay', displayName: 'Hours Per Day', edit: true },
        { name: 'totalDaysOnJob', displayName: 'Total Days On Job', edit: true },
        { name: 'costPerUnit', displayName: 'Rate Per Hour', edit: false, cost: true },
        { name: 'equipmentCost', displayName: 'Equipment Cost', edit: false, cost: true },
        { name: 'fuelChargePercentage', displayName: 'Fuel Charge', edit: false },
        { name: 'totalCost', displayName: 'Total Cost', edit: false, cost: true },
        { displayName: 'Action', edit: false },
      ],
    },
    gradingDemoLabor: {
      columns: [
        { name: 'title', displayName: 'Labor', edit: true, type: 'text', nestedKey: 'laborer' },
        { name: 'quantity', displayName: 'Quantity Of Men', edit: true },
        { name: 'hoursOnSite', displayName: 'Hours On Site', edit: true },
        { name: 'daysOnSite', displayName: 'Days On Job Site', edit: true },
        { name: 'totalHours', displayName: 'Total Hours', edit: false, initialValue: 0 },
        { name: 'costPerUnit', displayName: 'Cost Per Hour', edit: false, cost: true },
        {
          name: 'regularHours',
          displayName: 'Regular Paid Hours',
          edit: false,
          initialValue: 0,
        },
        {
          name: 'overtimeHours',
          displayName: 'Overtime Paid Hours',
          edit: false,
          initialValue: 0,
        },
        {
          name: 'overtimeCostPerUnit',
          displayName: 'Overtime Rate/hour',
          edit: false,
          nestedKey: 'laborer',
          cost: true,
        },
        {
          name: 'totalRegularPay',
          displayName: 'Total Regular Pay',
          edit: false,
          initialValue: 0,
          cost: true,
        },
        {
          name: 'totalOvertimePay',
          displayName: 'Total Overtime Pay',
          edit: false,
          initialValue: 0,
          cost: true,
        },
        {
          name: 'totalCost',
          displayName: 'Total Cost',
          edit: false,
          initialValue: 0,
          nestedKey: 'laborer',
          cost: true,
        },
        { displayName: 'Action', edit: false },
      ],
    },
    haulDumpCalculator: {
      columns: [
        { name: 'name', displayName: 'Name', edit: false },
        { name: 'Area', displayName: 'Area', edit: true },
        { name: 'Depth', displayName: 'Depth', edit: true },
        { name: 'lchTime', displayName: 'LCH Time', edit: true },
        { name: 'lchCostPerHour', displayName: 'LCH Cost Per Hour', edit: false, cost: true },
        { name: 'tons', displayName: 'Tons', edit: false, initialValue: 0 },
        { name: 'loads', displayName: '# Of Loads', edit: false, initialValue: 0 },
        {
          name: 'totalTruckingCost',
          displayName: 'Total Trucking Cost',
          edit: false,
          initialValue: 0,
          cost: true,
        },
        {
          name: 'costPerUnit',
          displayName: 'Cost Per Dump',
          edit: false,
          nestedKey: 'material',
          cost: true,
        },
        {
          name: 'totalDumpCost',
          displayName: 'Total Dump Cost',
          edit: false,
          initialValue: 0,
          cost: true,
        },
        {
          name: 'totalHaulDumpCost',
          displayName: 'Total Haul & Dump Cost',
          edit: false,
          initialValue: 0,
          cost: true,
        },
        { displayName: 'Action', edit: false },
      ],
    },
  }
}

export const gradingDemoTab = {
  gradingDemoEquipment: {
    columns: [
      { name: 'name', displayName: 'Equipment', edit: false, nestedKey: 'equipment' },
      { name: 'quantity', displayName: 'Equipment Quantity', edit: true },
      { name: 'hoursPerDay', displayName: 'Hours Per Day', edit: true },
      { name: 'totalDaysOnJob', displayName: 'Total Days On Job', edit: true },
      { name: 'costPerUnit', displayName: 'Rate Per Hour', edit: false, cost: true },
      { name: 'equipmentCost', displayName: 'Equipment Cost', edit: false, cost: true },
      { name: 'fuelChargePercentage', displayName: 'Fuel Charge', edit: false },
      { name: 'totalCost', displayName: 'Total Cost', edit: false, cost: true },
      { displayName: 'Action', edit: false },
    ],
  },
  gradingDemoMiscellaneousCost: {
    columns: [
      { name: 'name', displayName: 'Misc Costs', edit: false, nestedKey: 'miscellaneousCost' },
      { name: 'quantity', displayName: 'Quantity Of Unit', edit: true },
      { name: 'unitOfMeasurement', displayName: 'Unit Of Measurement', edit: false },
      { name: 'costPerUnit', displayName: 'Cost Per Unit', edit: true, cost: true },
      { name: 'totalCost', displayName: 'Total Cost', edit: false, cost: true },
      { displayName: 'Action', edit: false },
    ],
  },
  haulDumpCalculator: {
    columns: [
      { name: 'name', displayName: 'Name', edit: false },
      { name: 'area', displayName: 'Area', edit: true, numberType: true },
      { name: 'depth', displayName: 'Depth', edit: true, numberType: true },
      { name: 'loadCutHaulTime', displayName: 'LCH Time', edit: true, numberType: true },
      {
        name: 'costTrucking',
        displayName: 'Truck Cost Per Hour',
        edit: true,
        nestedKey: 'cost',
        numberType: true,
        cost: true,
      },
      {
        name: 'totalQuantity',
        displayName: 'Tons',
        edit: false,
        initialValue: 0,
        numberType: true,
      },
      {
        name: 'totalTruckLoads',
        displayName: '# Of Loads',
        edit: false,
        initialValue: 0,
        numberType: true,
      },
      {
        name: 'costDump',
        displayName: 'Cost Per Dump',
        edit: true,
        nestedKey: 'cost',
        numberType: true,
        cost: true,
      },
      {
        name: 'totalTruckCost',
        displayName: 'Total Trucking Cost',
        edit: false,
        initialValue: 0,
        numberType: true,
        cost: true,
      },
      {
        name: 'totalDumpCost',
        displayName: 'Total Dump Cost',
        edit: false,
        initialValue: 0,
        numberType: true,
        cost: true,
      },
      {
        name: 'totalHaulDumpCost',
        displayName: 'Total Haul & Dump Cost',
        edit: false,
        initialValue: 0,
        numberType: true,
        cost: true,
      },
      { name: 'service', displayName: 'Service', edit: false, initialValue: 'Grading' },
      { displayName: 'Action', edit: false },
    ],
  },
  gradingDemoLabor: {
    columns: [
      { name: 'title', displayName: 'Labor', edit: false, nestedKey: 'laborer' },
      { name: 'quantity', displayName: 'Quantity Of Men', edit: true },
      { name: 'hoursOnSite', displayName: 'Hours On Site', edit: true },
      { name: 'daysOnSite', displayName: 'Days On Job Site', edit: true },
      { name: 'totalHours', displayName: 'Total Hours', edit: false, initialValue: 0 },
      { name: 'costPerUnit', displayName: 'Cost Per Hour', edit: false, cost: true },
      {
        name: 'regularHours',
        displayName: 'Regular Paid Hours',
        edit: false,
        initialValue: 0,
      },
      {
        name: 'overtimeHours',
        displayName: 'Overtime Paid Hours',
        edit: false,
        initialValue: 0,
      },
      {
        name: 'overtimeCostPerUnit',
        displayName: 'Overtime Rate/hour',
        edit: false,
        nestedKey: 'laborer',
        cost: true,
      },
      {
        name: 'totalRegularPay',
        displayName: 'Total Regular Pay',
        edit: false,
        initialValue: 0,
        cost: true,
      },
      {
        name: 'totalOvertimePay',
        displayName: 'Total Overtime Pay',
        edit: false,
        initialValue: 0,
        cost: true,
      },
      {
        name: 'totalCost',
        displayName: 'Total Cost',
        edit: false,
        initialValue: 0,
        nestedKey: 'laborer',
        cost: true,
      },
      { displayName: 'Action', edit: false },
    ],
  },
}

export const stripingTab = {
  striping: {
    columns: [
      { name: 'name', displayName: 'Striping Category	', edit: false, nestedKey: 'workType' },
      { name: 'quantity', displayName: 'Quantity', edit: true },
      {
        name: 'costPerUnit',
        displayName: 'Cost per unit',
        edit: false,
        nestedKey: 'stripingWork',
        cost: true,
      },
      { name: 'newLayoutSelected', displayName: 'New Layout', edit: true, initialValue: false },
      { name: 'doubleCoatSelected', displayName: 'Double Coat', edit: true, initialValue: false },
      { name: 'totalCost', displayName: 'Total Cost', edit: false, cost: true },
      { displayName: 'Action', edit: false },
    ],
  },
  miscellaneousCost: {
    columns: [
      {
        name: 'name',
        displayName: 'Description',
        edit: true,
        custom: true,
        type: 'text',
        placeholder: true,
        placeholderValue: 'Description',
      },
      { name: 'quantity', displayName: 'Quantity', edit: true, custom: true },
      {
        name: 'unitOfMeasurement',
        displayName: 'Unit Of Measurement',
        edit: true,
        custom: true,
        type: 'text',
        placeholder: true,
        placeholderValue: 'Unit',
      },
      { name: 'costPerUnit', displayName: 'Cost Per Unit', edit: true, custom: true, cost: true },
      { name: 'totalCost', displayName: 'Total Cost', edit: false, cost: true },
      {
        name: 'addStripingOnProposal',
        displayName: 'Show on Proposal',
        edit: true,
        type: 'checkbox',
      },
      { displayName: 'Action', edit: false },
    ],
  },
}

export const subcontractorsTab = {
  subcontractor: {
    columns: [
      {
        name: 'company',
        displayName: 'Subcontractor Company	',
        edit: true,
        type: 'string',
        required: true,
      },
      { name: 'quoteNumber', displayName: 'QuoteNumber	', edit: true, required: true },
      { name: 'cost', displayName: 'Cost', edit: true, required: true, cost: true },
      { name: 'markup', displayName: 'Markup %', edit: true },
      { name: 'bonds', displayName: 'Bonds', edit: true, checkbox: true },
      {
        name: 'workDescription',
        displayName: 'Description of work',
        edit: true,
        type: 'string',
      },
      {
        name: 'serviceType',
        displayName: 'Service Type',
        edit: true,
        required: true,
      },
      { displayName: 'Action', edit: false },
    ],
  },
}

export const optionsTab = {
  options: {
    columns: [
      {
        name: 'name',
        displayName: 'Options of work',
        edit: true,
        type: 'string',
        required: true,
      },
      {
        name: 'summary',
        displayName: 'Description of work to be done',
        edit: true,
        required: false,
        nestedKey: 'note',
      },
      {
        name: 'estimatedCost',
        displayName: 'Additional option cost',
        edit: true,
        required: true,
        cost: true,
      },
      { displayName: 'Action', edit: false },
    ],
  },
}

export const unitOfMeasurementKind = [
  { key: 'Length', value: 'LENGTH' },
  { key: 'Area', value: 'AREA' },
  { key: 'Volume', value: 'VOLUME' },
  { key: 'Mass', value: 'MASS' },
]
