<template>
  <div class="relative h-24">
    <div class="page-header" :style="{ backgroundColor: props.themeColor }">
      <div class="page-number-container"></div>
      <div class="page-header-title" :style="{ color: props.themeColor }">
        <h1>{{ title }}</h1>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  themeColor: {
    type: String,
    default: '#07275c',
  },
})
</script>
