// custom mapbopx-gl-draw mode that extends draw_line_string
// shows a center point, radius line, and circle polygon while drawing
// forces draw.create on creation of second vertex
import circle from '@turf/circle'
import length from '@turf/length'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
// import { getDisplayMeasurements } from './util.js'

function circleFromTwoVertexLineString(geojson, lineId) {
  const center = geojson.geometry.coordinates[0]
  // If the geojson is a line with two vertices, calculate the distance between them
  // which is the radius of the circle.
  const radiusInKm = length(geojson, { units: 'kilometers' })
  const circleFeature = circle(center, radiusInKm, { steps: 64, units: 'kilometers' })

  // Add an 'isCircle' property to the circle's properties to differentiate it from other polygons
  circleFeature.id = generateUniqueId()
  circleFeature.properties = {
    ...circleFeature.properties,
    isCircle: true,
    lineId: lineId,
  }

  return circleFeature
}

function generateUniqueId(prefix = 'circle') {
  // Create a unique id based on the current time and a random number
  const uniquePart = Date.now().toString(36) + Math.random().toString(36).substr(2)
  return `${uniquePart}`
}

const CircleMode = {
  ...MapboxDraw.modes.draw_line_string,

  clickAnywhere: function (state, e) {
    // this ends the drawing after the user creates a second point, triggering this.onStop
    if (state.currentVertexPosition === 1) {
      state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat)
      return this.changeMode('simple_select', { featureIds: [state.line.id] })
    }

    state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat)
    if (state.direction === 'forward') {
      state.currentVertexPosition += 1
      state.line.updateCoordinate(state.currentVertexPosition, e.lngLat.lng, e.lngLat.lat)
    } else {
      state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat)
    }

    return null
  },

  onStop: function (state) {
    this.activateUIButton()

    // check to see if we've deleted this feature
    if (this.getFeature(state.line.id) === undefined) return

    // remove last added coordinate
    state.line.removeCoordinate('0')
    if (state.line.isValid()) {
      const lineGeoJson = state.line.toGeoJSON()
      const circleFeature = circleFromTwoVertexLineString(lineGeoJson, state.line.id)

      this.map.fire('draw.create', {
        features: [circleFeature],
      })
    } else {
      this.deleteFeature([state.line.id], { silent: true })
      this.changeMode('simple_select', {}, { silent: true })
    }
  },

  toDisplayFeatures: function (state, geojson, display) {
    // Only render the line if it has at least one real coordinate
    if (geojson.geometry.coordinates.length < 2) return null

    display({
      type: 'Feature',
      properties: {
        active: 'true',
      },
      geometry: {
        type: 'Point',
        coordinates: geojson.geometry.coordinates[0],
      },
    })

    // displays the line as it is drawn
    geojson.properties.active = 'true'
    display(geojson)

    const displayMeasurements = 1

    // create custom feature for the current pointer position
    const currentVertex = {
      type: 'Feature',
      properties: {
        meta: 'currentPosition',
        radius: `${displayMeasurements.metric} ${displayMeasurements.standard}`,
        parent: state.line.id,
      },
      geometry: {
        type: 'Point',
        coordinates: geojson.geometry.coordinates[1],
      },
    }

    display(currentVertex)

    const circleFeature = circleFromTwoVertexLineString(geojson)

    circleFeature.properties = {
      active: 'true',
    }

    display(circleFeature)

    return null
  },
}

export default CircleMode
