<template>
  <label v-if="label" class="block capitalize text-sm font-medium text-gray-700"
    >{{ label }} <span v-if="required">*</span></label
  >
  <input
    v-bind="$attrs"
    class="block mt-1 w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    :class="{ 'border-gray-400': !error, 'border-red-400 ': error }"
    :placeholder="label"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
  <p v-if="error" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
    {{ error }}
  </p>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
}
</script>
