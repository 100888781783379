<template>
  <Loader v-if="store.userDataLoading.value" />
  <div v-else>
    <div>
      <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
        <div
          v-for="item in stats"
          :key="item.name"
          class="overflow-hidden rounded-lg text-white px-4 py-8 shadow sm:p-6 bg-primary-700"
        >
          <dt class="truncate text-sm font-medium text-white">{{ item.name }}</dt>
          <dd class="mt-1 text-3xl font-semibold tracking-tight text-white">
            {{ formatDecimalNumber(userStats[item.key]) }}
          </dd>
        </div>
      </dl>
    </div>
    <div class="my-20 grid grid-cols-2 gap-5">
      <div class="overflow-hidden rounded-lg py-5 shadow sm:p-6 shadow bg-primary-100">
        <h1 class="text-3xl py-4">Welcome {{ store.userData.roles.nodes[0].name }}</h1>
        <img
          v-if="store.userData?.profilePictureUrl"
          class="h-16 w-16 rounded-full"
          :src="store.userData.profilePictureUrl"
          alt="profile"
        />
        <span
          v-else
          class="ml-8 inline-flex h-16 w-16 items-center justify-center rounded-full bg-primary-500"
        >
          <span class="text-xl font-medium leading-none text-white">{{
            store.userData.roles?.nodes[0]?.name[0]
          }}</span>
        </span>
      </div>
      <div class="px-4 py-4 shadow sm:p-6 shadow rounded-lg bg-primary-100">
        <h1 class="text-2xl py-4">Notifications</h1>
        <div class="pb-6 overflow-hidden shadow rounded-lg bg-primary-50">
          <div class="h-[53vh] pt-4 overflow-x-auto">
            <div
              v-show="store.notifications?.nodes.length == 0"
              class="pt-44 relative flex justify-center items-center"
            >
              <p class="px-2 text-xl text-gray-700">No Notifications</p>
            </div>
            <ul role="list" class="divide-y divide-gray-100">
              <li
                v-for="item in store.notifications?.nodes"
                :key="item.commit"
                class="px-2 py-4 hover:bg-primary-100 cursor-pointer"
              >
                <router-link
                  :to="item.redirectUrl ? item.redirectUrl : '/'"
                  @click="handleUpdateNotification(item)"
                >
                  <div class="flex items-center gap-x-3">
                    <BellAlertIcon class="h-10 w-10 text-primary-600" />
                    <h3 class="flex-auto text-sm leading-6 text-gray-900">
                      {{ item.message }}
                    </h3>
                    <div class="flex flex-col items-center">
                      <div
                        v-show="!item.readAt"
                        class="text-primary-600 bg-blue-500/10 h-2.5 w-2.5 rounded-full bg-current my-1"
                      />

                      <time
                        :datetime="item.dateTime"
                        class="text-center text-xs text-gray-500 w-24"
                      >
                        {{ notificationTime(item.createdAt) }}
                      </time>
                    </div>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="my-20 grid grid-cols-3 gap-10">
      <div class="col-span-2 overflow-hidden shadow rounded-lg bg-primary-100 p-8">
        <div class="flex justify-end mr-8">
          <BaseSelect class="w-32" v-model="selectedSpan" :options="['week', 'month', 'year']" />
        </div>

        <Line
          class="pt-16 px-7 h-[55vh]"
          :chart-options="{
            responsive: true,
          }"
          :chart-data="{
            labels: graphData.totalProposals.labels,
            datasets: [
              {
                label: 'Total Proposals',
                backgroundColor: '#f87979',
                data: graphData.totalProposals.data,
              },
              {
                label: 'Signed Proposals',
                backgroundColor: '#00308F',
                data: graphData.totalSignedProposals.data,
              },
            ],
          }"
          chart-id="proposalCountsBarChart"
          dataset-id-key="proposalCountsKey"
          :width="100"
          :height="60"
        />
      </div>
      <div class="p-8 shadow rounded-lg bg-primary-100">
        <Bar
          :chartData="{
            labels: graphData.proposalsTotalAmount.labels,
            datasets: [
              {
                label: 'Created Proposals $',
                backgroundColor: '#7CB9E8',
                data: graphData.proposalsTotalAmount.data,
              },
              {
                label: 'Signed Proposals $',
                backgroundColor: '#32de84',
                data: graphData.signedProposalsTotalAmount.data,
              },
            ],
          }"
          :chartOptions="{
            responsive: true,
            maintainAspectRatio: false,
          }"
          :width="100"
          :height="600"
        />
      </div>
    </div>

    <div class="my-20 grid grid-cols-2 gap-5">
      <div
        v-if="!(store.userData.roles.nodes[0].name == 'estimator')"
        class="p-8 shadow rounded-lg bg-primary-100"
      >
        <p v-show="route.query.id" class="text-xs">
          <router-link to="/dashboard"> Back to Manager view</router-link>
        </p>
        <h1 class="text-3xl p-6">Estimators</h1>
        <div class="h-[53vh] pt-4 overflow-x-auto">
          <ul role="list" class="divide-y divide-gray-200">
            <li
              v-for="estimator in store.userData.estimators.nodes"
              :key="estimator.email"
              class="flex items-center justify-between gap-x-6 py-5"
            >
              <div class="flex min-w-0 gap-x-4">
                <img
                  v-if="estimator.profilePictureUrl"
                  class="h-14 w-14 rounded-full"
                  :src="estimator.profilePictureUrl"
                  alt="profile"
                />
                <UserCircleIcon v-else class="h-14 w-14 text-primary-500" />

                <div class="min-w-0 flex-auto">
                  <p class="text-sm font-semibold leading-6 text-gray-900">{{ estimator.email }}</p>
                  <p class="mt-1 truncate text-xs leading-5 text-gray-500">
                    Total Bids : {{ estimator.totalBids }}
                  </p>
                </div>
              </div>
              <button
                @click="handleEstimatorRedirect(estimator)"
                class="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50"
              >
                View
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="p-8 shadow rounded-lg bg-primary-100">
        <h1 class="text-3xl p-6">Latest Proposals</h1>
        <ul role="list" class="divide-y divide-gray-200">
          <li
            v-for="proposal in store.userData.proposals.nodes.slice(0, 5)"
            :key="proposal.id"
            class="flex items-center justify-between gap-x-6 py-5"
          >
            <div class="min-w-0">
              <div class="flex items-start gap-x-3">
                <p class="text-sm font-semibold leading-6 text-gray-900">{{ proposal.title }}</p>
                <p
                  :class="[
                    statuses[proposal.status],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                  ]"
                >
                  {{ proposal.status }}
                </p>
              </div>
              <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p class="whitespace-nowrap">
                  <time :datetime="proposal.createdAt">{{
                    Moment(proposal.createdAt).format('LL')
                  }}</time>
                </p>
                <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                  <circle cx="1" cy="1" r="1" />
                </svg>
                <p class="truncate">{{ proposal.author.email }}</p>
              </div>
            </div>
            <div class="flex flex-none items-center gap-x-4">
              <router-link :to="`/estimations/${proposal.id}`" class="block hover:bg-primary-50">
                <p
                  class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-primary-50 sm:block"
                >
                  View proposal
                </p>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Bar, Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from 'chart.js'
import Moment from 'moment'
import { BellAlertIcon, UserCircleIcon } from '@heroicons/vue/20/solid'

import BaseSelect from '@/components/layout/BaseSelect.vue'
import Loader from '@/components/layout/Loader.vue'
import { useUserStore } from '../stores/user'
import { formatDecimalNumber } from '../utils/utility_methods'
import { notificationTime } from '../utils/utility_methods'

const store = useUserStore()
const router = useRouter()
const route = useRoute()

const statuses = {
  APPROVED: 'text-success-700 bg-success-50 ring-green-600/20',
  DECLINED: 'text-failure-600 bg-failure-50 ring-red-500/10',
  PENDING: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  READY_FOR_APPROVAL: 'text-primary-800 bg-blue-50 ring-blue-600/20',
  UNDER_CLIENTS_REVIEW: 'text-yellow-600 bg-yellow-100 ring-yellow-700/20',
  SIGNED_CONTRACT: 'text-success-600 bg-success-50 ring-green-600/20',
}

const selectedSpan = ref('year')
const graphData = ref(null)
const userStats = ref(null)
const stats = [
  { name: 'Total Sales', key: 'totalSales', value: null },
  { name: 'Total Bids', key: 'totalBids', value: null },
  { name: 'Total Estimators', key: 'estimators', value: null },
  { name: 'Closing Percentage', key: 'closingPercentage', value: null },
]

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
)

const updateGraphData = (data) => {
  graphData.value = {
    proposalsTotalAmount: {
      labels: data.proposalMetrics.proposalsTotalAmount.nodes.map((item) => item.date),
      data: data.proposalMetrics.proposalsTotalAmount.nodes.map((item) => item.total),
    },
    signedProposalsTotalAmount: {
      labels: data.proposalMetrics.signedProposalsTotalAmount.nodes.map((item) => item.date),
      data: data.proposalMetrics.signedProposalsTotalAmount.nodes.map((item) => item.total),
    },
    totalProposals: {
      labels: data.proposalMetrics.totalProposals.nodes.map((item) => item.date),
      data: data.proposalMetrics.totalProposals.nodes.map((item) => item.total),
    },
    totalSignedProposals: {
      labels: data.proposalMetrics.totalSignedProposals.nodes.map((item) => item.date),
      data: data.proposalMetrics.totalSignedProposals.nodes.map((item) => item.total),
    },
  }
  userStats.value = {
    totalSales: data.totalSales ?? 0,
    totalBids: data.totalBids ?? 0,
    estimators: data.estimators ? data.estimators.nodes.length : 0,
    closingPercentage: data.closingPercentage ?? 0,
  }
}

watch(
  () => route.query.id,
  () => {
    if (route.query.id) {
      const selectedEstimator = store.userData.estimators.nodes.find(
        (estimator) => estimator.id == route.query.id
      )
      updateGraphData(selectedEstimator)
    }
  }
)

watch(selectedSpan, (span) => {
  store.updateSpan(span)
})

watchEffect(() => {
  if (store.userData && route.query.id) {
    updateGraphData(
      store.userData.estimators.nodes.find((estimator) => estimator.id == route.query.id)
    )
  }
  if (store.userData && !route.query.id) {
    updateGraphData(store.userData)
  }
})

const handleEstimatorRedirect = (estimator) => {
  router.push({
    path: '/dashboard',
    query: { id: estimator.id },
  })
}
</script>
