<template>
  <div
    class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
  >
    <div class="flex flex-1 justify-between sm:hidden">
      <a
        href="#"
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-primary-50"
        >Previous</a
      >
      <a
        href="#"
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-primary-50"
        >Next</a
      >
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center justify-around">
      <div class="text-center">
        <p class="text-sm text-gray-700">
          Showing page {{ props.currentPage }}
          of
          <span class="font-medium">{{ totalComputedPages }}</span>
        </p>
      </div>
      <div>
        <nav class="isolate inline-flex-space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <button
            :disabled="!pageInfo.hasPreviousPage"
            :class="!pageInfo.hasPreviousPage && 'cursor-not-allowed'"
            @click="handleControls('previous')"
            class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-primary-50 focus:z-20 focus:outline-offset-0"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>

          <button
            :disabled="!pageInfo.hasNextPage"
            :class="!pageInfo.hasNextPage && 'cursor-not-allowed'"
            class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-primary-50 focus:z-20 focus:outline-offset-0"
            @click="handleControls('next')"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  totalPages: {
    type: Number,
  },
  currentPage: {
    type: Number,
  },
  pageInfo: {
    type: Object,
  },
})

const emit = defineEmits(['page-change'])

const handleControls = (direction) => {
  if (direction === 'next' && props.pageInfo.hasNextPage) {
    emit('page-change', {
      first: 10,
      after: props.pageInfo.endCursor,
      before: null,
      currentPage: props.currentPage + 1,
    })
  } else if (direction === 'previous' && props.pageInfo.hasPreviousPage && props.currentPage > 1) {
    emit('page-change', {
      last: 10,
      before: props.pageInfo.startCursor,
      after: null,
      currentPage: props.currentPage - 1,
    })
  }
}

const totalComputedPages = computed(() => Math.ceil(props.totalPages / 10))
</script>
