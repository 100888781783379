<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
  </div>
  <div v-else>
    <div v-for="item in stripingCategories.result.value.stripingCategories?.nodes">
      <StripingTabTable
        :title="item.name"
        :columns="stripingTab.striping.columns"
        :rowFields="filterFields(item.name)"
        :rowsData="tablesData[camelCaseString(item.name)]?.data"
        :showSave="tablesData[camelCaseString(item.name)]?.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        :name="camelCaseString(item.name)"
      />
    </div>

    <div>
      <TabTable
        title="Miscellaneous Costs"
        :columns="stripingTab.miscellaneousCost.columns"
        :rowFields="miscellaneousFieldDataQuery.result.value.stripingMiscellaneousCost.nodes"
        :rowsData="tablesData.miscellaneousCostData?.data"
        :showSave="tablesData.miscellaneousCostData?.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="miscellaneousCostData"
      />
    </div>
    <DeleteRowModal
      :showModal="deleteModal.open"
      :title="deleteModal.title"
      @close-modal="deleteModal.open = false"
      @delete-row="handleRemoveRow"
    />
    <Toast v-if="toast.showToast" :title="toast.title" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'

import Toast from '@/components/layout/Toast.vue'
import DeleteRowModal from '@/components/modals/DeleteRowModal.vue'
import Loader from '@/components/layout/Loader.vue'
import StripingTabTable from '@/components/layout/StripingTabTable.vue'
import TabTable from '@/components/layout/TabTable.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'
import { stripingTab } from '@/keys/constants'
import { useAddRowData } from '../../composables/useAddRowData'
import { useDeleteTableData } from '../../composables/useDeleteTableData'
import { useEditTableData } from '../../composables/useEditTableData'
import { useUpdateRowData } from '../../composables/useUpdateRowData'
import { filterServiceItems } from '../../utils/utility_methods'
import GET_STRIPING_FIELD_DATA from '../../graphql/queries/getStripingFieldData.gql'
import GET_STRIPING_MISC_FIELD_DATA from '../../graphql/queries/getStripingMiscellaneousFieldData.gql'
import GET_SERVICE_ID from '../../graphql/queries/getServiceId.gql'
import GET_PROPOSAL_BY_ID from '../../graphql/queries/getProposalById.gql'
import GET_STRIPING_CATEGORIES from '../../graphql/queries/getStripingCategories.gql'

const tablesData = ref({})
const calculationsLoading = ref(false)

const props = defineProps({
  proposalData: {
    type: Object,
  },
})
const deleteModal = ref({ open: false, title: '' })
const deleteRowData = ref({ name: null, data: null })
const stripingServiceId = ref(null)
const route = useRoute()
const toast = ref({ showToast: false, title: '' })
const fieldDataQuery = useQuery(GET_STRIPING_FIELD_DATA)
const miscellaneousFieldDataQuery = useQuery(GET_STRIPING_MISC_FIELD_DATA)
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const { result } = useQuery(GET_SERVICE_ID, {
  id: route.params.id,
})
const stripingCategories = useQuery(GET_STRIPING_CATEGORIES)

const loading = computed(() => {
  return (
    fieldDataQuery.loading.value ||
    miscellaneousFieldDataQuery.loading.value ||
    proposalByIdQuery.loading.value ||
    stripingCategories.loading.value
  )
})

watch(result, (value) => {
  stripingServiceId.value = value.proposalsService.nodes.find(
    (item) => item.service.name == 'Striping'
  ).id
})

watch([stripingServiceId, proposalByIdQuery.result, stripingCategories.result], () => {
  if (
    stripingServiceId.value &&
    proposalByIdQuery.result.value &&
    stripingCategories.result.value
  ) {
    stripingCategories.result.value.stripingCategories?.nodes.forEach((categoryItem) => {
      tablesData.value[camelCaseString(categoryItem.name)] = {
        data: proposalByIdQuery.result.value.proposal.constructionMaterial.nodes.filter(
          (item) => item.workType.workTypeCategory?.name == categoryItem.name
        ),
        savePayload: [],
      }
    })

    tablesData.value.miscellaneousCostData = {
      data: filterServiceItems(
        proposalByIdQuery.result.value.proposal.miscellaneousCosts.nodes,
        stripingServiceId.value
      ),
      savePayload: [],
    }
  }
})

const filterFields = (category) => {
  return fieldDataQuery.result.value.stripingConstructionWork.nodes.filter(
    (item) => item.workTypeCategory?.name == category && item.stripingWork
  )
}

const handleAddRow = (name, rows) => {
  tablesData.value[name].data = [...tablesData.value[name].data, ...rows]
}

const handleCancelAddRow = (name, rowId, rows) => {
  tablesData.value[name].data = rows
  tablesData.value[name].savePayload = tablesData.value[name].savePayload.filter(
    (item) => item.id !== rowId
  )
}

const handleEditRow = async (name, payload) => {
  let { responseMessage } = await useEditTableData(name, payload)
  proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleConfirmRemoveRow = (name, title, row) => {
  deleteModal.value.open = true
  deleteRowData.value = { name: name, data: row.map((item) => item.id ?? item) }
  deleteModal.value.title = title
}

const handleRemoveRow = async () => {
  let { responseMessage } = await useDeleteTableData(
    deleteRowData.value.name,
    deleteRowData.value.data
  )
  proposalByIdQuery.refetch()
  deleteModal.value = { open: false, title: '' }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleSubmit = async (name, newRow) => {
  let { responseMessage } = await useAddRowData(name, newRow, stripingServiceId.value, tablesData)
  tablesData.value[name] = { data: [], savePayload: [] }
  proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const updateData = async (name, row, isEditing) => {
  calculationsLoading.value = true
  await useUpdateRowData(name, row, isEditing, tablesData, props.proposalData.proposal.publicWorks)
  calculationsLoading.value = false
}

const camelCaseString = (string) =>
  string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
</script>
