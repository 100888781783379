<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
  </div>
  <div v-else>
    <div>
      <ConstructionMaterials
        title="Seal Coat"
        :rowFields="
          fieldDataQuery.result.value.sealCoatingConstructionWork.nodes.filter(
            (item) => item.material
          )
        "
        :rowsData="tablesData.constructionMaterialsData.data"
        name="constructionMaterialsData"
        @add-row="handleSubmit"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cost-updated="toast = { showToast: true, title: 'Cost Per Unit updated Successfully!' }"
      />
    </div>

    <div>
      <TabTable
        title="Seal Coat Equipment"
        :columns="tabColumns().equipment.columns"
        :rowFields="fieldDataQuery.result.value.sealCoatEquipment.nodes"
        :rowsData="tablesData.equipmentData.data"
        :showSave="tablesData.equipmentData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="equipmentData"
      />
    </div>

    <div>
      <TabTable
        title="Seal Coat Labor"
        :columns="tabColumns(props.proposalData.proposal.publicWorks).labor.columns"
        :rowFields="fieldDataQuery.result.value.sealCoatLaborers.nodes"
        :rowsData="tablesData.laborerData.data"
        :showSave="tablesData.laborerData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="laborerData"
      />
    </div>

    <div>
      <TabTable
        title="Seal Coat Miscellaneous Cost"
        :columns="tabColumns().miscellaneousCost.columns"
        :rowFields="fieldDataQuery.result.value.sealCoatMiscellaneousCost.nodes"
        :rowsData="tablesData.miscellaneousCostData.data"
        :showSave="tablesData.miscellaneousCostData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="miscellaneousCostData"
      />
    </div>

    <DeleteRowModal
      :showModal="deleteModal.open"
      :title="deleteModal.title"
      @close-modal="deleteModal.open = false"
      @delete-row="handleRemoveRow"
    />
    <Toast v-if="toast.showToast" :title="toast.title" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import { tabColumns } from '@/keys/constants'
import TabTable from '@/components/layout/TabTable.vue'
import Toast from '@/components/layout/Toast.vue'
import Loader from '@/components/layout/Loader.vue'
import DeleteRowModal from '@/components/modals/DeleteRowModal.vue'
import ConstructionMaterials from '@/components/layout/ConstructionMaterials.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'

import { useDeleteTableData } from '../../composables/useDeleteTableData'
import { useEditTableData } from '../../composables/useEditTableData'
import { useUpdateRowData } from '../../composables/useUpdateRowData'
import { useAddRowData } from '../../composables/useAddRowData'

import GET_SEALCOAT_FIELD_DATA from '../../graphql/queries/getSealCoatFieldData.gql'
import GET_SERVICE_ID from '../../graphql/queries/getServiceId.gql'
import GET_PROPOSAL_BY_ID from '../../graphql/queries/getProposalById.gql'
import GET_WRITEUP_SECTIONS from '../../graphql/queries/getWriteupSections.gql'

const tablesData = ref({
  laborerData: { data: [], savePayload: [] },
  equipmentData: { data: [], savePayload: [] },
  miscellaneousCostData: { data: [], savePayload: [] },
  constructionMaterialsData: { data: [], savePayload: [] },
})
const props = defineProps({
  proposalData: {
    type: Object,
  },
})
const emit = defineEmits(['disableFinalbidTab'])
const deleteModal = ref({ open: false, title: '' })
const deleteRowData = ref({ name: null, data: null, ids: [] })
const sealCoatServiceId = ref(null)
const route = useRoute()
const calculationsLoading = ref(false)
const toast = ref({ showToast: false, title: '' })
const fieldDataQuery = useQuery(GET_SEALCOAT_FIELD_DATA)
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const { result } = useQuery(GET_SERVICE_ID, {
  id: route.params.id,
})
const getWriteupSections = useQuery(GET_WRITEUP_SECTIONS, {
  proposalId: route.params.id,
})
const loading = computed(() => {
  return fieldDataQuery.loading.value || proposalByIdQuery.loading.value
})

const disableFinalBidTab = computed(() =>
  tablesData.value.constructionMaterialsData.data.some((item) => !item.isComplete)
)

watch(tablesData.value, (value) => {
  emit('disableFinalbidTab', disableFinalBidTab.value)
})

watch(result, (value) => {
  sealCoatServiceId.value = value.proposalsService.nodes.find(
    (item) => item.service.name == 'Seal Coating'
  ).id
})

watch([sealCoatServiceId, proposalByIdQuery.result], () => {
  if (sealCoatServiceId.value && proposalByIdQuery.result.value) {
    tablesData.value.laborerData.data =
      proposalByIdQuery.result.value.proposal.laborers.nodes.filter(
        (item) => item.proposalsServicesItems[0].proposalsService.id == sealCoatServiceId.value
      )
    tablesData.value.equipmentData.data =
      proposalByIdQuery.result.value.proposal.equipments.nodes.filter(
        (item) => item.proposalsServicesItems[0].proposalsService.id == sealCoatServiceId.value
      )
    tablesData.value.miscellaneousCostData.data =
      proposalByIdQuery.result.value.proposal.miscellaneousCosts.nodes.filter(
        (item) => item.proposalsServicesItems[0].proposalsService.id == sealCoatServiceId.value
      )
    tablesData.value.constructionMaterialsData.data =
      proposalByIdQuery.result.value.proposal.constructionMaterial.nodes.filter(
        (item) => item.proposalsServicesItems[0].proposalsService.id == sealCoatServiceId.value
      )
  }
})

const handleAddRow = (name, rows) => {
  tablesData.value[name].data = [...tablesData.value[name].data, ...rows]
}

const handleCancelAddRow = (name, rowId, rows) => {
  tablesData.value[name].data = rows
  tablesData.value[name].savePayload = tablesData.value[name].savePayload.filter(
    (item) => item.id !== rowId
  )
}

const handleEditRow = async (name, payload, row) => {
  let { responseMessage } = await useEditTableData(name, payload, row)
  proposalByIdQuery.refetch()
  if (name == 'constructionMaterialsData') {
    await getWriteupSections.refetch()
  }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleConfirmRemoveRow = (name, title, rows) => {
  deleteModal.value.open = true
  deleteRowData.value = {
    name: name,
    data: rows,
    ids: name !== 'constructionMaterialsData' ? rows.map((item) => item.id ?? item) : [],
  }
  deleteModal.value.title = title
}

const handleRemoveRow = async () => {
  let { responseMessage } = await useDeleteTableData(
    deleteRowData.value.name,
    deleteRowData.value.name !== 'constructionMaterialsData'
      ? deleteRowData.value.ids
      : deleteRowData.value.data.id,
    deleteRowData.value.data.proposalsWorkProperties
  )
  proposalByIdQuery.refetch()
  if (deleteRowData.value.name == 'constructionMaterialsData') {
    await getWriteupSections.refetch()
  }
  deleteModal.value = { open: false, title: '' }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleSubmit = async (name, newRow) => {
  let { responseMessage } = await useAddRowData(name, newRow, sealCoatServiceId.value, tablesData)

  tablesData.value[name] = { data: [], savePayload: [] }
  proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const updateData = async (name, row, isEditing) => {
  calculationsLoading.value = true
  await useUpdateRowData(name, row, isEditing, tablesData, props.proposalData.proposal.publicWorks)
  calculationsLoading.value = false
}
</script>
