<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
  </div>
  <div v-else>
    <div>
      <ConstructionMaterials
        title="Concrete"
        :rowFields="
          fieldDataQuery.result.value.concreteConstructionWork.nodes.filter((item) => item.material)
        "
        :rowsData="tablesData.constructionMaterialsData.data"
        name="constructionMaterialsData"
        @add-row="handleSubmit"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cost-updated="toast = { showToast: true, title: 'Cost Per Unit updated Successfully!' }"
      />
    </div>
    <div>
      <TabTable
        title="Grading Equipment"
        :columns="tabColumns().gradingDemoEquipment.columns"
        :rowFields="gradingFieldDataQuery.result.value.gradingEquipment.nodes"
        :rowsData="tablesData.gradingEquipmentData.data"
        :showSave="tablesData.gradingEquipmentData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="gradingEquipmentData"
      />
    </div>
    <div>
      <TabTable
        title="Grading Labor"
        :columns="tabColumns(props.proposalData.proposal.publicWorks).labor.columns"
        :rowFields="gradingFieldDataQuery.result.value.gradingLaborers.nodes"
        :rowsData="tablesData.gradingLaborerData.data"
        :showSave="tablesData.gradingLaborerData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="gradingLaborerData"
      />
    </div>

    <div>
      <TabTable
        title="Concrete Equipment"
        :columns="tabColumns().equipment.columns"
        :rowFields="fieldDataQuery.result.value.concreteEquipment.nodes"
        :rowsData="tablesData.equipmentData.data"
        :showSave="tablesData.equipmentData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="equipmentData"
      />
    </div>

    <div>
      <TabTable
        title="Concrete Labor"
        :columns="tabColumns(props.proposalData.proposal.publicWorks).labor.columns"
        :rowFields="fieldDataQuery.result.value.concreteLaborers.nodes"
        :rowsData="tablesData.laborerData.data"
        :showSave="tablesData.laborerData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="laborerData"
      />
    </div>
    <div>
      <TabTable
        title="Concrete Miscellaneous Cost"
        :columns="tabColumns().miscellaneousCost.columns"
        :rowFields="fieldDataQuery.result.value.concreteMiscellaneousCost.nodes"
        :rowsData="tablesData.miscellaneousCostData.data"
        :showSave="tablesData.miscellaneousCostData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="miscellaneousCostData"
      />
    </div>
    <DeleteRowModal
      :showModal="deleteModal.open"
      :title="deleteModal.title"
      @close-modal="deleteModal.open = false"
      @delete-row="handleRemoveRow"
    />
    <Toast v-if="toast.showToast" :title="toast.title" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { useRoute } from 'vue-router'

import { tabColumns } from '@/keys/constants'
import Loader from '@/components/layout/Loader.vue'
import TabTable from '@/components/layout/TabTable.vue'
import Toast from '@/components/layout/Toast.vue'
import DeleteRowModal from '@/components/modals/DeleteRowModal.vue'
import ConstructionMaterials from '@/components/layout/ConstructionMaterials.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'
import { useDeleteTableData } from '../../composables/useDeleteTableData'
import { useEditTableData } from '../../composables/useEditTableData'
import { useUpdateRowData } from '../../composables/useUpdateRowData'
import { useAddRowData } from '../../composables/useAddRowData'
import { filterGradingItems, filterServiceItems } from '../../utils/utility_methods'

import GET_CONCRETE_FIELD_DATA from '../../graphql/queries/getConcreteFieldData.gql'
import GET_GRADING_FIELD_DATA from '../../graphql/queries/getGradingDemoFieldData.gql'
import GET_PROPOSAL_BY_ID from '../../graphql/queries/getProposalById.gql'
import GET_PROPOSAL_SERVICE from '../../graphql/queries/getProposalService.gql'
import GET_WRITEUP_SECTIONS from '../../graphql/queries/getWriteupSections.gql'

const props = defineProps({
  proposalData: {
    type: Object,
  },
})
const emit = defineEmits(['disableFinalbidTab'])

const route = useRoute()
const calculationsLoading = ref(false)

const tablesData = ref({
  laborerData: { data: [], savePayload: [] },
  equipmentData: { data: [], savePayload: [] },
  miscellaneousCostData: { data: [], savePayload: [] },
  constructionMaterialsData: { data: [], savePayload: [] },
  gradingEquipmentData: { data: [], savePayload: [] },
  gradingLaborerData: { data: [], savePayload: [] },
})
const deleteModal = ref({ open: false, title: '' })
const deleteRowData = ref({ name: null, data: null, ids: [] })
const concreteServiceId = ref(null)
const gradingServiceId = ref(null)
const toast = ref({ showToast: false, title: '' })

const fieldDataQuery = useQuery(GET_CONCRETE_FIELD_DATA)
const gradingFieldDataQuery = useQuery(GET_GRADING_FIELD_DATA)
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const getWriteupSections = useQuery(GET_WRITEUP_SECTIONS, {
  proposalId: route.params.id,
})

const { result } = useQuery(GET_PROPOSAL_SERVICE, {
  id: route.params.id,
})

const loading = computed(() => {
  return (
    fieldDataQuery.loading.value ||
    gradingFieldDataQuery.loading.value ||
    proposalByIdQuery.loading.value
  )
})

const disableFinalBidTab = computed(() =>
  tablesData.value.constructionMaterialsData.data.some((item) => !item.isComplete)
)

watch(tablesData.value, (value) => {
  emit('disableFinalbidTab', disableFinalBidTab.value)
})

watch(result, (value) => {
  let concreteService = value.proposalsService.nodes.find((item) => item.service.name == 'Concrete')
  concreteServiceId.value = concreteService.id
  gradingServiceId.value = value.proposalsService.nodes.find(
    (item) => item.service.name == 'Grading'
  ).id
})

watch([concreteServiceId, proposalByIdQuery.result], () => {
  if (concreteServiceId.value && proposalByIdQuery.result.value) {
    tablesData.value.gradingLaborerData.data = filterGradingItems(
      proposalByIdQuery.result.value.proposal.laborers.nodes,
      concreteServiceId.value
    )
    tablesData.value.gradingEquipmentData.data = filterGradingItems(
      proposalByIdQuery.result.value.proposal.equipments.nodes,
      concreteServiceId.value
    )
    tablesData.value.laborerData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.laborers.nodes,
      concreteServiceId.value
    )

    tablesData.value.equipmentData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.equipments.nodes,
      concreteServiceId.value
    )

    tablesData.value.miscellaneousCostData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.miscellaneousCosts.nodes,
      concreteServiceId.value
    )

    tablesData.value.constructionMaterialsData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.constructionMaterial.nodes,
      concreteServiceId.value
    )
  }
})

const handleAddRow = (name, rows) => {
  tablesData.value[name].data = [...tablesData.value[name].data, ...rows]
}

const handleCancelAddRow = (name, rowId, rows) => {
  tablesData.value[name].data = rows
  tablesData.value[name].savePayload = tablesData.value[name].savePayload.filter(
    (item) => item.id !== rowId
  )
}

const handleEditRow = async (name, payload, row) => {
  let { responseMessage } = await useEditTableData(name, payload, row)
  proposalByIdQuery.refetch()
  if (name == 'constructionMaterialsData') {
    await getWriteupSections.refetch()
  }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleConfirmRemoveRow = (name, title, rows) => {
  deleteModal.value.open = true
  deleteRowData.value = {
    name: name,
    data: rows,
    ids: name !== 'constructionMaterialsData' ? rows.map((item) => item.id ?? item) : [],
  }
  deleteModal.value.title = title
}

const handleRemoveRow = async () => {
  let { responseMessage } = await useDeleteTableData(
    deleteRowData.value.name,
    deleteRowData.value.name !== 'constructionMaterialsData'
      ? deleteRowData.value.ids
      : deleteRowData.value.data.id,
    deleteRowData.value.data.proposalsWorkProperties
  )
  proposalByIdQuery.refetch()
  if (deleteRowData.value.name == 'constructionMaterialsData') {
    await getWriteupSections.refetch()
  }
  deleteModal.value = { open: false, title: '' }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleSubmit = async (name, newRow) => {
  let { responseMessage } = await useAddRowData(
    name,
    newRow,
    concreteServiceId.value,
    tablesData,
    gradingServiceId.value
  )
  tablesData.value[name] = { data: [], savePayload: [] }
  proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const updateData = async (name, row, isEditing, rowData) => {
  calculationsLoading.value = true
  await useUpdateRowData(
    name,
    row,
    isEditing,
    tablesData,
    props.proposalData.proposal.publicWorks,
    rowData
  )
  calculationsLoading.value = false
}
</script>
