import axios from 'axios'
import { useMutation } from '@vue/apollo-composable'
import { provideApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/helpers/apolloClient.js'
import MARKUP_COST_CALCULATE from '../graphql/mutations/calculations/markupCostCalculate.gql'

provideApolloClient(apolloClient)

const calculateMarkupCost = useMutation(MARKUP_COST_CALCULATE)

export const getCookie = (name) => {
  const value = document.cookie
  const parts = value.split(`${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export const logoutUser = async () => {
  try {
    localStorage.clear()
    await axios.get('/users/sign_out')
    location.reload()
  } catch (error) {
    console.log('Error :', error)
  }
}

export const filterGradingItems = (items, id) =>
  items
    .filter((item) => item.proposalsServicesItems.length == 2)
    .filter((filteredItems) =>
      filteredItems.proposalsServicesItems.some(
        (itemService) => itemService.proposalsService.id == id
      )
    )

export const filterServiceItems = (items, id) =>
  items
    .filter((item) => item.proposalsServicesItems.length == 1)
    .filter((itemService) => itemService.proposalsServicesItems[0].proposalsService.id == id)

export const extractDensityAndGid = (str) => {
  const regex = /:(\w+)=>"(.+?)"/g
  let matches
  const results = []
  while ((matches = regex.exec(str)) !== null) {
    const key = matches[1]
    const gid = matches[2]
    results.push({
      name: key.replace(/_+/g, ' '),
      property: key.toLowerCase().replace(/\w/, (firstLetter) => firstLetter.toLowerCase()),
      gid: gid,
    })
  }
  return results
}

export const toSnakeCase = (str) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_')

export const formatNumber = (number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number)

export const formatDecimalNumber = (number) => {
  const formattedNumber = typeof number == 'string' ? parseFloat(number) : number
  if (Number.isInteger(formattedNumber)) {
    return formattedNumber
  } else {
    return formattedNumber.toFixed(2)
  }
}

export const notificationTime = (dateString) => {
  const now = new Date()
  const date = new Date(dateString)

  const seconds = Math.floor((now - date) / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)
  if (minutes < 60) {
    return `${minutes} min${minutes > 1 ? 's' : ''} ago`
  } else if (hours < 24) {
    return `${hours}h ago`
  } else if (days < 7) {
    return `${days} day${days > 1 ? 's' : ''} ago`
  } else if (weeks < 8) {
    return `${weeks} week${weeks > 1 ? 's' : ''} ago`
  } else {
    return `${weeks} weeks ago`
  }
}

export const formatIsoDateTime = (date) => {
  const dateObject = new Date(date)
  return `${dateObject.getUTCFullYear()}-${(dateObject.getUTCMonth() + 1)
    .toString()
    .padStart(2, '0')}-${dateObject.getUTCDate().toString().padStart(2, '0')}`
}

export const markupCost = async (markupPercentage, subTotal) => {
  return await calculateMarkupCost.mutate({
    input: {
      markupPercentage: markupPercentage,
      subTotal: subTotal,
    },
  })
}
