import { ref } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { provideApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/helpers/apolloClient.js'

import DELETE_EQUIPMENT from '../graphql/mutations/deleteProposalEquipment.gql'
import DELETE_LABORER from '../graphql/mutations/deleteProposalLaborer.gql'
import DELETE_MISCELLANEOUS_COST from '../graphql/mutations/deleteProposalMiscellaneousCost.gql'
import DELETE_PROPOSAL_WORK from '../graphql/mutations/deleteProposalWork.gql'
import DELETE_PROPOSAL_WORK_PROPERTIES from '../graphql/mutations/deleteProposalWorkProperties.gql'
import DELETE_SUBCONTRACTOR from '../graphql/mutations/deleteProposalSubcontractor.gql'
import REMOVE_LCH_WORK from '../graphql/mutations/deleteProposalLchWork.gql'
import DELETE_NOTE from '../graphql/mutations/deleteNote.gql'
import DELETE_OPTION from '../graphql/mutations/deleteOption.gql'

provideApolloClient(apolloClient)

const responseMessage = ref(null)
const proposalRemoveEquipment = useMutation(DELETE_EQUIPMENT)
const proposalRemoveLaborer = useMutation(DELETE_LABORER)
const proposalRemoveMiscellaneousCost = useMutation(DELETE_MISCELLANEOUS_COST)
const proposalRemoveWork = useMutation(DELETE_PROPOSAL_WORK)
const proposalRemoveWorkProperties = useMutation(DELETE_PROPOSAL_WORK_PROPERTIES)
const proposalRemoveSubcontractor = useMutation(DELETE_SUBCONTRACTOR)
const proposalRemoveLchWork = useMutation(REMOVE_LCH_WORK)
const deleteNote = useMutation(DELETE_NOTE)
const deleteOption = useMutation(DELETE_OPTION)

export async function useDeleteTableData(name, id, rowData) {
  let responseData = ref(await deleteMutation(name, id, rowData))
  return { responseData, responseMessage }
}

const deleteMutation = async (name, id, rowData) => {
  if (name == 'equipmentData' || name == 'gradingEquipmentData') {
    responseMessage.value = { showToast: true, title: 'Equipment removed successfully!' }
    return await proposalRemoveEquipment.mutate({
      input: {
        ids: id,
      },
    })
  } else if (name == 'laborerData' || name == 'gradingLaborerData') {
    responseMessage.value = { showToast: true, title: 'Laborer removed successfully!' }
    return await proposalRemoveLaborer.mutate({
      input: {
        ids: id,
      },
    })
  } else if (name == 'miscellaneousCostData') {
    responseMessage.value = { showToast: true, title: 'MiscellaneousCost removed successfully!' }
    return await proposalRemoveMiscellaneousCost.mutate({
      input: {
        ids: id,
      },
    })
  } else if (name == 'constructionMaterialsData') {
    await proposalRemoveWorkProperties.mutate({
      input: { ids: rowData.map((item) => item.id) },
    })
    responseMessage.value = { showToast: true, title: 'Material removed successfully!' }
    return await proposalRemoveWork.mutate({
      input: {
        ids: id,
      },
    })
  } else if (name == 'haulAndDumpData') {
    responseMessage.value = { showToast: true, title: 'Material removed successfully!' }
    return await proposalRemoveLchWork.mutate({
      input: { ids: id },
    })
  } else if (name == 'subcontractorsData') {
    responseMessage.value = { showToast: true, title: 'Subcontractor removed successfully!' }
    return await proposalRemoveSubcontractor.mutate({
      input: {
        id: id,
      },
    })
  } else if (name == 'optionsData') {
    responseMessage.value = { showToast: true, title: 'Option removed successfully!' }
    return await Promise.all(
      rowData.data.map(async (row) => {
        await deleteNote.mutate({ input: { id: row.note.id } })
        return await deleteOption.mutate({ input: { id: row.id } })
      })
    )
  } else {
    responseMessage.value = { showToast: true, title: 'Material removed successfully!' }
    return await proposalRemoveWork.mutate({
      input: {
        ids: id,
      },
    })
  }
}
