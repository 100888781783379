import { defineStore } from 'pinia'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useQuery } from '@vue/apollo-composable'

import GET_USER_DATA from '../graphql/queries/getUserData.gql'
import GET_USER_NOTIFICATIONS from '../graphql/queries/getUserNotifications.gql'

export const useUserStore = defineStore('user', () => {
  const span = ref('year')
  const userDataQuery = useQuery(GET_USER_DATA, { span: span })
  const userNotificationsQuery = useQuery(GET_USER_NOTIFICATIONS)

  const userData = computed(() => userDataQuery.result?.value?.me)
  const userDataLoading = computed(() => userDataQuery.loading)

  const notifications = ref(null)

  const fetchNotifications = async () => {
    await userNotificationsQuery.refetch()
    notifications.value = userNotificationsQuery.result?.value?.notifications || []
  }

  const fetchUserData = async () => {
    await userDataQuery.refetch()
  }

  onMounted(() => {
    fetchNotifications()
  })

  const pollingInterval = setInterval(() => {
    fetchNotifications()
  }, 10000)

  onBeforeUnmount(() => {
    clearInterval(pollingInterval)
  })

  const updateSpan = (newSpan) => {
    span.value = newSpan
  }

  return { userData, fetchUserData, userDataLoading, notifications, fetchNotifications, updateSpan }
})
