import { ref } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { provideApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/helpers/apolloClient.js'

import CREATE_EQUIPMENT_SERVICE from '../graphql/mutations/createEquipmentService.gql'
import CREATE_LABORER_SERVICE from '../graphql/mutations/createLaborerService.gql'
import CREATE_MISC_SERVICE from '../graphql/mutations/createMiscService.gql'
import CREATE_PROPOSALS_SERVICES_WORK from '../graphql/mutations/createProposalsServicesWork.gql'
import CREATE_PROPOSALS_WORK_PROPERTY from '../graphql/mutations/createProposalsWorkProperty.gql'
import CREATE_MATERIALS_COST from '../graphql/mutations/createMaterialsCost.gql'
import CREATE_PROPOSALS_SERVICES_ITEM from '../graphql/mutations/createProposalsServicesItem.gql'
import CREATE_PROPOSALS_SERVICES_SUBCONTRACTOR from '../graphql/mutations/createSubcontractorsService.gql'
import CREATE_LCH_WORK from '../graphql/mutations/createProposalLchWork.gql'
import CREATE_OPTION from '../graphql/mutations/createOption.gql'
import CREATE_NOTE from '../graphql/mutations/createNote.gql'

import { markupCost } from '../utils/utility_methods'

provideApolloClient(apolloClient)
const responseMessage = ref(null)

const equipmentServiceCreate = useMutation(CREATE_EQUIPMENT_SERVICE)
const proposalsServicesMiscCreate = useMutation(CREATE_MISC_SERVICE)
const laborerServiceCreate = useMutation(CREATE_LABORER_SERVICE)
const proposalServiceWorkCreate = useMutation(CREATE_PROPOSALS_SERVICES_WORK)
const proposalWorkPropertyCreate = useMutation(CREATE_PROPOSALS_WORK_PROPERTY)
const proposalsServicesItemCreate = useMutation(CREATE_PROPOSALS_SERVICES_ITEM)
const proposalsServicesSubcontractorCreate = useMutation(CREATE_PROPOSALS_SERVICES_SUBCONTRACTOR)
const proposalAddLchWork = useMutation(CREATE_LCH_WORK)
const createOption = useMutation(CREATE_OPTION)
const createNote = useMutation(CREATE_NOTE)

export async function useAddRowData(
  name,
  newRow,
  proposalsServiceId,
  tablesData,
  gradingServiceId,
  proposalId,
  proposalServices
) {
  let responseData = ref(
    await addMutation(
      name,
      newRow,
      proposalsServiceId,
      tablesData,
      gradingServiceId,
      proposalId,
      proposalServices
    )
  )
  return { responseData, responseMessage }
}

async function proposalsServicesItemMutation(payload) {
  return await proposalsServicesItemCreate.mutate({
    input: { input: payload },
  })
}

const addMutation = async (
  name,
  newRow,
  proposalsServiceId,
  tablesData,
  gradingServiceId,
  proposalId,
  proposalServices
) => {
  if (name == 'equipmentData' || name == 'gradingEquipmentData') {
    responseMessage.value = { showToast: true, title: 'New Equipment created successfully!' }
    let payload = tablesData.value[name].savePayload.map((item) => ({
      equipmentId: item.id,
      name: item.name,
      quantity: parseInt(item.quantity),
      hoursPerDay: parseInt(item.hoursPerDay),
      totalDaysOnJob: parseInt(item.totalDaysOnJob),
      fuelCharge: item.fuelChargePercentage,
      costPerUnit: item.costPerUnit,
    }))
    const createEquipmentService = await equipmentServiceCreate.mutate({
      input: { input: payload },
    })
    if (name == 'gradingEquipmentData') {
      await proposalsServicesItemMutation(
        createEquipmentService.data.proposalsServicesEquipmentCreate.proposalsServicesEquipment.map(
          (item) => ({ proposalsServiceId: proposalsServiceId, itemId: item.id })
        )
      )
      return await proposalsServicesItemMutation(
        createEquipmentService.data.proposalsServicesEquipmentCreate.proposalsServicesEquipment.map(
          (item) => ({ proposalsServiceId: gradingServiceId, itemId: item.id })
        )
      )
    } else {
      return await proposalsServicesItemMutation(
        createEquipmentService.data.proposalsServicesEquipmentCreate.proposalsServicesEquipment.map(
          (item) => ({ proposalsServiceId: proposalsServiceId, itemId: item.id })
        )
      )
    }
  } else if (name == 'laborerData' || name == 'gradingLaborerData') {
    responseMessage.value = { showToast: true, title: 'New Laborer created successfully!' }
    let payload = tablesData.value[name].savePayload.map((item) => ({
      laborerId: item.id,
      name: item.name,
      quantity: parseInt(item.quantity),
      hoursOnSite: parseInt(item.hoursOnSite),
      costPerUnit: item.costPerUnit,
      daysOnSite: parseInt(item.daysOnSite),
      overtimeCostPerUnit: item.overtimeCostPerUnit,
      publicWorksCostPerHour: item.publicWorksCostPerUnit,
      totalHours: item.totalHours,
      totalRegularPay: item.totalRegularPay,
      totalOvertimePay: item.totalOvertimePay,
    }))
    const createLaborerService = await laborerServiceCreate.mutate({
      input: { input: payload },
    })
    if (name == 'gradingLaborerData') {
      await proposalsServicesItemMutation(
        createLaborerService.data.proposalsServicesLaborerCreate.proposalsServicesLaborer.map(
          (item) => ({ proposalsServiceId: proposalsServiceId, itemId: item.id })
        )
      )
      return await proposalsServicesItemMutation(
        createLaborerService.data.proposalsServicesLaborerCreate.proposalsServicesLaborer.map(
          (item) => ({ proposalsServiceId: gradingServiceId, itemId: item.id })
        )
      )
    } else {
      return await proposalsServicesItemMutation(
        createLaborerService.data.proposalsServicesLaborerCreate.proposalsServicesLaborer.map(
          (item) => ({ proposalsServiceId: proposalsServiceId, itemId: item.id })
        )
      )
    }
  } else if (name == 'miscellaneousCostData') {
    responseMessage.value = {
      showToast: true,
      title: 'New MiscellaneousCost created successfully!',
    }
    let payload = tablesData.value.miscellaneousCostData.savePayload.map((item) => ({
      miscellaneousCostId: item.id,
      quantity: parseInt(item.quantity),
      costPerUnit: parseFloat(item.costPerUnit),
      unitOfMeasurement: item.unitOfMeasurement,
      name: item.name,
      addStripingOnProposal: item.addStripingOnProposal ?? false,
    }))
    const createMiscService = await proposalsServicesMiscCreate.mutate({
      input: { input: payload },
    })
    return await proposalsServicesItemMutation(
      createMiscService.data.proposalsServicesMiscCreate.proposalsServicesMisc.map((item) => ({
        proposalsServiceId: proposalsServiceId,
        itemId: item.id,
      }))
    )
  } else if (name == 'constructionMaterialsData') {
    await Promise.all(
      newRow.map(async (item) => {
        let proposalServiceWork = await proposalServiceWorkCreate.mutate({
          input: {
            input: {
              workTypeId: item.workTypeId,
              costPerUnit: parseFloat(item.costPerUnit),
              quantity: parseFloat(item.totals.quantity),
            },
          },
        })
        await proposalsServicesItemMutation(
          proposalServiceWork.data.proposalsServicesWorkCreate.proposalsServicesWork.map(
            (proposalItem) => ({
              proposalsServiceId: proposalsServiceId,
              itemId: proposalItem.id,
            })
          )
        )
        await Promise.all(
          item.properties.map(async (property) => {
            await proposalWorkPropertyCreate.mutate({
              input: {
                input: {
                  proposalsServicesWorkId:
                    proposalServiceWork.data.proposalsServicesWorkCreate.proposalsServicesWork[0]
                      .id,
                  workTypePropertyId: property.id,
                  name: property.name,
                  value: property.value,
                  unitOfMeasurement: property.unitOfMeasurement,
                },
              },
            })
          })
        )
      })
    )
    responseMessage.value = { showToast: true, title: 'New Material created successfully!' }
  } else if (name == 'haulAndDumpData') {
    await Promise.all(
      tablesData.value[name].savePayload.map(async (item) => {
        await proposalAddLchWork.mutate({
          input: {
            input: {
              lchWorkId: item.id,
              services: [
                proposalServices.find(
                  (serviceItem) => serviceItem.service.name == item.services.nodes[0].name
                ).id,
              ],
              area: parseFloat(item.area),
              depth: parseFloat(item.depth),
              loadCutHaulTime: parseFloat(item.loadCutHaulTime),
              costTrucking: parseFloat(item.costTrucking),
              costDump: parseFloat(item.costDump),
              maxTruckLoad: parseFloat(item.maxTruckLoad),
            },
          },
        })
      })
    )
    responseMessage.value = { showToast: true, title: 'New Material created successfully!' }
  } else if (name == 'subcontractorsData') {
    responseMessage.value = {
      showToast: true,
      title: 'New Subcontractor created successfully!',
    }
    let payload = await Promise.all(
      tablesData.value[name].savePayload.map(async (item) => {
        const cost = await markupCost(
          isNaN(parseFloat(item.markup)) ? 0 : parseFloat(item.markup),
          parseFloat(item.cost)
        )
        return {
          cost: cost.data.markupCostCalculate.totalCost,
          bonds: item.bonds,
          company: item.company,
          quoteNumber: item.quoteNumber,
          workDescription: item.workDescription,

          markup: isNaN(parseFloat(item.markup)) ? 0 : parseFloat(item.markup),
        }
      })
    )
    const createSubcontractorService = await proposalsServicesSubcontractorCreate.mutate({
      input: { input: payload },
    })
    return await proposalsServicesItemMutation(
      createSubcontractorService.data.proposalsServicesSubcontractorCreate.proposalsServicesSubcontractors.map(
        (item) => ({
          proposalsServiceId: proposalsServiceId,
          itemId: item.id,
        })
      )
    )
  } else if (name == 'optionsData') {
    responseMessage.value = { showToast: true, title: 'New Option created successfully!' }
    return await Promise.all(
      tablesData.value[name].savePayload.map(async (item) => {
        const option = await createOption.mutate({
          input: {
            input: {
              name: item.name,
              proposalId: proposalId,
              estimatedCost: parseFloat(item.estimatedCost),
            },
          },
        })
        await createNote.mutate({
          input: {
            input: { ownerId: option.data.optionCreate.option.id, summary: item.note.summary },
          },
        })
      })
    )
  } else {
    let payload = tablesData.value[name].savePayload.map((item) => ({
      workTypeId: item.id,
      costPerUnit: item.stripingWork.newWorkCost,
      quantity: parseInt(item.quantity),
      newLayoutSelected: item.newLayoutSelected,
      doubleCoatSelected: item.doubleCoatSelected,
    }))
    let proposalServiceWork = await proposalServiceWorkCreate.mutate({
      input: {
        input: payload,
      },
    })
    await proposalsServicesItemMutation(
      proposalServiceWork.data.proposalsServicesWorkCreate.proposalsServicesWork.map((item) => ({
        proposalsServiceId: proposalsServiceId,
        itemId: item.id,
      }))
    )
    responseMessage.value = { showToast: true, title: 'New Material created successfully!' }
  }
}
