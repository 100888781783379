<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-primary-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full max-w-xs flex-1 flex-col bg-primary-800 pt-5 pb-4"
            >
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex flex-shrink-0 items-center px-4">
                <img class="h-12 w-auto" :src="'/assets/Bidproz.png'" alt="Bidproz" />
              </div>
              <div class="no-print mt-5 h-0 flex-1 overflow-y-auto">
                <nav class="no-print space-y-1 px-2">
                  <a
                    v-for="item in filteredNavigation"
                    :key="item.name"
                    :href="item.href"
                    :class="[
                      item.current
                        ? 'bg-primary-900 text-white'
                        : 'text-primary-300 hover:bg-primary-700 hover:text-white',
                      'no-print group flex items-center px-2 py-2 text-base font-medium rounded-md',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        item.current
                          ? 'text-primary-300'
                          : 'text-primary-400 group-hover:text-primary-300',
                        'no-print mr-4 flex-shrink-0 h-6 w-6',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}
                  </a>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="no-print hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex min-h-0 flex-1 flex-col bg-primary-800">
        <div class="flex h-16 flex-shrink-0 items-center bg-primary-900 px-4">
          <img class="h-12 w-auto" :src="companyLogo" alt="Bidproz" />
        </div>
        <div class="flex flex-1 flex-col overflow-y-auto">
          <nav class="flex-1 space-y-1 px-2 py-4">
            <template v-for="item in filteredNavigation">
              <router-link
                v-if="!item.children"
                @click="updateTab(item)"
                :key="item.name"
                :to="item.path"
                :class="[
                  item.current
                    ? 'bg-primary-900 text-white'
                    : 'text-primary-300 hover:bg-primary-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                ]"
              >
                <component
                  :is="item.icon"
                  :class="[
                    item.current
                      ? 'text-primary-300'
                      : 'text-primary-400 group-hover:text-primary-300',
                    'mr-3 flex-shrink-0 h-6 w-6',
                  ]"
                  aria-hidden="true"
                />
                {{ item.name }}
              </router-link>

              <!-- Render this for items with children -->
              <Disclosure as="div" v-else v-slot="{ open }">
                <DisclosureButton
                  :class="[
                    'text-primary-300 hover:bg-primary-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full',
                  ]"
                >
                  <ChevronRightIcon
                    :class="[
                      open ? 'rotate-90 text-primary-500' : 'text-primary-400',
                      'h-5 w-5 shrink-0 mr-2',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </DisclosureButton>
                <DisclosurePanel as="ul" class="mt-1 px-2">
                  <li v-for="subItem in item.children" :key="subItem.name">
                    <router-link :to="subItem.path">
                      <DisclosureButton
                        :class="[
                          subItem.current
                            ? 'bg-primary-900 text-white'
                            : 'text-primary-300 hover:bg-primary-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full',
                        ]"
                      >
                        {{ subItem.name }}
                      </DisclosureButton>
                    </router-link>
                  </li>
                </DisclosurePanel>
              </Disclosure>
            </template>
          </nav>
        </div>
      </div>
    </div>
    <div class="flex flex-col md:pl-64">
      <div class="no-print sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
        <button
          type="button"
          class="no-print border-r border-success-200 px-4 text-primary-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon class="no-print h-6 w-6" aria-hidden="true" />
        </button>
        <div class="no-print flex flex-1 justify-between px-4">
          <div class="flex flex-1"></div>
          <div class="ml-4 flex items-center md:ml-6">
            <button
              type="button"
              class="no-print rounded-full bg-white text-primary-400 hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <Popover class="relative">
                <PopoverButton
                  class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-primary-900"
                >
                  <BellIcon class="no-print h-6 w-6" aria-hidden="true" />
                  <span
                    v-if="unreadNotifications.length"
                    class="absolute right-0 top-0 block h-2.5 w-2.5 rounded-full bg-blue-600 ring-2 ring-white"
                  />
                </PopoverButton>

                <transition
                  enter-active-class="transition ease-out duration-200"
                  enter-from-class="opacity-0 translate-y-1"
                  enter-to-class="opacity-100 translate-y-0"
                  leave-active-class="transition ease-in duration-150"
                  leave-from-class="opacity-100 translate-y-0"
                  leave-to-class="opacity-0 translate-y-1"
                >
                  <PopoverPanel
                    v-slot="{ close }"
                    class="absolute -left-3/4 z-10 mt-5 flex w-screen max-w-max -translate-x-3/4 px-4"
                  >
                    <div
                      class="w-screen max-w-sm flex-auto rounded-3xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-success-900/5"
                    >
                      <div
                        v-show="notifications.length == 0"
                        class="py-44 relative flex justify-center items-center"
                      >
                        <p class="px-2 text-lg text-primary-700">No Notifications</p>
                      </div>
                      <div
                        v-for="notification in notifications.slice(0, 4)"
                        :key="notification.id"
                        class="relative rounded-lg p-1 hover:bg-primary-50 cursor-pointer"
                      >
                        <router-link
                          :to="notification.redirectUrl ? notification.redirectUrl : '/'"
                          @click="handleNotification(notification, close)"
                        >
                          <div class="flex items-center gap-x-3">
                            <p class="mt-0.5 text-primary-600 text-left">
                              {{ notification.message }}
                            </p>
                            <div
                              v-show="!notification.readAt"
                              class="text-primary-600 bg-blue-500/10 flex-none rounded-full p-1"
                            >
                              <div class="h-2 w-2 rounded-full bg-current" />
                            </div>
                          </div>
                          <p class="text-sm text-primary-500 text-left">
                            {{ notificationTime(notification.createdAt) }}
                          </p></router-link
                        >
                      </div>
                    </div>
                  </PopoverPanel>
                </transition>
              </Popover>
            </button>

            <!-- Profile dropdown -->
            <Menu as="div" class="no-print relative ml-3">
              <div>
                <MenuButton
                  class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    v-if="store.userData?.profilePictureUrl"
                    class="h-10 w-10 rounded-full"
                    :src="store.userData.profilePictureUrl"
                    alt="profile"
                  />
                  <UserIcon v-else class="no-print h-6 w-6" aria-hidden="true" />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                    @click="handleClick(item.name)"
                  >
                    <router-link
                      :to="item.path"
                      :class="[
                        active ? 'bg-primary-100' : '',
                        'block px-4 py-2 text-sm text-primary-700',
                      ]"
                    >
                      {{ item.name }}
                    </router-link>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <main class="flex-1">
        <div class="py-6">
          <div class="mx-auto max-w-7xl">
            <slot></slot>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { useMutation } from '@vue/apollo-composable'

import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  Bars3BottomLeftIcon,
  BellIcon,
  BuildingOffice2Icon,
  ClipboardDocumentListIcon,
  PresentationChartLineIcon,
  UserIcon,
  XMarkIcon,
  HomeIcon,
  PencilSquareIcon,
} from '@heroicons/vue/24/outline'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { logoutUser, notificationTime } from '../../utils/utility_methods'
import companyLogo from '@/assets/Bidproz.png'
import { useUserStore } from '../../stores/user'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import UPDATE_NOTIFICATION from '../../graphql/mutations/updateNotification.gql'

const store = useUserStore()
const filteredNavigation = ref(null)
const notifications = ref([])

const updateNotification = useMutation(UPDATE_NOTIFICATION)
const unreadNotifications = computed(() =>
  notifications.value.filter((notification) => !notification.readAt)
)

watch(
  () => store.userData,
  (newUserData) => {
    if (newUserData) {
      filteredNavigation.value = newUserData?.roles.nodes.find(
        (item) => item.name == 'company_admin'
      )
        ? navigation.value
        : navigation.value.filter(
            (item) =>
              item.name !== 'Company Profile' &&
              item.name !== 'Services' &&
              item.name !== 'Filtering Options'
          )
    }
  }
)

watch(
  () => store.notifications,
  (data) => {
    notifications.value = data.nodes
  }
)

const navigation = ref([
  { name: 'Home', path: '/', icon: HomeIcon, current: true },
  { name: 'Dashboard', path: '/dashboard', icon: PresentationChartLineIcon, current: false },
  {
    name: 'New Proposal',
    path: '/estimations/new',
    icon: PencilSquareIcon,
    current: false,
  },
  { name: 'Proposals', path: '/estimations', icon: ClipboardDocumentListIcon, current: false },
  {
    name: 'Services',
    icon: ClipboardDocumentListIcon,
    current: false,
    children: [
      { name: 'Equipment', path: '/service/equipment' },
      { name: 'Laborers', path: '/service/laborers' },
      { name: 'Miscellaneous Costs', path: '/service/miscellaneous_costs' },
      { name: 'Materials Costs', path: '/service/materials_cost' },
      { name: 'Striping Works', path: '/service/striping_works' },
    ],
  },
  {
    name: 'Filtering Options',
    icon: ClipboardDocumentListIcon,
    current: false,
    children: [
      { name: 'Exclusions', path: '/exclusions' },
      { name: 'Inclusions', path: '/inclusions' },
    ],
  },
  {
    name: 'Company Profile',
    path: '/company',
    icon: BuildingOffice2Icon,
    current: false,
  },
  {
    name: 'Reports',
    path: '/reports',
    icon: BuildingOffice2Icon,
    current: false,
  },
])

const userNavigation = [
  { name: 'Profile', path: '/profile' },
  { name: 'Sign out', path: '/' },
]
const sidebarOpen = ref(true)
const handleClick = async (menuName) => {
  if (menuName === 'Sign out') {
    await logoutUser()
  }
}
const updateTab = (selectedTab) => {
  navigation.value.map((item) => {
    item.name === selectedTab.name ? (item.current = true) : (item.current = false)
  })
}

const handleNotification = async (notification, close) => {
  if (!notification.readAt) {
    await updateNotification.mutate({
      input: { notification: { id: notification.id, readAt: new Date().toISOString() } },
    })
    await store.fetchNotifications()
  }
  close()
}
</script>
