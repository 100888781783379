<template>
  <Loader v-if="store.userDataLoading.value || loading" />
  <div v-else class="divide-y divide-gray-400/5">
    <div
      class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8"
    >
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-400">Personal Information</h2>
      </div>
      <form class="md:col-span-2" @submit.prevent="onSubmit">
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div class="col-span-full flex items-center gap-x-8">
            <img
              v-if="userProfileURl"
              :src="userProfileURl"
              class="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
              alt="profile-image"
            />
            <UserCircleIcon v-else class="h-28 w-28 text-gray-600" />
            <div>
              <label
                for="file-input"
                class="rounded-md bg-primary-600 hover:bg-primary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm cursor-pointer"
              >
                Change Image
                <input
                  id="file-input"
                  type="file"
                  ref="fileInput"
                  style="display: none"
                  @change="uploadImage"
                />
              </label>

              <p class="mt-2 text-xs leading-5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="first-name" class="block text-sm font-medium leading-6 text-gray-700"
              >First name</label
            >
            <div class="mt-2">
              <input
                type="text"
                name="firstName"
                id="first-name"
                v-model="firstName"
                v-bind="firstNameAttrs"
                autocomplete="given-name"
                class="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-400/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
            <p
              v-if="userFormErrors.firstName"
              class="mt-2 first-letter:capitalize text-failure-500 text-xs"
            >
              {{ userFormErrors.firstName }}
            </p>
          </div>

          <div class="sm:col-span-3">
            <label for="last-name" class="block text-sm font-medium leading-6 text-gray-700"
              >Last name</label
            >
            <div class="mt-2">
              <input
                type="text"
                name="lastName"
                id="last-name"
                v-model="lastName"
                v-bind="lastNameAttrs"
                autocomplete="family-name"
                class="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-400/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
            <p
              v-if="userFormErrors.lastName"
              class="mt-2 first-letter:capitalize text-failure-500 text-xs"
            >
              {{ userFormErrors.lastName }}
            </p>
          </div>

          <div class="col-span-full">
            <label for="email" class="block text-sm font-medium leading-6 text-gray-700"
              >Phone Number</label
            >
            <div class="mt-2">
              <input
                id="phone"
                name="phone"
                type="tel"
                v-model="phone"
                v-bind="phoneAttrs"
                class="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-400/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
            <p
              v-if="userFormErrors.phone"
              class="mt-2 first-letter:capitalize text-failure-500 text-xs"
            >
              {{ userFormErrors.phone }}
            </p>
          </div>
        </div>

        <div class="mt-8 flex">
          <button
            type="submit"
            class="rounded-md bg-success-500 hover:bg-success-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </div>

    <div
      class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8"
    >
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-400">Change password</h2>
        <p class="mt-1 text-sm leading-6 text-gray-400">
          Update your password associated with your account.
        </p>
      </div>

      <form @submit.prevent="onPasswordSubmit" class="md:col-span-2">
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div class="col-span-full">
            <label for="current-password" class="block text-sm font-medium leading-6 text-gray-700"
              >Current password</label
            >
            <div class="mt-2">
              <input
                id="current-password"
                name="currentPassword"
                type="password"
                v-model="currentPassword"
                v-bind="currentPasswordAttrs"
                autocomplete="current-password"
                class="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-400/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
              <p
                v-if="passwordErrors.currentPassword"
                class="mt-2 first-letter:capitalize text-failure-500 text-xs"
              >
                {{ passwordErrors.currentPassword }}
              </p>
            </div>
          </div>

          <div class="col-span-full">
            <label for="new-password" class="block text-sm font-medium leading-6 text-gray-700"
              >New password</label
            >
            <div class="mt-2">
              <input
                id="new-password"
                name="newPassword"
                type="password"
                v-model="newPassword"
                v-bind="newPasswordAttrs"
                autocomplete="new-password"
                class="block w-full rounded-md border-0 bg-gray-100 py-1.5 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-400/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
              <p
                v-if="passwordErrors.newPassword"
                class="mt-2 first-letter:capitalize text-failure-500 text-xs"
              >
                {{ passwordErrors.newPassword }}
              </p>
            </div>
          </div>
        </div>
        <div>
          <p v-if="errorMessage" class="mt-3 first-letter:capitalize text-failure-500 text-xs">
            {{ errorMessage }}
          </p>
        </div>

        <div class="mt-8 flex">
          <button
            type="submit"
            class="rounded-md bg-success-500 hover:bg-success-600 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
  <Toast v-if="toast.showToast" :title="toast.title" />
</template>

<script setup>
import { ref, watch } from 'vue'
import axios from 'axios'
import { useForm } from 'vee-validate'
import { object, string } from 'yup'
import { useMutation } from '@vue/apollo-composable'
import { UserCircleIcon } from '@heroicons/vue/20/solid'

import Loader from '@/components/layout/Loader.vue'
import Toast from '@/components/layout/Toast.vue'
import UPDATE_USER_PROFILE from '../graphql/mutations/updateUserProfile.gql'

import { useUserStore } from '../stores/user'
import { getCookie } from '../utils/utility_methods'

const profileUpdate = useMutation(UPDATE_USER_PROFILE)
const errorMessage = ref(null)
const userProfileURl = ref(null)
const toast = ref({ showToast: false, title: '' })

const userFormValidationSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  phone: string().required(),
})

const passwordValidationSchema = object({
  currentPassword: string().min(12, 'Current Password must be at least 12 characters').required(),
  newPassword: string().min(12, 'New Password must be at least 12 characters').required(),
})

const {
  defineField: userFormDefineField,
  handleSubmit: userFormSubmit,
  errors: userFormErrors,
} = useForm({
  validationSchema: userFormValidationSchema,
})

const {
  defineField: passwordFormDefineField,
  handleSubmit: handlePassword,
  errors: passwordErrors,
  resetForm,
} = useForm({
  validationSchema: passwordValidationSchema,
})

const loading = ref(false)
const fileInput = ref(null)
const store = useUserStore()

const [firstName, firstNameAttrs] = userFormDefineField('firstName', {
  validateOnModelUpdate: false,
})
const [lastName, lastNameAttrs] = userFormDefineField('lastName', {
  validateOnModelUpdate: false,
})
const [phone, phoneAttrs] = userFormDefineField('phone', {
  validateOnModelUpdate: false,
})
const [currentPassword, currentPasswordAttrs] = passwordFormDefineField('currentPassword')
const [newPassword, newPasswordAttrs] = passwordFormDefineField('newPassword')

watch(
  () => store.userData,
  (data) => {
    if (data) {
      firstName.value = data.firstName ?? ''
      lastName.value = data.lastName ?? ''
      phone.value = data.phone ?? ''
      userProfileURl.value = data.profilePictureUrl ?? ''
    }
  },
  { immediate: true }
)

const uploadImage = async () => {
  const file = fileInput.value.files[0]
  const formData = new FormData()
  formData.append('user[profile_picture]', file)
  try {
    const response = await axios.post(`/users/${store.userData.id}/attach_files`, formData, {
      headers: {
        'X-CSRF-Token': getCookie('csrf_token'),
        'Content-Type': 'multipart/form-data',
      },
    })
    userProfileURl.value = response.data.url
    toast.value = { showToast: true, title: 'Image updated Successfully!' }
  } catch (error) {
    console.log(error)
  }
}

const onSubmit = userFormSubmit(async (values) => {
  loading.value = true
  await profileUpdate.mutate({ input: { input: { id: store.userData.id, ...values } } })
  loading.value = false
})

const onPasswordSubmit = handlePassword(async (values) => {
  loading.value = true
  try {
    await axios.post('/change_password', {
      current_password: values.currentPassword,
      new_password: values.newPassword,
    })
    resetForm()
    errorMessage.value = null
    toast.value = { showToast: true, title: 'Password updated Successfully!' }
  } catch (error) {
    console.log(error)
    errorMessage.value = error.response.data.errors?.[0] ?? null
  }
  loading.value = false
})
</script>
