<template>
  <Loader v-if="loading" />
  <div v-else class="grid grid-cols-2 gap-10">
    <div>
      <BidTable
        :editable="editable"
        title="Asphalt Cost"
        :data="finalBidTab.Asphalt"
        :totals="{
          ...filterServiceTotals('Asphalt'),
          subcontractorTotal: filterSubcontractorTotals('Asphalt'),
        }"
        :markup="filterServiceMarkup('Asphalt')"
        @markup-total="handleTotalMarkup"
      />
      <BidTable
        :editable="editable"
        title="Concrete Cost"
        :data="finalBidTab.Concrete"
        :totals="{
          ...filterServiceTotals('Concrete'),
          subcontractorTotal: filterSubcontractorTotals('Concrete'),
        }"
        :markup="filterServiceMarkup('Concrete')"
        @markup-total="handleTotalMarkup"
      />
      <BidTable
        :editable="editable"
        title="Grading & Demo Cost"
        :data="finalBidTab.Grading"
        :totals="{
          ...filterServiceTotals('Grading'),
          subcontractorTotal: filterSubcontractorTotals('Grading'),
        }"
        :markup="filterServiceMarkup('Grading')"
        @markup-total="handleTotalMarkup"
      />
      <BidTable
        :editable="editable"
        title="Seal Coat Cost"
        :data="finalBidTab.SealCoating"
        :totals="{
          ...filterServiceTotals('Seal Coating'),
          subcontractorTotal: filterSubcontractorTotals('Seal Coating'),
        }"
        :markup="filterServiceMarkup('Seal Coating')"
        @markup-total="handleTotalMarkup"
      />
      <BidTable
        :editable="editable"
        title="Striping Cost"
        :data="finalBidTab.Striping"
        :totals="{
          ...filterServiceTotals('Striping'),
          subcontractorTotal: filterSubcontractorTotals('Striping'),
        }"
        :markup="filterServiceMarkup('Striping')"
        @markup-total="handleTotalMarkup"
      />
    </div>
    <div class="mt-14">
      <div class="overflow-hidden bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6 bg-primary-100">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Total Estimated Hard Cost</h3>
        </div>
        <div class="border-t border-gray-200">
          <dl>
            <div class="bg-primary-50 px-4 py-3 sm:grid grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Total Estimated Hard Cost</dt>
              <dd class="mt-1 text-sm text-gray-900 col-start-3 col-span-1 sm:mt-0">
                {{ formatNumber(serviceTotals) }}
              </dd>
            </div>
            <div
              v-for="(item, index) in finalBidTab.totalEstimatedHardCost"
              class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              :class="[{ 'bg-primary-50': index % 2 == 1 }, 'bg-white']"
            >
              <dt class="text-sm font-medium text-gray-500">{{ item.name }}</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                <p
                  v-if="
                    item.key == 'generalAdminCost' &&
                    !userStore?.userData?.roles?.nodes.find((item) => item.name == 'company_admin')
                  "
                >
                  {{ costPercentages[item.key]?.percentage }}%
                </p>
                <input
                  v-else-if="item.edit"
                  placeholder="0"
                  :value="formatDecimalNumber(costPercentages[item.key]?.percentage)"
                  type="number"
                  min="0"
                  @change="handleMarkupChange($event, item)"
                  class="block w-20 h-5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <p v-else>{{ costPercentage(displayTotal(item.key)) }}%</p>
              </dd>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                <div class="flex" v-if="item.key == 'discountPercentage'">
                  $
                  <input
                    placeholder="0"
                    :value="formatDecimalNumber(costPercentages[item.key]?.amount)"
                    type="number"
                    min="0"
                    @change="handleDiscountChange($event, item)"
                    class="mx-1 block w-20 h-5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>

                <p v-else>{{ formatNumber(parseFloat(displayTotal(item.key))) ?? 0 }}</p>
              </dd>
            </div>
            <div v-show="props.editable" class="mt-5">
              <div class="bg-white px-4 py-2 sm:px-6 flex items-center">
                <p class="text-sm font-medium col-span-3 text-gray-500">
                  Show Discount on Proposal
                </p>
                <input
                  type="checkbox"
                  class="ml-4 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
                  :checked="finalData.showDiscount"
                  @click="handleDisplayDiscountChange($event, item)"
                />
              </div>
              <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium col-span-3 text-gray-500">Pre-set Exclusion</dt>
                <div
                  v-for="item in proposalInclusionData.result.value.organization.exclusions.nodes"
                  class="relative flex items-start"
                >
                  <div class="flex h-5 items-center">
                    <input
                      :id="item.id"
                      type="checkbox"
                      :checked="finalData.exclusions.find((id) => id.exclusion.id == item.id)"
                      @click="handleExclusion($event, item)"
                      class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="comments" class="font-medium text-gray-700">{{ item.title }}</label>
                  </div>
                </div>
              </div>
              <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium col-span-3 text-gray-500">Inclusion</dt>
                <div
                  v-for="item in proposalInclusionData.result.value.organization.inclusions.nodes"
                  class="relative flex items-start"
                >
                  <div class="flex h-5 items-center">
                    <input
                      :id="item.id"
                      type="checkbox"
                      :checked="finalData.inclusions.find((id) => id.inclusion.id == item.id)"
                      @click="handleInclusion($event, item)"
                      class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-indigo-500"
                    />
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="comments" class="font-medium text-gray-700">{{ item.title }}</label>
                  </div>
                </div>
              </div>

              <div class="px-6 py-3">
                <div class="w-96">
                  <label class="block capitalize text-sm font-medium text-gray-700">Notes</label>
                  <textarea
                    class="w-full mt-1 block px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="description"
                    v-model="finalData.notes"
                    rows="3"
                    placeholder="Notes"
                    @blur="handleNotesChange($event)"
                  ></textarea>
                </div>
              </div>

              <div class="px-6 py-3">
                <div class="w-96">
                  <label class="block capitalize text-sm font-medium text-gray-700"
                    >Conditions</label
                  >
                  <textarea
                    class="w-full mt-1 block px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="description"
                    v-model="finalData.conditions"
                    rows="3"
                    placeholder="Conditions"
                    @blur="handleConditionsChange($event)"
                  ></textarea>
                </div>
              </div>

              <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="flex items-center justify-between text-sm font-medium text-gray-500">
                  Proposal Map
                </dt>
                <dd class="bg-primary-50 mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul
                    role="list"
                    class="divide-y divide-gray-200 rounded-md border border-gray-200"
                  >
                    <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                      <div class="flex w-0 flex-1 items-center">
                        <PaperClipIcon
                          class="h-5 w-5 flex-shrink-0 text-gray-500"
                          aria-hidden="true"
                        />
                        <span class="ml-2 w-0 flex-1 truncate"></span>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <input
                          class="block w-full text-sm text-primary-600 cursor-pointer"
                          type="file"
                          ref="fileInput"
                          @change="uploadDocument"
                        />
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
              <div class="px-6 py-3">
                <div class="mb-2 w-40">
                  <label for="validDate" class="block text-sm font-medium text-gray-500"
                    >Price is valid through</label
                  >
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="date"
                      id="validDate"
                      name="validDate"
                      @change="handleDate"
                      v-model="selectedValidDate"
                      class="form-input py-2 px-3 block w-full leading-5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>

                <div class="mt-6">
                  <label for="favcolor" class="text-sm font-medium text-gray-500"
                    >Select proposal color</label
                  >
                  <div class="flex items-center">
                    <div
                      v-for="item in colorOptions"
                      :key="item.id"
                      class="color-circle"
                      :class="{ 'ring-4 ring-gray-300 shadow-md': item.selected }"
                      :style="{ backgroundColor: item.color }"
                      @click="handleColorSelection(item)"
                    ></div>
                    <div
                      class="flex items-center border-solid border-2 border-gray-300 rounded p-2"
                    >
                      <PaintBrushIcon
                        class="h-5 w-5 flex-shrink-0 text-gray-500"
                        aria-hidden="true"
                      />
                      <input
                        type="color"
                        id="favcolor"
                        name="favcolor"
                        v-model="selectedThemeColor"
                        class="mx-1 block w-14 h-7 focus:ring focus:border-blue-300 border-gray-300 rounded-md shadow-sm"
                        @change="handleThemeColor"
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    @click="handlePreivewClick"
                    type="button"
                    class="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 mt-10 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Preview Details
                  </button>
                </div>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import BidTable from '@/components/layout/BidTable.vue'
import { finalBidTab } from '@/keys/constants'
import { useRoute, useRouter } from 'vue-router'
import { PaperClipIcon, PaintBrushIcon } from '@heroicons/vue/20/solid'
import { useQuery, useMutation } from '@vue/apollo-composable'
import axios from 'axios'
import { getCookie, formatDecimalNumber } from '../../utils/utility_methods'

import Loader from '@/components/layout/Loader.vue'

import { useUserStore } from '../../stores/user'

import { formatNumber, formatIsoDateTime } from '../../utils/utility_methods'
import GET_PROPOSAL_BY_ID from '../../graphql/queries/getProposalById.gql'
import GET_INCLUSION_AND_EXCLUSION from '../../graphql/queries/getInclusionsAndExclusions.gql'
import ADD_PROPOSAL_INCLUSION from '../../graphql/mutations/proposalAddInclusion.gql'
import ADD_PROPOSAL_EXCLUSION from '../../graphql/mutations/proposalAddExclusion.gql'
import REMOVE_PROPOSAL_INCLUSION from '../../graphql/mutations/proposalRemoveInclusion.gql'
import REMOVE_PROPOSAL_EXCLUSION from '../../graphql/mutations/proposalRemoveExclusion.gql'
import MARKUP_COST_CALCULATE from '../../graphql/mutations/calculations/markupCostCalculate.gql'
import MARKUP_PERCENTAGE_CALCULATE from '../../graphql/mutations/calculations/markupPercentageCalculate.gql'
import UPDATE_PROPOSAL from '../../graphql/mutations/updateProposal.gql'
import UPDATE_COMPANY_DETAILS from '../../graphql/mutations/updateCompanyDetails.gql'
import GET_COMPANY_DATA from '../../graphql/queries/getCompanyData.gql'

const props = defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
  proposalId: {
    type: String,
  },
})
const router = useRouter()
const route = useRoute()

onMounted(async () => {
  proposalByIdQuery.refetch()
  companyDetails.refetch()
})

const userStore = useUserStore()
const finalData = ref({
  inclusions: [],
  exclusions: [],
  markup: null,
  notes: null,
  conditions: null,
  showDiscount: false,
})
const serviceMarkupAmount = ref({})
const costPercentages = ref({
  additionalMarkupPercentage: { percentage: 0, amount: 0 },
  discountPercentage: { percentage: 0, amount: 0 },
  generalAdminCost: { percentage: 0, amount: 0 },
})
const fileInput = ref(null)
const selectedValidDate = ref(null)
const selectedThemeColor = ref('#ffffff')
const colorOptions = ref([
  { id: 1, color: '#696C6C', selected: false },
  { id: 2, color: '#B00001', selected: false },
  { id: 3, color: '#3F933C', selected: false },
  { id: 4, color: '#07275c', selected: true },
  { id: 5, color: '#2899BB', selected: false },
])

const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id ? route.params.id : props.proposalId,
})
const companyDetails = useQuery(GET_COMPANY_DATA)

const filterServiceMarkup = (service) =>
  proposalByIdQuery.result.value.proposal.proposalsServices.nodes.filter(
    (item) => item.service.name == service
  )[0]

watch([proposalByIdQuery.result, companyDetails.result], async ([proposalData, companyData]) => {
  if (proposalData && companyData) {
    finalData.value.inclusions = proposalData.proposal.inclusions.nodes
    finalData.value.exclusions = proposalData.proposal.exclusions.nodes
    finalData.value.notes = proposalData.proposal.notes
    finalData.value.conditions = proposalData.proposal.conditions
    finalData.value.showDiscount = proposalData.proposal.showDiscount
    if (proposalData.proposal.goodThrough) {
      selectedValidDate.value = formatIsoDateTime(proposalData.proposal.goodThrough)
    }
    if (proposalData.proposal.pdfThemeColor) {
      selectedThemeColor.value = proposalData.proposal.pdfThemeColor
      colorOptions.value.forEach((item) => {
        if (item.color == proposalData.proposal.pdfThemeColor) {
          item.selected = true
          selectedThemeColor.value = '#d3d3d3'
        } else {
          item.selected = false
        }
      })
    }
    costPercentages.value.additionalMarkupPercentage.percentage =
      proposalData.proposal.additionalMarkupPercentage
    costPercentages.value.discountPercentage.percentage = proposalData.proposal.discountPercentage
    costPercentages.value.generalAdminCost.percentage = companyData.organization.generalAdminCost
    if (proposalData.proposal.additionalMarkupPercentage) {
      const cost = await calculateMarkupCost.mutate({
        input: {
          markupPercentage: proposalData.proposal.additionalMarkupPercentage,
          subTotal: serviceTotals.value,
        },
      })
      costPercentages.value.additionalMarkupPercentage.amount =
        cost.data.markupCostCalculate.markupCost
    }
    if (proposalData.proposal.discountPercentage) {
      const cost = await calculateMarkupCost.mutate({
        input: {
          markupPercentage: proposalData.proposal.discountPercentage,
          subTotal: serviceTotals.value,
        },
      })
      costPercentages.value.discountPercentage.amount = cost.data.markupCostCalculate.markupCost
    }
    if (companyData.organization.generalAdminCost) {
      const cost = await calculateMarkupCost.mutate({
        input: {
          markupPercentage: companyData.organization.generalAdminCost,
          subTotal: overheadTotals.value,
        },
      })
      costPercentages.value.generalAdminCost.amount = cost.data.markupCostCalculate.markupCost
    }
    workTypeTotals('materialCost')
  }
})

const proposalInclusionData = useQuery(GET_INCLUSION_AND_EXCLUSION)
const addInclusion = useMutation(ADD_PROPOSAL_INCLUSION)
const removeInclusion = useMutation(REMOVE_PROPOSAL_INCLUSION)
const addExclusion = useMutation(ADD_PROPOSAL_EXCLUSION)
const removeExclusion = useMutation(REMOVE_PROPOSAL_EXCLUSION)
const calculateMarkupCost = useMutation(MARKUP_COST_CALCULATE)
const calculateMarkupPercentage = useMutation(MARKUP_PERCENTAGE_CALCULATE)
const updateProposal = useMutation(UPDATE_PROPOSAL)
const updateCompanyDetails = useMutation(UPDATE_COMPANY_DETAILS)

const filterServiceTotals = (service) => {
  return proposalByIdQuery.result.value.proposal.serviceTotals.nodes.filter(
    (item) => item.serviceName == service
  )[0]
}

const filterSubcontractorTotals = (service) => {
  return proposalByIdQuery.result.value.proposal.subcontractors.nodes
    .filter((item) => item.proposalsServicesItems[0]?.proposalsService.service.name == service)
    .reduce((total, item) => total + item.cost, 0)
}

const loading = computed(() => {
  return (
    proposalByIdQuery.loading.value ||
    proposalInclusionData.loading.value ||
    companyDetails.loading.value
  )
})

const overheadTotals = computed(
  () =>
    displayTotal('equipmentTotal') +
    displayTotal('laborerTotal') +
    displayTotal('miscellaneousCostTotal') +
    displayTotal('stripingTotal')
)

const subcontractorTotal = computed(() =>
  proposalByIdQuery.result.value.proposal.subcontractors.nodes.reduce(
    (total, item) => total + item.cost,
    0
  )
)

const handleInclusion = (e, item) => {
  if (e.target.checked) {
    addInclusion.mutate({
      input: { input: { proposalId: route.params.id, inclusionId: item.id } },
    })
  } else {
    let inclusionItemId = finalData.value.inclusions.find((id) => id.inclusion.id == item.id).id
    removeInclusion.mutate({
      input: { id: inclusionItemId },
    })
  }
}

const handleExclusion = (e, item) => {
  if (e.target.checked) {
    addExclusion.mutate({
      input: { input: { proposalId: route.params.id, exclusionId: item.id } },
    })
  } else {
    let exclusionItemId = finalData.value.exclusions.find((id) => id.exclusion.id == item.id).id
    removeExclusion.mutate({
      input: { id: exclusionItemId },
    })
  }
}

const workTypeTotals = (name) => {
  return proposalByIdQuery.result.value.proposal.serviceTotals.nodes.reduce(
    (accumulator, object) => {
      if (name === 'equipmentTotal' && object['serviceName'] !== 'Grading') {
        return accumulator + object[name] + object['gradingEquipment']
      } else if (name === 'laborerTotal' && object['serviceName'] !== 'Grading') {
        return accumulator + object[name] + object['gradingLabor']
      } else return accumulator + object[name]
    },
    0
  )
}

const serviceTotals = computed(() => {
  let sum = 0
  proposalByIdQuery.result.value.proposal.serviceTotals.nodes.forEach((element) => {
    sum += Object.entries(element)
      .filter(
        ([key, value]) =>
          typeof value === 'number' &&
          !(
            element['serviceName'] === 'Grading' &&
            (key === 'gradingEquipment' || key === 'gradingLabor')
          )
      )
      .reduce((innerSum, [key, value]) => innerSum + value, 0)
  })
  return sum
})

const handleTotalMarkup = (serviceMarkupTotal) => {
  if (!serviceMarkupAmount.value[Object.keys(serviceMarkupTotal)[0]]) {
    serviceMarkupAmount.value = { ...serviceMarkupAmount.value, ...serviceMarkupTotal }
  } else if (
    serviceMarkupAmount.value[Object.keys(serviceMarkupTotal)[0]].markupAmount !==
    serviceMarkupTotal[Object.keys(serviceMarkupTotal)[0]].markupAmount
  ) {
    serviceMarkupAmount.value[Object.keys(serviceMarkupTotal)[0]] =
      serviceMarkupTotal[Object.keys(serviceMarkupTotal)[0]]
  }
}

const costPercentage = (cost) =>
  cost !== 0 ? ((cost / estimationTotal.value) * 100).toFixed(2) : 0

const stripingTotal = computed(
  () =>
    proposalByIdQuery.result.value.proposal.serviceTotals.nodes.filter(
      (element) => element.serviceName == 'Striping'
    )[0]?.constructionTotal
)

const estimationTotal = computed(
  () =>
    totalCalculate('markupAmount') +
    subcontractorTotal.value +
    serviceTotals.value +
    costPercentages.value.generalAdminCost.amount +
    costPercentages.value.additionalMarkupPercentage.amount -
    costPercentages.value.discountPercentage.amount
)

const handlePreivewClick = () => {
  proposalByIdQuery.refetch()
  router.push(`/estimations/${route.params.id}/preview`)
}

const displayTotal = (key) => {
  if (key == 'markupTotal' || key == 'profitTotal') {
    return totalCalculate('markupAmount')
  } else if (key == 'estimationTotal') {
    return estimationTotal.value
  } else if (
    key == 'additionalMarkupPercentage' ||
    key == 'discountPercentage' ||
    key == 'generalAdminCost'
  ) {
    return costPercentages.value[key].amount
  } else if (key == 'stripingTotal') {
    return stripingTotal.value
  } else if (key == 'subcontractorTotal') {
    return subcontractorTotal.value
  } else if (key == 'bondTotal') {
    return 0
  } else {
    return workTypeTotals(key)
  }
}

function totalCalculate(property) {
  let sum = 0
  for (let key in serviceMarkupAmount.value) {
    if (serviceMarkupAmount.value.hasOwnProperty(key)) {
      sum += serviceMarkupAmount.value[key][property]
    }
  }
  return sum
}

const uploadDocument = async () => {
  const file = fileInput.value.files[0]
  const formData = new FormData()
  formData.append('proposal[images]', file)
  try {
    await axios.post(
      `/proposals/${proposalByIdQuery.result.value.proposal.id}/attach_files`,
      formData,
      {
        headers: {
          'X-CSRF-Token': getCookie('csrf_token'),
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  } catch (error) {
    console.log(error)
  }
}

const handleMarkupChange = async (e, item) => {
  const payloadValue = e.target.value
  const cost = await calculateMarkupCost.mutate({
    input: {
      markupPercentage: parseFloat(e.target.value),
      subTotal: item.key == 'generalAdminCost' ? overheadTotals.value : serviceTotals.value,
    },
  })
  costPercentages.value[item.key].percentage = e.target.value
  costPercentages.value[item.key].amount = cost.data.markupCostCalculate.markupCost
  if (item.key == 'generalAdminCost') {
    await updateCompanyDetails.mutate({
      input: {
        input: {
          id: companyDetails.result.value.organization.id,
          generalAdminCost: parseFloat(payloadValue),
        },
      },
    })
  } else {
    await updateProposal.mutate({
      input: {
        input: {
          id: proposalByIdQuery.result.value.proposal.id,
          [item.key]: parseFloat(payloadValue),
        },
      },
    })
  }
}

const handleDiscountChange = async (e, item) => {
  const cost = await calculateMarkupPercentage.mutate({
    input: {
      markupCost: parseFloat(e.target.value),
      subTotal: serviceTotals.value,
    },
  })
  costPercentages.value[item.key].percentage = cost.data.markupPercentageCalculate.markupPercentage
  costPercentages.value[item.key].amount = e.target.value
  await updateProposal.mutate({
    input: {
      input: {
        id: proposalByIdQuery.result.value.proposal.id,
        [item.key]: parseFloat(cost.data.markupPercentageCalculate.markupPercentage),
      },
    },
  })
}

const handleNotesChange = async (e) => {
  await updateProposal.mutate({
    input: {
      input: {
        id: proposalByIdQuery.result.value.proposal.id,
        notes: e.target.value,
      },
    },
  })
}

const handleConditionsChange = async (e) => {
  await updateProposal.mutate({
    input: {
      input: {
        id: proposalByIdQuery.result.value.proposal.id,
        conditions: e.target.value,
      },
    },
  })
}

const handleDisplayDiscountChange = async (e) => {
  await updateProposal.mutate({
    input: {
      input: {
        id: proposalByIdQuery.result.value.proposal.id,
        showDiscount: e.target.checked,
      },
    },
  })
}

const handleDate = async () => {
  await updateProposal.mutate({
    input: {
      input: {
        id: proposalByIdQuery.result.value.proposal.id,
        goodThrough: new Date(selectedValidDate.value).toISOString(),
      },
    },
  })
}

const handleThemeColor = async () => {
  colorOptions.value.forEach((item) => {
    item.selected = false
  })
  await updateThemeColor(selectedThemeColor.value)
}

const handleColorSelection = async (selectedItem) => {
  selectedItem.selected = true
  colorOptions.value.forEach((item) => {
    if (item !== selectedItem) {
      item.selected = false
    }
  })
  await updateThemeColor(selectedItem.color)
}

const updateThemeColor = async (color) => {
  await updateProposal.mutate({
    input: {
      input: {
        id: proposalByIdQuery.result.value.proposal.id,
        pdfThemeColor: color,
      },
    },
  })
}
</script>

<style scoped>
.color-circle {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
</style>
