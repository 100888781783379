<template>
  <input
    type="checkbox"
    :checked="modelValue"
    v-bind="{
      ...$attrs,
      onChange: ($event) => {
        $emit('update:modelValue', $event.target.checked)
      },
    }"
    class="form-checkbox rounded h-5 w-5 text-primary-600"
  />
  <label class="px-2 text-sm font-medium text-gray-700" v-if="label">{{ label }}</label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
