<template>
  <div id="app">
    <div class="my-6">
      <h1 class="my-3 text-xl font-semibold text-gray-900">{{ title }}</h1>
      <BaseCheckbox @click="handleCheckbox" label="Add section in Proposal" v-model="checked" />
    </div>
    <vue-editor @text-change="handleTextChange" v-model="content"></vue-editor>
  </div>
</template>

<script setup>
import { VueEditor } from 'vue3-editor'
import { ref } from 'vue'
import BaseCheckbox from '@/components/layout/BaseCheckbox.vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  showSection: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['updateShowSection', 'updateText'])

const content = ref(props.text)
const checked = ref(props.showSection)

const handleTextChange = () => {
  emit('updateText', content.value)
}

const handleCheckbox = () => {
  emit('updateShowSection', !checked.value)
}
</script>
