<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
  </div>
  <div v-else>
    <div>
      <HaulAndDumpTabTable
        title="Haul & Dump Calculator"
        :columns="gradingDemoTab.haulDumpCalculator.columns"
        :rowFields="haulAndDumpDataQuery.result.value.haulAndDump.nodes"
        :rowsData="tablesData.haulAndDumpData.data"
        :showSave="tablesData.haulAndDumpData.savePayload.length !== 0"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        name="haulAndDumpData"
      />
    </div>
    <div>
      <TabTable
        title="Grading Demo Equipment"
        :columns="gradingDemoTab.gradingDemoEquipment.columns"
        :rowFields="fieldDataQuery.result.value.gradingEquipment.nodes"
        :rowsData="tablesData.equipmentData.data"
        :showSave="tablesData.equipmentData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="equipmentData"
      />
    </div>
    <div>
      <TabTable
        title="Grading Demo Labor"
        :columns="tabColumns(proposalByIdQuery.result.value.proposal.publicWorks).labor.columns"
        :rowFields="fieldDataQuery.result.value.gradingLaborers.nodes"
        :rowsData="tablesData.laborerData.data"
        :showSave="tablesData.laborerData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="laborerData"
      />
    </div>
    <div>
      <TabTable
        title="Grading Demo Miscellaneous Cost"
        :columns="gradingDemoTab.gradingDemoMiscellaneousCost.columns"
        :rowFields="fieldDataQuery.result.value.gradingMiscellaneousCost.nodes"
        :rowsData="tablesData.miscellaneousCostData.data"
        :showSave="tablesData.miscellaneousCostData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        name="miscellaneousCostData"
      />
    </div>
    <DeleteRowModal
      :showModal="deleteModal.open"
      :title="deleteModal.title"
      @close-modal="deleteModal.open = false"
      @delete-row="handleRemoveRow"
    />
    <Toast v-if="toast.showToast" :title="toast.title" />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'

import { gradingDemoTab, tabColumns } from '@/keys/constants'
import TabTable from '@/components/layout/TabTable.vue'
import HaulAndDumpTabTable from '@/components/layout/HaulAndDumpTabTable.vue'
import Toast from '@/components/layout/Toast.vue'
import Loader from '@/components/layout/Loader.vue'
import DeleteRowModal from '@/components/modals/DeleteRowModal.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'
import { useDeleteTableData } from '../../composables/useDeleteTableData'
import { useEditTableData } from '../../composables/useEditTableData'
import { useUpdateRowData } from '../../composables/useUpdateRowData'
import { useAddRowData } from '../../composables/useAddRowData'
import { filterServiceItems } from '../../utils/utility_methods'

import GET_GRADING_FIELD_DATA from '../../graphql/queries/getGradingDemoFieldData.gql'
import GET_HAUL_AND_DUMP_FIELD_DATA from '../../graphql/queries/getHaulAndDumpFieldData.gql'
import GET_SERVICE_ID from '../../graphql/queries/getServiceId.gql'
import GET_PROPOSAL_BY_ID from '../../graphql/queries/getProposalById.gql'

const tablesData = ref({
  laborerData: { data: [], savePayload: [] },
  equipmentData: { data: [], savePayload: [] },
  miscellaneousCostData: { data: [], savePayload: [] },
  haulAndDumpData: { data: [], savePayload: [] },
})
const deleteModal = ref({ open: false, title: '' })
const deleteRowData = ref({ name: null, data: null, ids: [] })
const gradingServiceId = ref(null)
const route = useRoute()
const calculationsLoading = ref(false)
const toast = ref({ showToast: false, title: '' })
const fieldDataQuery = useQuery(GET_GRADING_FIELD_DATA)
const haulAndDumpDataQuery = useQuery(GET_HAUL_AND_DUMP_FIELD_DATA)
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const { result } = useQuery(GET_SERVICE_ID, {
  id: route.params.id,
})

const loading = computed(() => {
  return (
    fieldDataQuery.loading.value ||
    haulAndDumpDataQuery.loading.value ||
    proposalByIdQuery.loading.value
  )
})

onMounted(() => {
  proposalByIdQuery.refetch()
})

watch(result, (value) => {
  gradingServiceId.value = value.proposalsService.nodes.find(
    (item) => item.service.name == 'Grading'
  ).id
})

watch([gradingServiceId, proposalByIdQuery.result, haulAndDumpDataQuery.result], () => {
  if (gradingServiceId.value && proposalByIdQuery.result.value) {
    tablesData.value.laborerData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.laborers.nodes,
      gradingServiceId.value
    )
    tablesData.value.equipmentData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.equipments.nodes,
      gradingServiceId.value
    )
    tablesData.value.miscellaneousCostData.data =
      proposalByIdQuery.result.value.proposal.miscellaneousCosts.nodes.filter(
        (item) => item.proposalsServicesItems[0].proposalsService.id == gradingServiceId.value
      )
    tablesData.value.haulAndDumpData.data = proposalByIdQuery.result.value.proposal.lchWorks.nodes
  }
})

const handleAddRow = (name, rows) => {
  tablesData.value[name].data = [...tablesData.value[name].data, ...rows]
}

const handleCancelAddRow = (name, rowId, rows) => {
  tablesData.value[name].data = rows
  tablesData.value[name].savePayload = tablesData.value[name].savePayload.filter(
    (item) => item.id !== rowId
  )
}

const handleEditRow = async (name, payload, row) => {
  let { responseMessage } = await useEditTableData(name, payload, row)
  proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleConfirmRemoveRow = (name, title, rows) => {
  deleteModal.value.open = true
  deleteRowData.value = {
    name: name,
    data: rows,
    ids: name !== 'haulAndDumpData' ? rows.map((item) => item.id ?? item) : [],
  }
  deleteModal.value.title = title
}

const handleRemoveRow = async () => {
  let { responseMessage } = await useDeleteTableData(
    deleteRowData.value.name,
    deleteRowData.value.name !== 'haulAndDumpData'
      ? deleteRowData.value.ids
      : deleteRowData.value.data.id,
    deleteRowData.value.data.proposalsWorkProperties
  )
  proposalByIdQuery.refetch()
  deleteModal.value = { open: false, title: '' }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleSubmit = async (name, newRow) => {
  let { responseMessage } = await useAddRowData(
    name,
    newRow,
    gradingServiceId.value,
    tablesData,
    null,
    null,
    result.value.proposalsService.nodes
  )
  tablesData.value[name] = { data: [], savePayload: [] }
  proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const updateData = async (name, row, isEditing, rowData) => {
  calculationsLoading.value = true
  await useUpdateRowData(
    name,
    row,
    isEditing,
    tablesData,
    proposalByIdQuery.result.value.proposal.publicWorks,
    rowData
  )
  calculationsLoading.value = false
}
</script>
