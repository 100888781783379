<template>
  <div class="absolute bottom-0 w-full">
    <p class="flex items-center justify-center py-1">
      Proposal Number : <span class="font-bold text-black pl-1"> {{ number }} </span>
    </p>
    <div
      v-if="ownerData"
      :style="{ backgroundColor: props.themeColor }"
      class="footer-container flex items-center justify-center py-4 px-14"
    >
      <div class="min-w-0 flex-1">
        <p class="text-sm text-white text-center">
          {{ ownerData.name }} {{ ownerData.address.address1 }} {{ ownerData.address.city }},
          {{ ownerData.address.state.country.abbreviation }} {{ ownerData.address.zipcode }}
        </p>
        <p class="text-sm text-white text-center">
          Phone: {{ ownerData.phone }} {{ ownerData.website }} Email: {{ ownerData.email }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  ownerData: {
    type: Object,
  },
  number: {
    type: String,
  },
  themeColor: {
    type: String,
    default: '#07275c',
  },
})
</script>
