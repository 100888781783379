import { useMutation } from '@vue/apollo-composable'
import { provideApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/helpers/apolloClient.js'

import CALCULATE_EQUIPMENT_COST from '../graphql/mutations/calculations/equipmentCostCalculate.gql'
import CALCULATE_TOTAL_EQUIPMENT_COST from '../graphql/mutations/calculations/equipmentTotalCostCalculate.gql'
import CALCULATE_TOTAL_MISC_COST from '../graphql/mutations/calculations/miscTotalCostCalculate.gql'
import CALCULATE_TOTAL_LABORER_COST from '../graphql/mutations/calculations/laborerTotalCostCalculate.gql'
import CALCULATE_TOTAL_LABORER_HOURS from '../graphql/mutations/calculations/laborerTotalHoursCalculate.gql'
import CALCULATE_TOTAL_LABORER_OVERTIME_HOURS from '../graphql/mutations/calculations/laborerTotalOvertimePaidHoursCalculate.gql'
import CALCULATE_TOTAL_LABORER_OVERTIME_PAY from '../graphql/mutations/calculations/laborerTotalOvertimePayCalculate.gql'
import CALCULATE_TOTAL_LABORER_TOTAL_PAID_HOURS from '../graphql/mutations/calculations/laborerTotalRegularPaidHoursCalculate.gql'
import CALCULATE_TOTAL_LABORER_TOTAL_REGULAR_PAY from '../graphql/mutations/calculations/laborerTotalRegularPayCalculate.gql'
import CALCULATE_STRIPING_COST from '../graphql/mutations/calculations/calculateStripingTotals.gql'

provideApolloClient(apolloClient)

const equipmentCostCreate = useMutation(CALCULATE_EQUIPMENT_COST)
const equipmentTotalCostCreate = useMutation(CALCULATE_TOTAL_EQUIPMENT_COST)
const laborerTotalCostCreate = useMutation(CALCULATE_TOTAL_LABORER_COST)
const laborerTotalHoursCreate = useMutation(CALCULATE_TOTAL_LABORER_HOURS)
const laborerTotalOvertimeHoursCreate = useMutation(CALCULATE_TOTAL_LABORER_OVERTIME_HOURS)
const laborerTotalOvertimePayCreate = useMutation(CALCULATE_TOTAL_LABORER_OVERTIME_PAY)
const laborerTotalRegularHoursCreate = useMutation(CALCULATE_TOTAL_LABORER_TOTAL_PAID_HOURS)
const laborerTotalRegularPayCreate = useMutation(CALCULATE_TOTAL_LABORER_TOTAL_REGULAR_PAY)
const miscTotalCostCreate = useMutation(CALCULATE_TOTAL_MISC_COST)
const stripingTotalCostCreate = useMutation(CALCULATE_STRIPING_COST)

export async function useRowCalculations(name, row, publicWorks) {
  if (name == 'equipmentData' || name == 'gradingEquipmentData') {
    await equipmentCalculations(row)
  } else if (name == 'laborerData' || name == 'gradingLaborerData') {
    await laborerCalculations(row, publicWorks)
  } else if (name == 'miscellaneousCostData') {
    await miscellaneousCostCalculations(row)
  } else if (name !== 'subcontractorsData') {
    await stripingCostCalculations(row)
  }
}

const equipmentCalculations = async (row) => {
  let equipmentCostResponse = await equipmentCostCreate.mutate({
    input: {
      hoursPerDay: parseInt(row.value.hoursPerDay),
      ratePerHour: row.value.costPerUnit,
      quantity: parseInt(row.value.quantity),
      totalDaysOnJob: parseInt(row.value.totalDaysOnJob),
    },
  })

  let equipmentTotalCostResponse = await equipmentTotalCostCreate.mutate({
    input: {
      fuelChargePercentage: row.value.fuelChargePercentage,
      cost: equipmentCostResponse.data.equipmentCostCalculate.cost,
    },
  })
  row.value.equipmentCost = equipmentCostResponse.data.equipmentCostCalculate.cost
  row.value.totalCost = equipmentTotalCostResponse.data.equipmentTotalCostCalculate.totalCost
}

const miscellaneousCostCalculations = async (row) => {
  if (parseFloat(row.value.costPerUnit) !== 0) {
    let costResponse = await miscTotalCostCreate.mutate({
      input: {
        quantity: parseFloat(row.value.quantity),
        costPerUnit: parseFloat(row.value.costPerUnit),
      },
    })
    row.value.totalCost = costResponse.data.miscTotalCostCalculate.totalCost
  } else {
    row.value.totalCost = 0
  }
}

const stripingCostCalculations = async (row) => {
  let costResponse = await stripingTotalCostCreate.mutate({
    input: {
      input: {
        id: row.value.stripingWork ? row.value.stripingWork.id : row.value.workType.stripingWork.id,
        doubleCoatSelected: row.value.doubleCoatSelected,
        newLayoutSelected: row.value.newLayoutSelected,
        quantity: parseFloat(row.value.quantity),
      },
    },
  })
  row.value.totalCost = costResponse.data.calculateStripingTotals.total
}

const laborerCalculations = async (row, publicWorks) => {
  let laborerTotalHoursResponse = await laborerTotalHoursCreate.mutate({
    input: {
      quantity: parseInt(row.value.quantity),
      daysOnSite: parseInt(row.value.daysOnSite),
      hoursOnSite: parseInt(row.value.hoursOnSite),
    },
  })
  let laborerTotalRegularHoursResponse = await laborerTotalRegularHoursCreate.mutate({
    input: {
      quantity: parseInt(row.value.quantity),
      daysOnSite: parseInt(row.value.daysOnSite),
      hoursOnSite: parseInt(row.value.hoursOnSite),
    },
  })
  let laborerTotalRegularPayResponse = await laborerTotalRegularPayCreate.mutate({
    input: {
      costPerUnit: publicWorks ? row.value.publicWorksCostPerUnit : row.value.costPerUnit,
      totalRegularHours:
        laborerTotalRegularHoursResponse.data.laborerTotalRegularPaidHoursCalculate.totalHours,
    },
  })
  let laborerTotalOvertimeHoursResponse = await laborerTotalOvertimeHoursCreate.mutate({
    input: {
      quantity: parseInt(row.value.quantity),
      daysOnSite: parseInt(row.value.daysOnSite),
      hoursOnSite: parseInt(row.value.hoursOnSite),
    },
  })
  let laborerTotalOvertimePayResponse = await laborerTotalOvertimePayCreate.mutate({
    input: {
      overtimeRate: row.value.laborer
        ? row.value.laborer.overtimeCostPerUnit
        : row.value.overtimeCostPerUnit,
      totalOvertimeHours: Math.max(
        0,
        laborerTotalOvertimeHoursResponse.data.laborerTotalOvertimePaidHoursCalculate.totalHours
      ),
    },
  })
  let laborerTotalCostResponse = await laborerTotalCostCreate.mutate({
    input: {
      totalRegularPay: laborerTotalRegularPayResponse.data.laborerTotalRegularPayCalculate.pay,
      totalOvertimePay: laborerTotalOvertimePayResponse.data.laborerTotalOvertimePayCalculate.pay,
    },
  })
  row.value.totalHours = laborerTotalHoursResponse.data.laborerTotalHoursCalculate.totalHours
  row.value.regularHours =
    laborerTotalRegularHoursResponse.data.laborerTotalRegularPaidHoursCalculate.totalHours
  row.value.totalRegularPay =
    laborerTotalRegularPayResponse.data.laborerTotalRegularPayCalculate.pay
  row.value.overtimeHours = Math.max(
    0,
    laborerTotalOvertimeHoursResponse.data.laborerTotalOvertimePaidHoursCalculate.totalHours
  )
  row.value.totalOvertimePay =
    laborerTotalOvertimePayResponse.data.laborerTotalOvertimePayCalculate.pay
  row.value.totalCost = laborerTotalCostResponse.data.laborerTotalCostCalculate.total
}
