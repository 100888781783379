<template>
  <input
    v-model="model"
    class="mx-2 py-0 w-20 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
    @input="$emit('onInput', $event)"
    @change="$emit('onChange', $event)"
    :class="{ 'border-gray-400': !error, 'border-red-400 ': error }"
    :placeholder="placeholder"
    :type="type"
  />
  <p v-if="error" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
    {{ error }}
  </p>
</template>

<script setup>
const model = defineModel({ required: true })
const emit = defineEmits(['onChange', 'onInput'])

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  placeholder: [String, null],
  label: {
    type: String,
    default: '',
  },
  error: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
})
</script>
