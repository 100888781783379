<template>
  <Loader v-if="loading || proposalByIdQuery.loading.value" />
  <div v-else>
    <Toast v-if="toast.showToast" :title="toast.title" />
    <Modal :open="showModal" title="Update Area">
      <p class="col-span-2 text-lg my-6">Select the type of polygon needed</p>
      <div class="flex justify-between pt-4 pb-2">
        <div>
          <button
            type="button"
            class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-success-500 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
            @click="addOverlappingPolygon"
          >
            Adding SF
          </button>
          <button
            type="submit"
            class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-primary-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-primary-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
            @click="removeOverlappingArea"
          >
            Removing SF
          </button>
        </div>
        <div>
          <button
            type="button"
            class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
            @click="cancelOverlapping"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
    <div class="relative">
      <div id="map" class="map">
        <div
          class="z-10 flex items-center absolute top-4 left-4 bg-black bg-opacity-75 rounded-md p-4"
        >
          <div id="geocoder" class="geocoder mx-2"></div>
          <CameraIcon @click="exportMap" class="h-8 w-8 text-gray-300 cursor-pointer mx-1" />
          <div class="relative" ref="unitsDropdownContainer">
            <AdjustmentsHorizontalIcon
              class="h-6 w-6 text-gray-300 cursor-pointer mx-1"
              @click="toggleUnitsDropdown"
            />
            <div
              v-if="showUnitsDropdown"
              class="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              @click.away="showUnitsDropdown = false"
            >
              <p
                v-for="unit in units"
                :key="unit.name"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:cursor-pointer"
                :class="unit.name === selectedUnit.name ? 'bg-primary-100' : 'bg-white'"
                @click="updateUnit(unit)"
              >
                {{ unit.name }}
              </p>
            </div>
          </div>
          <div class="relative" ref="modesDropdownContainer">
            <PencilSquareIcon
              class="h-6 w-6 text-gray-300 cursor-pointer mx-1"
              @click="toggleModesDropdown"
            />
            <div
              v-if="showModesDropdown"
              class="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              @click.away="showModesDropdown = false"
            >
              <p
                v-for="mode in drawModes"
                :key="mode.key"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:cursor-pointer"
                @click="updateMode(mode)"
              >
                {{ mode.name }}
              </p>
            </div>
          </div>
          <div ref="fillColorPickerContainer">
            <button
              @click="showFillColorPicker = !showFillColorPicker"
              class="rounded mx-2 outline-none w-6 h-6"
              :style="drawFillColor ? `background-color: ${drawFillColor}` : ' #78fd94'"
            ></button>
            <Sketch
              class="!absolute"
              v-if="showFillColorPicker"
              v-model="drawFillColor"
              @update:modelValue="updateFillColor"
            />
          </div>
          <div ref="lineColorPickerContainer">
            <button
              @click="showLineColorPicker = !showLineColorPicker"
              class="rounded-md bg-transparent border-4 mx-2 outline-none w-7 h-7"
              :style="drawLineColor ? `border-color: ${drawLineColor}` : ' #78fd94'"
            ></button>
            <Sketch
              class="!absolute"
              v-if="showLineColorPicker"
              v-model="drawLineColor"
              @update:modelValue="updateLineColor"
            />
          </div>
          <div>
            <ArrowsPointingOutIcon
              class="h-6 w-6 text-gray-300 cursor-pointer mx-1"
              @click="handleFullscreen"
            />
          </div>
          <div class="px-3 flex">
            <ArrowUturnLeftIcon class="h-5 w-5 text-gray-300 cursor-pointer mx-1" @click="undo" />
            <ArrowUturnRightIcon class="h-5 w-5 text-gray-300 cursor-pointer mx-1" @click="redo" />
          </div>
          <button
            type="button"
            class="inline-flex items-center rounded-md border border-transparent ml-6 bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
            @click="saveMap"
          >
            Save
          </button>
        </div>
        <div id="left" class="sidebar flex-center left collapsed">
          <div class="sidebar-content rounded-lg bg-white opacity-95 shadow-lg shadow-black">
            <div class="flex justify-end">
              <XMarkIcon
                @click="handleCloseSidebar"
                class="h-6 w-6 text-gray-600 cursor-pointer mx-1"
              />
            </div>

            <div class="w-full p-4">
              <div class="my-3">
                <p class="block text-xl text-center font-medium leading-6 text-gray-900">
                  {{ selectedFeatureLineString ? 'LineString' : 'Polygon' }}
                </p>
              </div>
              <div v-show="!selectedFeatureLineString" class="my-3">
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900"
                  >Label</label
                >
                <div class="relative mt-2">
                  <input
                    value=""
                    v-model="selectedFeature.label"
                    type="text"
                    name="label"
                    class="peer block w-full border-0 bg-gray-100 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="label"
                  />
                </div>
              </div>
              <div v-if="selectedFeature.feature?.properties.isCircle" class="my-3">
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900"
                  >Input square footage
                </label>
                <div class="relative mt-2">
                  <input
                    value=""
                    v-model.number="selectedFeature.customArea"
                    type="text"
                    name="label"
                    class="peer block w-full border-0 bg-gray-100 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Area"
                  />
                </div>
              </div>
              <div>
                <div
                  v-show="!selectedFeatureLineString"
                  ref="sidebarFillColorPickerContainer"
                  class="relative flex items-center p-2 z-20"
                >
                  <label for="label" class="block text-sm font-medium leading-6 text-gray-900"
                    >Fill color</label
                  >
                  <button
                    @click="
                      selectedFeature.showFillColorPicker = !selectedFeature.showFillColorPicker
                    "
                    class="rounded mx-2 outline-none w-6 h-6"
                    :style="`background-color: ${selectedFeature.fillColor}`"
                  ></button>
                  <Sketch
                    class="!absolute top-10"
                    v-if="selectedFeature.showFillColorPicker"
                    v-model="selectedFeature.fillColor"
                    @update:modelValue="updateFillColor"
                  />
                </div>
                <div
                  ref="sidebarLineColorPickerContainer"
                  class="relative flex items-center p-2 z-10"
                >
                  <label for="label" class="block text-sm font-medium leading-6 text-gray-900"
                    >Line color</label
                  >
                  <button
                    @click="
                      selectedFeature.showLineColorPicker = !selectedFeature.showLineColorPicker
                    "
                    class="rounded-md bg-transparent border-4 mx-2 outline-none w-7 h-7"
                    :style="`border-color: ${selectedFeature.lineColor}`"
                  ></button>
                  <Sketch
                    class="!absolute top-10"
                    v-if="selectedFeature.showLineColorPicker"
                    v-model="selectedFeature.lineColor"
                    @update:modelValue="updateLineColor"
                  />
                </div>

                <div class="my-3">
                  <label for="name" class="block text-sm font-medium leading-6 text-gray-900"
                    >Line Style</label
                  >
                  <select
                    v-model="selectedFeature.lineStyle"
                    class="w-24 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                  >
                    <option value="solid">Solid</option>
                    <option value="dotted">Dotted</option>
                    <option value="dashed">Dashed</option>
                  </select>
                </div>
              </div>
              <div class="py-2">
                <label class="text-sm text-gray-700" for="duration">Line Width</label>
                <input
                  class="w-4/5"
                  type="range"
                  id="duration"
                  name="duration"
                  v-model.number="selectedFeature.lineWidth"
                  min="0"
                  max="10"
                  step="1"
                />
              </div>
              <div v-show="!selectedFeatureLineString" class="py-2">
                <label class="text-sm text-gray-700" for="duration">Polygon transparency</label>
                <input
                  class="w-4/5"
                  type="range"
                  id="duration"
                  v-model.number="selectedFeature.opacity"
                  name="duration"
                  min="0"
                  max="1"
                  step="0.10"
                />
              </div>
              <div v-show="!selectedFeatureLineString && !selectedFeatureCircle" class="py-2">
                <label class="text-sm text-gray-700 pr-4">Show line labels</label>
                <ToggleButton v-model="selectedFeature.showLineLabels" />
              </div>
              <div v-show="!selectedFeatureLineString && !selectedFeatureCircle" class="py-2">
                <label class="text-sm text-gray-700 pr-4">Show square footage</label>
                <ToggleButton v-model="selectedFeature.showAreaLabel" />
              </div>

              <div class="flex mt-10">
                <button
                  type="button"
                  class="inline-flex items-center rounded-md border border-transparent ml-6 bg-primary-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                  @click="updateFeature"
                >
                  Update
                </button>
                <button
                  type="button"
                  class="inline-flex items-center rounded-md border border-transparent ml-6 bg-failure-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                  @click="deletePolygonAndLayers"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calculation-box">
        <p>Total Area :</p>
        <div id="calculated-area"></div>
      </div>
      <div ref="distanceDisplay" class="distance-display"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted, watch } from 'vue'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import CircleMode from '../map/circle'
import CustomCircleControl from '../map/customcontrol'
import WhiteRoundedBG from '../../assets/rounded-white.png'
import BlackRoundedBG from '../../assets/rounded-black.png'
import RedRoundedBG from '../../assets/rounded-red.png'

import * as turf from '@turf/turf'
import axios from 'axios'
import Loader from '@/components/layout/Loader.vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { useRoute } from 'vue-router'
import {
  CameraIcon,
  AdjustmentsHorizontalIcon,
  XMarkIcon,
  ArrowsPointingOutIcon,
  PencilSquareIcon,
  ArrowUturnLeftIcon,
  ArrowDownRightIcon,
  ArrowUturnRightIcon,
} from '@heroicons/vue/20/solid'

import CREATE_GEOMETRY from '../../graphql/mutations/createMapGeometry.gql'
import UPDATE_GEOMETRY from '../../graphql/mutations/updateMapGeometry.gql'
import DELETE_GEOMETRY from '../../graphql/mutations/deleteMapGeometry.gql'
import GET_PROPOSAL_BY_ID from '../../graphql/queries/getProposalById.gql'
import GET_COMPANY_DATA from '../../graphql/queries/getCompanyData.gql'

import Toast from '@/components/layout/Toast.vue'
import Modal from '@/components/layout/Modal.vue'
import ToggleButton from '@/components/layout/ToggleButton.vue'
import { Sketch } from '@ckpack/vue-color'

const showFillColorPicker = ref(false)
const showLineColorPicker = ref(false)
const distanceDisplay = ref(null)

const props = defineProps({
  proposalData: {
    type: Object,
  },
})

const route = useRoute()
const createGeometry = useMutation(CREATE_GEOMETRY)
const updateGeometry = useMutation(UPDATE_GEOMETRY)
const deleteGeometry = useMutation(DELETE_GEOMETRY)
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const companyDataQuery = useQuery(GET_COMPANY_DATA)

const showSidebar = ref(false)
const map = ref(null)
const loading = ref(false)
const selectedUnit = ref({
  name: 'square feet',
  value: 'square_feet',
  unit: 'ft',
  sideUnit: 'feet',
  thresholdLength: 100,
})
const selectedMode = ref(null)
const Features = ref({
  created: [],
  updated: [],
  deleted: [],
})
const undoStack = ref([])
const redoStack = ref([])
const mapInitialState = ref(null)
const coordinates = ref([-77.05018, 38.88926])
const units = ref([
  {
    name: 'square meters',
    value: 'square_meter',
    unit: 'm',
    sideUnit: 'meters',
    thresholdLength: 30.48,
  },
  {
    name: 'square kilometres',
    value: 'square_kilometre',
    unit: 'km',
    sideUnit: 'kilometers',
    thresholdLength: 0.03048,
  },
  { name: 'square feet', value: 'square_feet', unit: 'ft', sideUnit: 'feet', thresholdLength: 100 },
  {
    name: 'square yards',
    value: 'square_yard',
    unit: 'yd',
    sideUnit: 'yards',
    thresholdLength: 33.333,
  },
  {
    name: 'miles',
    value: 'square_mile',
    unit: 'mile',
    sideUnit: 'miles',
    thresholdLength: 0.0189,
  },
])
const lineStyleMap = {
  solid: [],
  dotted: [0.2, 2],
  dashed: [2, 2],
}
const drawModes = [
  { name: 'Draw Polygon', key: 'draw_polygon' },
  { name: 'Draw Circle', key: 'draw_circle' },
  { name: 'Add point Marker', key: 'draw_point' },
  { name: 'Measure Length', key: 'draw_line_string' },
]

const showUnitsDropdown = ref(false)
const showModesDropdown = ref(false)
const unitsDropdownContainer = ref(null)
const modesDropdownContainer = ref(null)
const fillColorPickerContainer = ref(null)
const lineColorPickerContainer = ref(null)
const sidebarFillColorPickerContainer = ref(null)
const sidebarLineColorPickerContainer = ref(null)

const toast = ref({ showToast: false, title: '' })
const drawLineColor = ref('#78fd94')
const drawFillColor = ref('#78fd94')
const showModal = ref(false)
const overlappingPolygon = ref({})

const selectedFeature = ref({
  showLineColorPicker: false,
  showFillColorPicker: false,
  feature: null,
  label: null,
  fillColor: drawFillColor.value,
  lineColor: drawLineColor.value,
  lineWidth: 3,
  opacity: 0.5,
  lineStyle: 'solid',
  showLineLabels: true,
  showAreaLabel: true,
  area: '0',
})

const selectedFeatureCircle = computed(() => {
  return selectedFeature.value.feature?.properties?.isCircle || false
})

const selectedFeatureLineString = computed(() => {
  return selectedFeature.value.feature?.geometry?.type === 'LineString' || false
})

const toggleSidebar = (id) => {
  showSidebar.value = !showSidebar.value
  const elem = document.getElementById(id)
  const collapsed = elem.classList.toggle('collapsed')
  const padding = {}
  padding[id] = collapsed ? 0 : 300
  map.value.easeTo({
    padding: padding,
    duration: 1000,
  })
}

const toggleUnitsDropdown = () => {
  showUnitsDropdown.value = !showUnitsDropdown.value
}

const toggleModesDropdown = () => {
  showModesDropdown.value = !showModesDropdown.value
}

const accessToken = import.meta.env.VITE_BASE_MAPBOX_TOKEN

let draw = null
const address = computed(() => {
  return props.proposalData.proposal.supervisor.nodes[0].address
})
const addressString = computed(() => {
  return encodeURIComponent(
    `${address.value.address1}, ${address.value.city}, ${address.value.state.name} ${address.value.zipcode}, ${address.value.state.country.name}`
  )
})

const handleClickOutside = (event) => {
  if (fillColorPickerContainer.value && !fillColorPickerContainer.value.contains(event.target)) {
    showFillColorPicker.value = false
  }
  if (lineColorPickerContainer.value && !lineColorPickerContainer.value.contains(event.target)) {
    showLineColorPicker.value = false
  }
  if (unitsDropdownContainer.value && !unitsDropdownContainer.value.contains(event.target)) {
    showUnitsDropdown.value = false
  }
  if (modesDropdownContainer.value && !modesDropdownContainer.value.contains(event.target)) {
    showModesDropdown.value = false
  }
  if (selectedFeature.value.feature) {
    if (
      sidebarFillColorPickerContainer.value &&
      !sidebarFillColorPickerContainer.value.contains(event.target)
    ) {
      selectedFeature.value.showFillColorPicker = false
    }
    if (
      sidebarLineColorPickerContainer.value &&
      !sidebarLineColorPickerContainer.value.contains(event.target)
    ) {
      selectedFeature.value.showLineColorPicker = false
    }
  }
}

onUnmounted(() => {
  window.removeEventListener('click', handleClickOutside)
})

onMounted(async () => {
  mapboxgl.accessToken = accessToken
  await geocodeAddress()
  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl,
  })

  map.value = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/mapbox/satellite-v9',
    center: coordinates.value,
    zoom: 16.5,
    preserveDrawingBuffer: true,
  })
  draw = new MapboxDraw({
    displayControlsDefault: false,
    modes: {
      ...MapboxDraw.modes,
      draw_circle: CircleMode,
      simple_select: { ...MapboxDraw.modes.simple_select, dragMove() {} },
      direct_select: { ...MapboxDraw.modes.direct_select, dragFeature() {} },
    },
  })

  new CustomCircleControl(draw)
  map.value.addControl(draw)

  map.value.on('load', async () => {
    window.addEventListener('click', handleClickOutside)

    try {
      const whiteBackground = await loadImageWithPromise(WhiteRoundedBG)
      const blackBackground = await loadImageWithPromise(BlackRoundedBG)
      const redBackground = await loadImageWithPromise(RedRoundedBG)

      if (!map.value.hasImage('white-rounded')) {
        map.value.addImage('white-rounded', whiteBackground, {
          content: [3, 3, 13, 13],
          stretchX: [[7, 9]],
          stretchY: [[7, 9]],
        })
      }
      if (!map.value.hasImage('black-rounded')) {
        map.value.addImage('black-rounded', blackBackground, {
          content: [3, 3, 13, 13],
          stretchX: [[7, 9]],
          stretchY: [[7, 9]],
        })
      }
      if (!map.value.hasImage('red-rounded')) {
        map.value.addImage('red-rounded', redBackground, {
          content: [3, 3, 13, 13],
          stretchX: [[7, 9]],
          stretchY: [[7, 9]],
        })
      }
    } catch (error) {
      console.error('Failed to load image:', error)
    }

    document.addEventListener('fullscreenchange', (event) => {
      map.value.resize()
    })

    draw.deleteAll()
    proposalByIdQuery.result.value.proposal.mapGeometries.nodes.forEach((geometry) => {
      let featureProperties = JSON.parse(geometry.properties)
      featureProperties.properties.dbId = geometry.id
      if (!featureProperties.properties.id) {
        featureProperties.properties.id = geometry.id
      }
      featureProperties.properties.updatePolygon = false
      featureProperties.properties.createPolygon = false
      featureProperties.properties.updated = false
      draw.add(featureProperties)

      var allFeatures = draw.getAll()

      // The newly added polygon should be the last feature in the array
      var lastFeature = allFeatures.features[allFeatures.features.length - 1]

      // Get the ID of the last feature (your polygon)
      var polygonId = lastFeature.id
      let featureSource = `polygon-${polygonId}`

      let polygonFeature = {
        type: 'Feature',
        properties: featureProperties.properties,
        geometry: featureProperties.geometry,
      }
      const centroidFeature = getCentroid(polygonFeature)

      var featureCollection = {
        type: 'FeatureCollection',
        features:
          polygonFeature.geometry.type == 'LineString'
            ? [polygonFeature]
            : [polygonFeature, centroidFeature],
      }

      if (!map.value.getSource(featureSource)) {
        map.value.addSource(featureSource, {
          type: 'geojson',
          data: featureCollection,
        })
      } else {
        map.value.getSource(featureSource).setData(featureCollection)
      }

      // Add a new layer to visualize the polygon.
      if (
        lastFeature.geometry.type !== 'LineString' &&
        !map.value.getLayer(`polygon-color-${polygonId}`)
      ) {
        map.value.addLayer({
          id: `polygon-color-${polygonId}`,
          type: 'fill',
          source: featureSource,
          layout: {},
          paint: {
            'fill-color': featureProperties.properties.fillColor ?? selectedFeature.value.fillColor,
            'fill-opacity': featureProperties.properties.opacity ?? selectedFeature.value.opacity,
          },
        })
      }

      if (
        !lastFeature.properties.isCircle &&
        lastFeature.geometry.type !== 'LineString' &&
        !map.value.getLayer(`polygon-area-${polygonId}`) &&
        lastFeature.properties.showAreaLabel
      ) {
        map.value.addLayer({
          id: `polygon-area-${polygonId}`,
          type: 'symbol',
          source: featureSource,
          filter: ['==', '$type', 'Point'], // Only apply to points, which are centroids
          layout: {
            'icon-image': 'white-rounded',
            'icon-text-fit': 'both',
            'icon-text-fit-padding': [1, 2, 1, 2], // Optional padding around the text within the icon
            'text-field': '{label}',
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 10,
            'text-offset': [0, 0],
            'text-allow-overlap': false, // Allows text to overlap with other map features
            'icon-allow-overlap': false,
          },
          paint: {
            'text-color': 'black',
          },
        })
      }

      if (!map.value.getLayer(`polygon-line-${polygonId}`)) {
        map.value.addLayer({
          id: `polygon-line-${polygonId}`,
          type: 'line',
          source: featureSource,
          layout: {},
          paint: {
            'line-color': featureProperties.properties.lineColor ?? selectedFeature.value.lineColor,
            'line-width': featureProperties.properties.lineWidth ?? selectedFeature.value.lineWidth,
            'line-dasharray':
              lineStyleMap[
                featureProperties.properties.lineStyle ?? selectedFeature.value.lineStyle
              ],
          },
        })
      }
      if (lastFeature.geometry.type == 'LineString') {
        lastFeature.properties.lineLengths = calculateLineLengths(lastFeature)
        createLineStringLengthLabels(lastFeature)
      }
      if (
        lastFeature.geometry.type !== 'LineString' &&
        !map.value.getLayer(`polygon-label-${polygonId}`)
      ) {
        map.value.addLayer({
          id: `polygon-label-${polygonId}`,
          type: 'symbol',
          source: featureSource,
          filter: ['==', '$type', 'Point'], // Only apply to points, which are centroids
          layout: {
            'icon-image': [
              'case',
              ['any', ['==', ['get', 'label'], null], ['==', ['get', 'label'], '']],
              '',
              'red-rounded',
            ],
            'icon-text-fit': 'both',
            'icon-text-fit-padding': [1, 2, 1, 2],
            'text-field': '{label}',
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 10,
            'text-allow-overlap': false,
            'icon-allow-overlap': false,
            'text-offset': [0, -2.5],
          },
          paint: {
            'text-color': 'black',
          },
        })

        if (!lastFeature.properties.isCircle) {
          lastFeature.properties.lineLengths = calculateLineLengths(lastFeature)
          createLineLengthLabels(lastFeature, lastFeature.properties.showLineLabels)
        }
        updateArea()
      }
    })
    mapInitialState.value = draw.getAll()
  })

  map.value.on('draw.create', (e) => {
    var polygon = e.features[0]
    if (e.features[0].geometry.type === 'Polygon') {
      const existingPolygons = draw.getAll().features.filter((feature) => feature.id !== polygon.id)
      const overlaps = existingPolygons.some((existingPolygon) => {
        if (existingPolygon.geometry.type === 'Polygon') {
          const overlap = turf.intersect(polygon, existingPolygon)
          if (overlap) {
            showModal.value = true
            overlappingPolygon.value.existingPolygon = existingPolygon
            overlappingPolygon.value.polygon = polygon
            return true
          }
        }
        return false
      })

      if (!overlaps) {
        createPolygon(polygon, e)
      }
    } else if (polygon.geometry.type === 'LineString') {
      createLineString(polygon)
    }
    if (e && e.features[0].geometry.type) {
      pushToUndoStack(polygon, 'create')
    }
  })

  map.value.on('click', function (e) {
    var features = map.value.queryRenderedFeatures(e.point)
    if (!features.length) {
      return
    }
    var feature = features[0]
    if (
      ((feature.layer && feature.layer.id.startsWith('polygon-')) ||
        feature.layer.id.startsWith('circle-')) &&
      draw.getMode() !== 'draw_polygon' &&
      !showModal.value
    ) {
      selectedFeature.value.feature = feature
      const updatedFeature = draw.getAll().features.find((item) => item.id == feature.properties.id)
      if (updatedFeature?.properties.updated) {
        toggleSidebar('left')
        selectedFeature.value = {
          feature: feature,
          label: updatedFeature.properties.label,
          fillColor: updatedFeature.properties.fillColor,
          lineColor: updatedFeature.properties.lineColor,
          lineWidth: updatedFeature.properties.lineWidth,
          opacity: updatedFeature.properties.opacity,
          lineStyle: updatedFeature.properties.lineStyle,
          showLineLabels: updatedFeature.properties.showLineLabels,
          showAreaLabel: updatedFeature.properties.showAreaLabel,
          area: updatedFeature.properties.area,
        }
        if (updatedFeature.properties.isCircle) {
          selectedFeature.value.customArea = updatedFeature.properties.customArea
        }
      } else {
        if (Object.keys(feature.properties).length !== 0 && feature.properties.id) {
          toggleSidebar('left')
          selectedFeature.value.feature = feature
          selectedFeature.value.fillColor = feature.properties.fillColor
          selectedFeature.value.lineColor = feature.properties.lineColor
          selectedFeature.value.area = updatedFeature.properties.area
          if (feature.properties.dbId && feature.properties.lineWidth) {
            selectedFeature.value.lineWidth = feature.properties.lineWidth
          }
          if (feature.properties.dbId && feature.geometry.type == 'Polygon') {
            selectedFeature.value.showLineLabels = feature.properties.showLineLabels
            selectedFeature.value.showAreaLabel = feature.properties.showAreaLabel
          }
          if (feature.properties.dbId && feature.properties.opacity) {
            selectedFeature.value.opacity = feature.properties.opacity
          }
          if (feature.properties.dbId && feature.properties.lineStyle) {
            selectedFeature.value.lineStyle = feature.properties.lineStyle
          }
          if (feature.properties.dbId && feature.properties.label) {
            selectedFeature.value.label = feature.properties.label
          }
        }
      }
      if (!showSidebar.value) {
        resetSelectedFeature()
      }
    }
  })

  map.value.on('draw.delete', updateArea)
  map.value.on('draw.update', updatePolygon)
  map.value.on('mousemove', handleMouseMove) // Add mousemove event listener
  map.value.on('draw.render', () => {
    var drawFeatures = draw.getAll().features
    if (drawFeatures.length > 0) {
      const sources = map.value.style._sourceCaches
      const drawSources = Object.keys(sources)
      const filteredDrawSources = drawSources.filter((sourceName) =>
        sourceName.includes('mapbox-gl-draw')
      )
      filteredDrawSources.forEach((drawSource) => {
        sources[drawSource].serialize().data.features.forEach((feature) => {})
      })
    }
  })

  initDistanceDisplay()

  document.getElementById('geocoder').appendChild(geocoder.onAdd(map.value))
})

const initDistanceDisplay = () => {
  const display = distanceDisplay.value
  display.style.position = 'absolute'
  display.style.background = 'rgba(255, 255, 255, 0.8)'
  display.style.padding = '5px'
  display.style.borderRadius = '3px'
  display.style.fontSize = '12px'
  display.style.display = 'none'
}

const handleMouseMove = (e) => {
  const data = draw.getAll()
  if (data.features.length > 0 && draw.getMode() === 'draw_line_string') {
    const feature = data.features[data.features.length - 1]
    const coords = feature.geometry.coordinates
    const lineLengths = calculateLineLengths(feature)
    feature.properties.lineLengths = lineLengths
    // createLineStringLengthLabels(feature)
    const createLengthLabel = (coords) => {
      const line = turf.lineString(coords)
      const length = turf.length(line, { units: selectedUnit.value.sideUnit })
      const rounded_length = Math.round(length * 1000) / 1000
      distanceDisplay.value.innerHTML = `<strong>${rounded_length.toFixed(2)}</strong> ${
        selectedUnit.value.unit
      }`
      distanceDisplay.value.style.left = `${e.point.x + 10}px`
      distanceDisplay.value.style.top = `${e.point.y + 10}px`
      distanceDisplay.value.style.display = 'block'
    }

    if (coords.length > 1) {
      const lastSegmentCoords = coords.slice(-2)
      createLengthLabel(lastSegmentCoords)
    } else {
      distanceDisplay.value.style.display = 'none'
    }
  }
}

const geocodeAddress = async () => {
  try {
    loading.value = true
    const response = await axios.get(
      `https://api.mapbox.com/search/geocode/v6/forward?q=${addressString.value}&access_token=${accessToken}`
    )
    coordinates.value = response.data.features[0].geometry.coordinates
    loading.value = false
  } catch (error) {
    console.log(error)
  }
}

function loadImageWithPromise(imageUrl) {
  return new Promise((resolve, reject) => {
    map.value.loadImage(imageUrl, (error, image) => {
      if (error) {
        reject(error)
      } else {
        resolve(image)
      }
    })
  })
}

const updateArea = (e) => {
  const data = draw.getAll()
  let circleFeatures = draw
    .getAll()
    .features.filter((feature) => feature.properties.isCircle && feature.properties.customArea)
  let circleTotalArea = circleFeatures.reduce((total, feature) => {
    return total + parseFloat(feature.properties.customArea)
  }, 0)

  data.features = data.features.filter(
    (feature) => feature.properties && feature.properties.lineLengths
  )

  if (data.features.length === 0 && circleFeatures.length === 0) {
    document.getElementById('calculated-area').innerHTML = ''
    if (e && e.type !== 'draw.delete') alert('Click the map to draw a polygon.')
    return
  }

  // Compute total area for all polygons
  const totalArea = turf.area(data)
  const totalRoundedArea = convertArea(Math.round(totalArea * 100) / 100, circleTotalArea)

  // Update area for each feature
  data.features.forEach((feature) => {
    if (feature.geometry.type === 'Polygon') {
      const area = turf.area(feature)
      const roundedArea = convertArea(Math.round(area * 100) / 100)
      if (map.value.getLayer(`polygon-area-${feature.id}`)) {
        map.value.setLayoutProperty(`polygon-area-${feature.id}`, 'text-field', roundedArea)
      }
      draw.setFeatureProperty(feature.id, 'area', roundedArea)
    }
  })

  // Update the total area display
  document.getElementById(
    'calculated-area'
  ).innerHTML = `<p><strong>${totalRoundedArea}</strong></p>`
}

const updatePolygon = (polygon, undoState) => {
  const updatePolygon = polygon.features ? polygon.features[0] : polygon
  if (updatePolygon.geometry.type === 'Polygon' && !updatePolygon.properties.isCircle) {
    const feature = updatePolygon

    if (!feature || !feature.geometry) {
      console.error('Invalid feature or geometry missing!')
      return
    }
    feature.properties.id = feature.id
    feature.properties.updatePolygon = true
    feature.properties.actionType = 'updated'
    const sourceId = 'polygon-' + feature.id
    const source = map.value.getSource(sourceId)

    if (!source) {
      console.error('Source not found:', sourceId)
      return
    }
    // Recalculate the line lengths.
    const lineLengths = calculateLineLengths(feature)
    feature.properties.lineLengths = lineLengths
    // Update the line length labels.
    source.setData({
      type: 'FeatureCollection',
      features: [feature, getCentroid(feature)],
    })
    createLineLengthLabels(feature, feature.properties.showLineLabels)
    if (feature.properties.dbId) {
      if (Features.value.updated.findIndex((item) => item.id == feature.id) === -1) {
        Features.value.updated.push(feature)
      }
    }
    if (polygon && undoState !== false) {
      pushToUndoStack(feature, 'update')
    }
    updateArea(feature)
  } else if (updatePolygon.geometry.type === 'LineString') {
    const coords = updatePolygon.geometry.coordinates
    const lineLengths = calculateLineLengths(updatePolygon)
    updatePolygon.properties.lineLengths = lineLengths
    createLineStringLengthLabels(updatePolygon)
    const createLengthLabel = (coords) => {
      const line = turf.lineString(coords)
      const length = turf.length(line, { units: selectedUnit.value.sideUnit })
      const rounded_length = Math.round(length * 1000) / 1000
      distanceDisplay.value.innerHTML = `<strong>${rounded_length.toFixed(2)}</strong> ${
        selectedUnit.value.unit
      }`
    }

    if (coords.length > 1) {
      const lastSegmentCoords = coords.slice(-2)
      createLengthLabel(lastSegmentCoords)
    } else {
      distanceDisplay.value.style.display = 'none'
    }
  }
}

const exportMap = async () => {
  const mapCanvas = map.value.getCanvas()
  const { width, height } = mapCanvas

  // Create an off-screen canvas
  const offScreenCanvas = document.createElement('canvas')
  offScreenCanvas.width = width
  offScreenCanvas.height = height
  const ctx = offScreenCanvas.getContext('2d')

  // Draw the original map image onto the off-screen canvas
  ctx.drawImage(mapCanvas, 0, 0)

  let imagesToLoad = []
  if (companyDataQuery.result.value.organization.watermarkUrl) {
    imagesToLoad.push({
      url: companyDataQuery.result.value.organization.watermarkUrl,
      x: 10,
      y: 10,
      w: 600,
      h: 200,
    })
  }

  await Promise.all(imagesToLoad.map((image) => loadImageAndDraw(ctx, image)))
  triggerDownload(offScreenCanvas.toDataURL())
}

const loadImageAndDraw = async (ctx, { url, x, y, w, h }) => {
  const response = await fetch(url)
  const blob = await response.blob()
  const img = new Image()
  return new Promise((resolve) => {
    img.onload = () => {
      ctx.drawImage(img, x, y, w, h)
      resolve()
    }
    img.src = URL.createObjectURL(blob)
  })
}

const triggerDownload = (dataUrl) => {
  const link = document.createElement('a')
  link.download = 'map.png'
  link.href = dataUrl
  link.click()
}

const convertArea = (area, circleArea) => {
  let circleTotalArea = circleArea ?? 0
  if (selectedUnit.value.value === 'square_meter') {
    return (area + circleTotalArea).toFixed(2) + ' m²'
  } else if (selectedUnit.value.value === 'square_feet') {
    return (area * 10.7639 + circleTotalArea).toFixed(2) + ' ft²'
  } else if (selectedUnit.value.value === 'square_yard') {
    return (area * 1.19599 + circleTotalArea).toFixed(2) + ' yd²'
  } else if (selectedUnit.value.value === 'square_kilometre') {
    return (area * 0.000001 + circleTotalArea).toFixed(2) + ' km²'
  } else if (selectedUnit.value.value === 'square_mile') {
    return (area / 2589988.11 + circleTotalArea).toFixed(2) + ' mi²'
  }
}

function deletePolygonAndLayers() {
  let polygon = selectedFeature.value.feature
  selectedFeature.value.feature.properties.actionType = 'updated'

  pushToUndoStack(selectedFeature.value.feature, 'delete')
  if (polygon.properties.dbId) {
    Features.value.deleted.push(polygon)
  }
  if (polygon.properties.isCircle) {
    draw.delete(polygon.properties.lineId)
  }
  draw.delete(polygon.properties.id)
  removeLayer(polygon.properties.id)
  updateArea()
  toggleSidebar('left')
}

const removeLayer = (featureId) => {
  map.value.getStyle().layers.forEach((layer) => {
    if (layer.id.startsWith('polygon-' + featureId + '-line-label-')) {
      map.value.removeLayer(layer.id)
      if (map.value.getSource(layer.id)) {
        map.value.removeSource(layer.id)
      }
    }
  })
  if (map.value.getLayer('polygon-color-' + featureId)) {
    map.value.removeLayer('polygon-color-' + featureId)
  }
  if (map.value.getLayer('polygon-label-' + featureId)) {
    map.value.removeLayer('polygon-label-' + featureId)
  }
  if (map.value.getLayer('polygon-area-' + featureId)) {
    map.value.removeLayer('polygon-area-' + featureId)
  }
  if (map.value.getLayer('polygon-line-' + featureId)) {
    map.value.removeLayer('polygon-line-' + featureId)
  }
}

// Function to calculate the length of each side of the polygon
function calculateLineLengths(polygon) {
  const lineLengths = []
  if (polygon.geometry.type === 'Polygon') {
    const coordinates = polygon.geometry.coordinates[0] // Assuming it's a simple polygon
    for (let i = 0; i < coordinates.length - 1; i++) {
      const start = coordinates[i]
      const end = coordinates[i + 1]
      const line = turf.lineString([start, end])
      const length = turf.length(line, { units: selectedUnit.value.sideUnit })
      lineLengths.push(length)
    }
  } else if (polygon.geometry.type === 'LineString') {
    const coordinates = polygon.geometry.coordinates
    for (let i = 0; i < coordinates.length - 1; i++) {
      const start = coordinates[i]
      const end = coordinates[i + 1]
      const line = turf.lineString([start, end])
      const length = turf.length(line, { units: selectedUnit.value.sideUnit })
      lineLengths.push(length)
    }
  }
  return lineLengths
}

const createLineStringLengthLabels = (feature) => {
  const coordinates = feature.geometry.coordinates

  const lineLengths = feature.properties.lineLengths
  coordinates.forEach((coord, index) => {
    if (index < coordinates.length - 1) {
      const sourceId = `polygon-${feature.id ?? feature.properties.id}-line-label-${index}`
      const layerId = `polygon-${feature.id ?? feature.properties.id}-line-label-${index}`

      // Ensure coord is an array
      const start = Array.isArray(coord) ? turf.point(coord) : null
      const end = Array.isArray(coordinates[index + 1]) ? turf.point(coordinates[index + 1]) : null

      if (start && end) {
        // Calculate the midpoint of the line segment
        const midPoint = turf.midpoint(start, end).geometry.coordinates
        const lengthLabel = `${lineLengths[index].toFixed(2)} ${selectedUnit.value.unit}`

        const labelFeature = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: midPoint,
          },
          properties: {
            label: lengthLabel,
          },
        }

        // Ensure the source for the label exists
        if (!map.value.getSource(sourceId)) {
          map.value.addSource(sourceId, {
            type: 'geojson',
            data: labelFeature,
          })
        } else {
          map.value.getSource(sourceId).setData(labelFeature)
        }

        // Create or update the layer for the label
        if (!map.value.getLayer(layerId)) {
          map.value.addLayer({
            id: layerId,
            type: 'symbol',
            source: sourceId,
            layout: {
              'icon-image': 'black-rounded',
              'icon-text-fit': 'both',
              'icon-text-fit-padding': [1, 2, 1, 2], // Optional padding around the text within the icon
              'text-field': '{label}',
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-size': 10,
              'text-allow-overlap': false, // Allows text to overlap with other map features
              'icon-allow-overlap': false,
            },
            paint: {
              'text-color': '#fff',
            },
          })
        }
      } else {
        if (map.value.getLayer(layerId)) {
          map.value.removeLayer(layerId)
        }
        if (map.value.getSource(sourceId)) {
          map.value.removeSource(sourceId)
        }
      }
    }
  })
}

const createLineLengthLabels = (polygon, show) => {
  const coordinates =
    polygon.geometry.type == 'LineString'
      ? polygon.geometry.coordinates
      : polygon.geometry.coordinates[0]
  coordinates.forEach((coord, index) => {
    if (index < coordinates.length - 1) {
      // Avoid the closing coordinate of the polygon
      const sourceId = `polygon-${polygon.id ?? polygon.properties.id}-line-label-${index}`
      const layerId = `polygon-${polygon.id ?? polygon.properties.id}-line-label-${index}`

      const lineLengths =
        typeof polygon.properties.lineLengths == 'string'
          ? JSON.parse(polygon.properties.lineLengths)
          : polygon.properties.lineLengths
      if (show) {
        if (lineLengths[index] >= selectedUnit.value.thresholdLength) {
          // Create or update the labels
          const midPoint = turf.midpoint(turf.point(coord), turf.point(coordinates[index + 1]))
            .geometry.coordinates
          const lengthLabel = `${lineLengths[index].toFixed(2)} ${selectedUnit.value.unit}`

          const labelFeature = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: midPoint,
            },
            properties: {
              label: lengthLabel,
            },
          }

          // Ensure the source for the label exists
          if (!map.value.getSource(sourceId)) {
            map.value.addSource(sourceId, {
              type: 'geojson',
              data: labelFeature,
            })
          } else {
            map.value.getSource(sourceId).setData(labelFeature)
          }

          // Create or update the layer for the label
          if (!map.value.getLayer(layerId)) {
            map.value.addLayer({
              id: layerId,
              type: 'symbol',
              source: sourceId,
              layout: {
                'icon-image': 'black-rounded',
                'icon-text-fit': 'both',
                'icon-text-fit-padding': [1, 2, 1, 2], // Optional padding around the text within the icon
                'text-field': '{label}',
                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                'text-size': 10,
                'text-allow-overlap': false, // Allows text to overlap with other map features
                'icon-allow-overlap': false,
              },
              paint: {
                'text-color': '#fff',
              },
            })
          }
        }
      } else {
        if (map.value.getLayer(layerId)) {
          map.value.removeLayer(layerId)
        }
        if (map.value.getSource(sourceId)) {
          map.value.removeSource(sourceId)
        }
      }
    }
  })
}

const getAllDrawnPolygons = () => {
  // Retrieve all features from Mapbox Draw
  const allFeatures = draw.getAll()
  const polygons = allFeatures.features.filter((feature) => feature.geometry.type === 'Polygon')
  return polygons
}

const updateUnit = (unit) => {
  selectedUnit.value = unit
  const polygons = getAllDrawnPolygons()
  if (polygons.length) {
    polygons.forEach((polygon) => {
      if (!polygon.properties.isCircle) {
        polygon.properties.lineLengths = calculateLineLengths(polygon)
        createLineLengthLabels(polygon, polygon.properties.showLineLabels)
      }
    })
  }
  updateArea()
}

const updateMode = (mode) => {
  selectedMode.value = mode
  draw.changeMode(mode.key)
}

const saveMap = () => {
  if (Features.value.deleted.length) {
    Features.value.deleted.forEach(async (feature) => {
      await deleteGeometry.mutate({ input: { id: feature.properties.dbId } })
    })
    toast.value = { showToast: true, title: 'Features removed Successfully!' }
  }

  if (Features.value.updated.length) {
    Features.value.updated.forEach(async (feature) => {
      const updatedFeature = draw.getAll().features.find((item) => item.id == feature.properties.id)
      await updateGeometry.mutate({
        input: {
          input: {
            id: updatedFeature.properties.dbId,
            properties: JSON.stringify({
              id: updatedFeature.id,
              type: 'Feature',
              geometry: updatedFeature.geometry,
              properties: updatedFeature.properties,
            }),
          },
        },
      })
    })
    toast.value = { showToast: true, title: 'Features updated Successfully!' }
  }

  if (Features.value.created.length) {
    Features.value.created.forEach(async (feature) => {
      const createdFeature = draw.getAll().features.find((item) => item.id == feature.id)
      if (createdFeature) {
        await createGeometry.mutate({
          input: {
            proposalId: route.params.id,
            mapBoxId: createdFeature.id,
            properties: JSON.stringify({
              id: createdFeature.id,
              type: 'Feature',
              geometry: createdFeature.geometry,
              properties: createdFeature.properties,
            }),
          },
        })
      }
    })
    toast.value = { showToast: true, title: 'Features created Successfully!' }
  }

  Features.value = {
    created: [],
    updated: [],
    deleted: [],
  }
}

const updateFeature = (feature) => {
  const polygon = feature.properties ? feature : selectedFeature.value.feature
  toggleSidebar('left')
  var customArea = polygon.properties.isCircle ? selectedFeature.value.customArea : 0
  draw.setFeatureProperty(polygon.properties.id, 'updated', true)
  if (polygon.geometry.type == 'LineString') {
    draw.setFeatureProperty(polygon.properties.id, 'lineColor', selectedFeature.value.lineColor)
    draw.setFeatureProperty(polygon.properties.id, 'lineWidth', selectedFeature.value.lineWidth)
    draw.setFeatureProperty(polygon.properties.id, 'lineStyle', selectedFeature.value.lineStyle)
  } else {
    draw.setFeatureProperty(polygon.properties.id, 'label', selectedFeature.value.label)
    draw.setFeatureProperty(polygon.properties.id, 'fillColor', selectedFeature.value.fillColor)
    draw.setFeatureProperty(polygon.properties.id, 'lineColor', selectedFeature.value.lineColor)
    draw.setFeatureProperty(polygon.properties.id, 'lineWidth', selectedFeature.value.lineWidth)
    draw.setFeatureProperty(polygon.properties.id, 'lineStyle', selectedFeature.value.lineStyle)
    draw.setFeatureProperty(polygon.properties.id, 'opacity', selectedFeature.value.opacity)
    draw.setFeatureProperty(
      polygon.properties.id,
      'showLineLabels',
      selectedFeature.value.showLineLabels
    )
    draw.setFeatureProperty(
      polygon.properties.id,
      'showAreaLabel',
      selectedFeature.value.showAreaLabel
    )
    if (polygon.properties.isCircle) {
      draw.setFeatureProperty(polygon.properties.id, 'customArea', customArea)
      polygon.properties.customArea = customArea
    } else {
      alert('hererer')
      draw.setFeatureProperty(
        polygon.properties.id,
        'showAreaLabel',
        selectedFeature.value.showAreaLabel
      )
    }
  }

  updatePaintProperties(polygon.properties.id, selectedFeature.value)
  if (polygon.geometry.type !== 'LineString') {
    if (map.value.getLayer(`polygon-label-${polygon.properties.id}`)) {
      map.value.setLayoutProperty(
        'polygon-label-' + polygon.properties.id,
        'text-field',
        selectedFeature.value.label
      )
    } else {
      if (selectedFeature.value.label) {
        map.value.addLayer({
          id: 'polygon-label-' + polygon.properties.id,
          type: 'symbol',
          source: polygon.properties.isCircle
            ? `circle-${polygon.properties.id}`
            : `polygon-${polygon.properties.id}`,
          filter: ['==', '$type', 'Point'], // Only apply to points, which are centroids
          layout: {
            'icon-image': 'red-rounded',
            'icon-text-fit': 'both',
            'icon-text-fit-padding': [1, 2, 1, 2],
            'text-field': '{label}',
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 10,
            'text-allow-overlap': false,
            'icon-allow-overlap': false,
            'text-anchor': 'center', // Center text on the centroid
            'text-offset': [0, -2.5],
          },
          paint: {
            'text-color': 'black',
          },
        })

        map.value.setLayoutProperty(
          'polygon-label-' + polygon.properties.id,
          'text-field',
          selectedFeature.value.label
        )
      }
    }
  }
  if (!polygon.properties.isCircle && polygon.geometry.type !== 'LineString') {
    createLineLengthLabels(polygon, selectedFeature.value.showLineLabels)
  }

  if (!polygon.properties.isCircle && polygon.geometry.type !== 'LineString') {
    if (
      !selectedFeature.value.showAreaLabel &&
      map.value.getLayer('polygon-area-' + polygon.properties.id)
    ) {
      map.value.removeLayer('polygon-area-' + polygon.properties.id)
    } else if (
      selectedFeature.value.showAreaLabel &&
      !map.value.getLayer('polygon-area-' + polygon.properties.id)
    ) {
      map.value.addLayer({
        id: `polygon-area-${polygon.properties.id}`,
        type: 'symbol',
        source: `polygon-${polygon.properties.id}`,
        filter: ['==', '$type', 'Point'], // Only apply to points, which are centroids
        layout: {
          'icon-image': 'white-rounded',
          'icon-text-fit': 'both',
          'icon-text-fit-padding': [1, 2, 1, 2], // Optional padding around the text within the icon
          'text-field': selectedFeature.value.area,
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 10,
          'text-offset': [0, 0],
          'text-allow-overlap': false, // Allows text to overlap with other map features
          'icon-allow-overlap': false,
        },
        paint: {
          'text-color': 'black',
        },
      })
    }
  }

  if (polygon.properties.isCircle && polygon.properties.customArea) {
    updateArea(polygon)
  }
  if (polygon.properties.dbId) {
    if (Features.value.updated.findIndex((item) => item.id == polygon.id) === -1) {
      Features.value.updated.push(polygon)
    }
  }
  pushToUndoStack()
  resetSelectedFeature()
}

const resetSelectedFeature = () => {
  selectedFeature.value = {
    feature: null,
    label: null,
    customArea: null,
    fillColor: drawFillColor.value,
    lineColor: drawLineColor.value,
    lineWidth: 3,
    opacity: 0.5,
    lineStyle: 'solid',
    showLineLabels: true,
    showAreaLabel: true,
  }
}

const createPolygon = (polygon, e) => {
  var layerId = polygon.properties.isCircle ? 'circle-' + polygon.id : 'polygon-' + polygon.id
  const centroidFeature = getCentroid(polygon)
  if (!polygon.properties.id) {
    polygon.properties.id = polygon.id
  }
  if (!polygon.id) {
    polygon.id = polygon.id ?? polygon.properties.id
  }
  polygon.properties.fillColor = drawFillColor.value
  polygon.properties.lineColor = drawLineColor.value
  polygon.properties.updatePolygon = true
  polygon.properties.createPolygon = true
  polygon.properties.showLineLabels = true
  polygon.properties.showAreaLabel = true
  polygon.properties.actionType = 'added'
  polygon.properties.area = '0'

  if (!polygon.properties.isCircle) {
    var lineLengths = calculateLineLengths(polygon)
    polygon.properties.lineLengths = lineLengths
  }
  draw.add(polygon)
  var featureCollection = {
    type: 'FeatureCollection',
    features: [polygon, centroidFeature],
  }

  // Add or update the source with new data
  if (map.value.getSource(layerId)) {
    map.value.getSource(layerId).setData(featureCollection)
  } else {
    map.value.addSource(layerId, {
      type: 'geojson',
      data: featureCollection,
    })
  }

  map.value.addLayer({
    id: 'polygon-color-' + polygon.id,
    type: 'fill',
    source: layerId,
    layout: {},
    paint: {
      'fill-color': drawFillColor.value,
      'fill-opacity': selectedFeature.value.opacity,
    },
  })

  if (!polygon.properties.isCircle) {
    if (!map.value.getLayer('polygon-area-' + polygon.id)) {
      map.value.addLayer({
        id: 'polygon-area-' + polygon.id,
        type: 'symbol',
        source: layerId,
        filter: ['==', '$type', 'Point'], // Only apply to points, which are centroids
        layout: {
          'icon-image': 'white-rounded',
          'icon-text-fit': 'both',
          'icon-text-fit-padding': [1, 2, 1, 2],
          'text-field': '{label}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-size': 10,
          'text-allow-overlap': false,
          'icon-allow-overlap': false,
          'text-anchor': 'center',
          'text-offset': [0, 0],
        },
        paint: {
          'text-color': 'black',
        },
      })
    }
  }

  // Add outline around the polygon.
  map.value.addLayer({
    id: `polygon-line-${polygon.id}`,
    type: 'line',
    source: layerId,
    layout: {},
    paint: {
      'line-color': drawLineColor.value,
      'line-width': selectedFeature.value.lineWidth,
    },
  })

  Features.value.created.push(polygon)
  if (!polygon.properties.isCircle) {
    updateArea(e)
    createLineLengthLabels(polygon, true)
  }
}

const createLineString = (polygon) => {
  const featureSource = `polygon-${polygon.id}`
  if (!polygon.properties.id) {
    polygon.properties.id = polygon.id
  }
  polygon.properties.lineColor = drawLineColor.value
  polygon.properties.lineLengths = calculateLineLengths(polygon)
  draw.add(polygon)
  var featureCollection = {
    type: 'FeatureCollection',
    features: [polygon],
  }

  if (!map.value.getSource(featureSource)) {
    map.value.addSource(featureSource, {
      type: 'geojson',
      data: featureCollection,
    })
  } else {
    map.value.getSource(featureSource).setData(featureCollection)
  }
  if (!map.value.getLayer(`polygon-line-${polygon.id}`)) {
    map.value.addLayer({
      id: `polygon-line-${polygon.id}`,
      type: 'line',
      source: featureSource,
      layout: {},
      paint: {
        'line-color': drawLineColor.value,
        'line-width': 5,
      },
    })
  }
  distanceDisplay.value.style.display = 'none'
  Features.value.created.push(polygon)
  createLineStringLengthLabels(polygon)
}

function getCentroid(polygon) {
  var feature = turf.centroid(polygon)
  feature.properties = { ...polygon.properties } // Maintain original properties
  return feature
}

const addOverlappingPolygon = (e) => {
  createPolygon(overlappingPolygon.value.polygon)
  showModal.value = false
  overlappingPolygon.value = {}
}

const removeOverlappingArea = async (e) => {
  let subtractedFeature = turf.difference(
    overlappingPolygon.value.existingPolygon,
    overlappingPolygon.value.polygon
  )
  subtractedFeature.id = overlappingPolygon.value.existingPolygon.id
  draw.delete(overlappingPolygon.value.existingPolygon.id)
  draw.add(subtractedFeature)
  updatePolygon(subtractedFeature)
  draw.delete(overlappingPolygon.value.polygon.id) // Remove the polygon from the drawing manager
  showModal.value = false
  overlappingPolygon.value = {}
  return true
}

const cancelOverlapping = () => {
  showModal.value = false
  draw.delete(overlappingPolygon.value.polygon.id)
  overlappingPolygon.value = {}
}

const handleFullscreen = () => {
  const mapContainer = document.getElementById('map') // Get the map container element

  if (!document.fullscreenElement) {
    // If not currently in fullscreen, request fullscreen on the map container
    if (mapContainer.requestFullscreen) {
      mapContainer
        .requestFullscreen()
        .then(() => {
          map.value.resize() // Ensure the map resizes to fit the fullscreen container
        })
        .catch((err) => {
          alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`)
        })
    } else {
      alert('Fullscreen API is not supported by your browser.')
    }
  } else {
    // If currently in fullscreen, exit fullscreen
    if (document.exitFullscreen) {
      document
        .exitFullscreen()
        .then(() => {
          map.value.resize() // Resize the map as it may need to adjust out of fullscreen
        })
        .catch((err) => {
          alert(`Error attempting to exit full-screen mode: ${err.message} (${err.name})`)
        })
    }
  }
}

const updateFillColor = (color) => {
  if (selectedFeature.value.feature) {
    selectedFeature.value.fillColor = color.hex
  } else {
    drawFillColor.value = color.hex
  }
}

const updateLineColor = (color) => {
  if (selectedFeature.value.feature) {
    selectedFeature.value.lineColor = color.hex
  } else {
    drawLineColor.value = color.hex
  }
}

const handleCloseSidebar = () => {
  toggleSidebar('left')
  resetSelectedFeature()
}

function pushToUndoStack(feature, actionType) {
  const currentState = draw.getAll()
  if (feature) {
    const featureIndex = currentState.features.findIndex((f) => f.id === feature.id)
    if (featureIndex !== -1) {
      currentState.features[featureIndex] = feature
    }
  }
  undoStack.value.push(currentState)
}

const undo = () => {
  const currentFeatures = draw.getAll().features.map((f) => f.properties.id ?? f.id)
  if (undoStack.value.length > 1) {
    redoStack.value.push(draw.getAll())
    const currentFeatureState = undoStack.value.pop()
    const lastState = undoStack.value[undoStack.value.length - 1]
    const featuresArray = lastState.features.reduce((acc, lastFeature) => {
      const currentFeature = currentFeatureState.features.find(
        (current) => current.id === lastFeature.id
      )
      if (currentFeature) {
        acc.push({
          featureId: lastFeature.id,
          lastState: lastFeature,
          currentState: currentFeature,
        })
      }
      return acc
    }, [])

    const newFeatures = lastState.features.filter(
      (lf) => !draw.getAll().features.some((cf) => cf.id === lf.id)
    )
    // Identify removed polygons
    const lastFeatures = new Set(lastState.features.map((f) => f.properties.id ?? f.id))
    const removedFeatures = currentFeatures.filter((id) => !lastFeatures.has(id))
    const removedFeature = draw.getAll().features.find((lf) => lf.id === removedFeatures[0])
    draw.set(lastState) // Revert to the last state
    // Clean up layers for removed polygons
    if (removedFeatures.length) {
      const verticesArray = removedFeature.geometry.coordinates[0]
      if (verticesArray.length - 1 > 3) {
        // Remove the second-to-last vertex to keep the polygon closed
        verticesArray.splice(verticesArray.length - 2, 1)[0]
        // redoStacks.value.push(removedVertex)

        // Update the polygon with the remaining vertices
        const polygon = removedFeature
        polygon.geometry.coordinates[0] = verticesArray

        // Update the polygon on the map
        draw.delete(polygon.id)
        draw.add(polygon)
        updatePolygon(polygon, false)
        pushToUndoStack(polygon)

        // Remove the label for the removed line
        const indexToRemove = verticesArray.length - 1 // This should be the index of the removed vertex
        const sourceId = `polygon-${
          polygon.id ?? polygon.properties.id
        }-line-label-${indexToRemove}`
        const layerId = `polygon-${polygon.id ?? polygon.properties.id}-line-label-${indexToRemove}`

        if (map.value.getLayer(layerId)) {
          map.value.removeLayer(layerId)
        }
        if (map.value.getSource(sourceId)) {
          map.value.removeSource(sourceId)
        }
      } else {
        removedFeatures.forEach((featureId) => removeLayer(featureId))
      }
    }

    if (newFeatures.length) {
      newFeatures.forEach((feature) => {
        createPolygon(feature)
      })
    }

    featuresArray.forEach((item) => {
      let itemCoordinates =
        item.lastState.geometry.type == 'LineString'
          ? item.lastState.geometry.coordinates
          : item.lastState.geometry.coordinates[0]
      if (item.currentState.properties.updatePolygon) {
        updatePolygon(item.lastState, false)
      }
      if (item.currentState.properties.updated) {
        updatePaintProperties(item.featureId, item.lastState.properties)
      }
      if (itemCoordinates.length !== item.lastState.properties?.lineLengths?.length) {
        const indexToRemove = itemCoordinates.length - 1
        const sourceId = `polygon-${item.featureId}-line-label-${indexToRemove}`
        const layerId = `polygon-${item.featureId}-line-label-${indexToRemove}`
        if (map.value.getLayer(layerId)) {
          map.value.removeLayer(layerId)
        }
        if (map.value.getSource(sourceId)) {
          map.value.removeSource(sourceId)
        }
      }
    })
  } else if (undoStack.value.length === 1) {
    const lastState = undoStack.value.pop()
    const savedFeatures = mapInitialState.value.features
    const removedFeatures = lastState.features.filter(
      (lf) => !savedFeatures.some((cf) => cf.id === lf.id)
    )
    const newFeatures = lastState.features.filter(
      (lf) => !draw.getAll().features.some((cf) => cf.id === lf.id)
    )

    if (lastState.features.length > savedFeatures.length) {
      const lineStringFeature =
        lastState.features[lastState.features.length - 1].geometry.type == 'LineString'
      const verticesArray = lineStringFeature
        ? lastState.features[lastState.features.length - 1].geometry.coordinates
        : lastState.features[lastState.features.length - 1].geometry.coordinates[0]

      if (
        verticesArray.length > 4 &&
        !lineStringFeature &&
        !lastState.features[lastState.features.length - 1].properties.isCircle
      ) {
        // Remove the second-to-last vertex to keep the polygon closed
        verticesArray.splice(verticesArray.length - 2, 1)[0]
        const polygons = draw.getAll().features
        // Update the polygon with the remaining vertices
        const polygon = polygons[polygons.length - 1]
        if (lineStringFeature) {
          polygon.geometry.coordinates = verticesArray
        } else {
          polygon.geometry.coordinates[0] = verticesArray
        }
        redoStack.value.push(draw.getAll())

        // Update the polygon on the map
        draw.delete(polygon.id)
        draw.add(polygon)
        updatePolygon(polygon, false)
        pushToUndoStack(polygon)

        // Remove the label for the removed line
        const indexToRemove = verticesArray.length - 1 // This should be the index of the removed vertex
        const sourceId = `polygon-${
          polygon.id ?? polygon.properties.id
        }-line-label-${indexToRemove}`
        const layerId = `polygon-${polygon.id ?? polygon.properties.id}-line-label-${indexToRemove}`
        if (map.value.getLayer(layerId)) {
          map.value.removeLayer(layerId)
        }
        if (map.value.getSource(sourceId)) {
          map.value.removeSource(sourceId)
        }
      } else {
        redoStack.value.push(draw.getAll())
        removedFeatures.forEach((feature) => {
          draw.delete(feature.id)
          removeLayer(feature.id)
        })
        undoStack.value = []
      }
    } else if (draw.getAll().features.length < savedFeatures.length) {
      draw.set(draw.getAll())
      newFeatures.forEach((feature) => createPolygon(feature))
    } else {
      draw.set(mapInitialState.value)
      savedFeatures.forEach((feature) => {
        if (feature.properties.dbId) {
          updatePolygon(feature, false)
        }
        if (feature.properties.dbId && feature.properties.updated) {
          updatePaintProperties(feature.properties.id, feature.properties)
        }
      })
    }
  } else {
    console.warn('No more states to undo.')
  }
}

const redo = () => {
  const currentFeatures = draw.getAll().features.map((f) => f.properties.id)
  if (redoStack.value.length > 0) {
    const stateToRestore = redoStack.value.pop()
    const addedFeature = stateToRestore.features.filter(
      (feature) => !currentFeatures.includes(feature.properties.id)
    )

    const removedFeatures = draw
      .getAll()
      .features.filter((lf) => !stateToRestore.features.some((cf) => cf.id === lf.id))

    if (removedFeatures.length) {
      removedFeatures.forEach((feature) => {
        draw.delete(feature.id)
        removeLayer(feature.id)
      })
    }

    if (addedFeature.length) {
      addedFeature.forEach((feature) => {
        if (feature.geometry.type == 'LineString') {
          createLineString(feature)
        } else {
          createPolygon(feature)
        }
      })
    }
    draw.set(stateToRestore) // Apply the restored state

    stateToRestore.features.forEach((item) => {
      if (item.properties.updatePolygon) {
        updatePolygon(item, false) // Might need to update based on how the redo affects visual aspects
      }
      if (item.properties.updated) {
        updatePaintProperties(item.properties.id, item.properties)
      }
    })
    pushToUndoStack()
  } else {
    console.warn('No more states to redo.')
  }
}

const updatePaintProperties = (id, featureProperty) => {
  if (featureProperty?.feature?.geometry?.type == 'LineString') {
    map.value.setPaintProperty(
      `polygon-line-${id}`,
      'line-width',
      featureProperty.lineWidth ?? selectedFeature.value.lineWidth
    )
    map.value.setPaintProperty(
      `polygon-line-${id}`,
      'line-color',
      featureProperty.lineColor ?? selectedFeature.value.lineColor
    )
    map.value.setPaintProperty(
      `polygon-line-${id}`,
      'line-dasharray',
      lineStyleMap[featureProperty.lineStyle ?? selectedFeature.value.lineStyle]
    )
  } else {
    map.value.setPaintProperty(
      `polygon-color-${id}`,
      'fill-color',
      featureProperty.fillColor ?? selectedFeature.value.fillColor
    )
    map.value.setPaintProperty(
      `polygon-color-${id}`,
      'fill-opacity',
      featureProperty.opacity ?? selectedFeature.value.opacity
    )
    map.value.setPaintProperty(
      `polygon-line-${id}`,
      'line-width',
      featureProperty.lineWidth ?? selectedFeature.value.lineWidth
    )
    map.value.setPaintProperty(
      `polygon-line-${id}`,
      'line-color',
      featureProperty.lineColor ?? selectedFeature.value.lineColor
    )
    map.value.setPaintProperty(
      `polygon-line-${id}`,
      'line-dasharray',
      lineStyleMap[featureProperty.lineStyle ?? selectedFeature.value.lineStyle]
    )
  }
}
</script>

<style>
@import 'https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css';
.map {
  width: 100%;
  height: 800px;
}
.calculation-box {
  height: 100px;
  width: 150px;
  position: relative;
  bottom: 200px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
}
.custom-controls {
  z-index: 10;
  display: flex;
  align-items: center;
  position: absolute;
  top: 1rem;
  left: 1rem;
}
.geocoder {
  width: 20rem;
}
.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}
.mapboxgl-ctrl-geocoder > input {
  padding-left: 2rem;
  border-radius: 1rem;
}

.mapboxgl-popup-close-button {
  width: 20px;
  height: 20px;
  font-size: 18px;
}

.flex-center {
  position: absolute;
  display: flex;
  justify-content: center;
}

.flex-center.left {
  left: 0px;
}

.sidebar-content {
  width: 95%;
  height: 95%;
}

.sidebar {
  transition: transform 1s;
  z-index: 10;
  width: 300px;
  height: 100%;
}

.left.collapsed {
  transform: translateX(-295px);
}

.right.collapsed {
  transform: translateX(295px);
}

.line-rounded-label {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 0.25em 0.5em;
  font-size: 0.8em;
  border-radius: 1em;
  border: 1px solid black;
}
</style>
