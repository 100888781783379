<template>
  <Loader v-if="equipmentDataQuery.loading.value" />
  <div v-else>
    <Modal :open="showModal" title="Add Equipment">
      <form>
        <div class="grid grid-cols-2 gap-4 my-10">
          <div class="w-60">
            <label for="name" class="block text-sm font-medium text-gray-700">Name </label>
            <div
              class="mt-1 border-b border-gray-300 focus-within:border-indigo-600"
              :class="errors.name && 'border-failure-600'"
            >
              <input
                type="text"
                v-model="name"
                v-bind="nameAttrs"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="Enter Name"
              />
            </div>
            <p v-if="errors.name" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
              {{ errors.name }}
            </p>
          </div>

          <div class="w-60">
            <label for="name" class="block text-sm font-medium text-gray-700">Fuel Charge </label>
            <div
              class="mt-1 border-b border-gray-300 focus-within:border-indigo-600"
              :class="errors.fuelCharge && 'border-failure-600'"
            >
              <input
                type="text"
                v-model="fuelCharge"
                v-bind="fuelChargeAttrs"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="Enter Fuel Charge"
              />
            </div>
            <p
              v-if="errors.fuelCharge"
              class="mt-1 first-letter:capitalize text-failure-500 text-xs"
            >
              {{ errors.fuelCharge }}
            </p>
          </div>

          <div class="w-60">
            <label for="name" class="block text-sm font-medium text-gray-700">Cost Per Unit </label>
            <div
              class="mt-1 border-b border-gray-300 focus-within:border-indigo-600"
              :class="errors.costPerUnit && 'border-failure-600'"
            >
              <input
                type="text"
                v-model="costPerUnit"
                v-bind="costPerUnitAttrs"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="Enter Cost Per Unit"
              />
            </div>
            <p
              v-if="errors.costPerUnit"
              class="mt-1 first-letter:capitalize text-failure-500 text-xs"
            >
              {{ errors.costPerUnit }}
            </p>
          </div>

          <div v-if="!editModal" class="w-60">
            <label class="block text-sm font-medium text-gray-700">Category </label>
            <select
              v-model="category"
              v-bind="categoryAttrs"
              class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
              :class="errors.category && 'border-failure-600'"
            >
              <option
                v-for="option in store.categoriesData?.__type.enumValues"
                :value="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <p v-if="errors.category" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
              {{ errors.category }}
            </p>
          </div>

          <div class="col-span-1 w-60">
            <ModalMultiSelect
              label="Select Service"
              :searchArray="store.serviceData.services.nodes"
              v-model="services"
              v-bind="servicesAttrs"
              :required="true"
              valueField="id"
              :error="errors.services"
            />
            <p v-if="errors.services" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
              {{ errors.services }}
            </p>
          </div>
        </div>
      </form>

      <div class="flex justify-end mx-5 py-4">
        <button
          type="button"
          class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
          @click="
            () => {
              showModal = false
              editModal = false
              handleReset()
            }
          "
        >
          Cancel
        </button>
        <button
          type="submit"
          class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
          @click="handleSubmitData"
        >
          Submit
        </button>
      </div>
    </Modal>
    <div class="flex my-4 px-1 flex-row justify-between items-center">
      <h1 class="text-2xl font-semibold text-gray-900">Equipments</h1>
      <BaseButton type="button" @click="showModal = true" label="New Equipment" size="small" />
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-primary-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Fuel Charge
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Cost Per Unit
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Services
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="equipment in equipmentDataQuery.result.value.equipment.nodes"
                  :key="equipment.id"
                >
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ equipment.name }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ equipment.fuelChargePercentage }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ equipment.costPerUnit }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ equipment.services.nodes[0].name }}
                  </td>
                  <td class="relative whitespace-nowrap py-4 text-sm font-medium">
                    <div class="flex">
                      <div class="px-1">
                        <PencilSquareIcon
                          @click="handleEdit(equipment)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="px-1">
                        <TrashIcon
                          @click="handleDelete(equipment)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/20/solid'
import Loader from '@/components/layout/Loader.vue'

import Modal from '@/components/layout/Modal.vue'
import BaseButton from '@/components/layout/BaseButton.vue'
import ModalMultiSelect from '@/components/layout/ModalMultiSelect.vue'
import CREATE_EQUIPMENT from '../../graphql/mutations/admin/createEquipment.gql'
import DELETE_EQUIPMENT from '../../graphql/mutations/admin/deleteEquipment.gql'
import UPDATE_EQUIPMENT from '../../graphql/mutations/admin/updateEquipment.gql'
import GET_EQUIPMENT_DATA from '../../graphql/queries/getEquipmentData.gql'

import { useAdminStore } from '../../stores/admin'

const showModal = ref(false)
const editModal = ref(false)

const validationSchema = computed(() => {
  return yup.object({
    name: yup.string().required('Name is required.'),
    fuelCharge: yup
      .number()
      .max(1, 'Fuel charge must not exceed 1')
      .required('Fuel charge is required.'),
    costPerUnit: yup.number().required('Cost per unit is required.'),
    services: yup.array().min(1, 'At least one service must be selected'),
    category: editModal.value
      ? yup.string().nullable()
      : yup.string().required('Category is required'),
  })
})

const { defineField, errors, setValues, handleSubmit, handleReset } = useForm({
  initialValues: {
    services: [],
  },
  validationSchema: validationSchema,
})

const [name, nameAttrs] = defineField('name')
const [fuelCharge, fuelChargeAttrs] = defineField('fuelCharge')
const [costPerUnit, costPerUnitAttrs] = defineField('costPerUnit')
const [services, servicesAttrs] = defineField('services')
const [category, categoryAttrs] = defineField('category')

const createEquipment = useMutation(CREATE_EQUIPMENT)
const deleteEquipment = useMutation(DELETE_EQUIPMENT)
const updateEquipment = useMutation(UPDATE_EQUIPMENT)
const equipmentDataQuery = useQuery(GET_EQUIPMENT_DATA)
const store = useAdminStore()

const handleSubmitData = handleSubmit(async (values) => {
  if (editModal.value) {
    await updateEquipment.mutate({
      input: {
        input: {
          id: values.id,
          costPerUnit: parseFloat(values.costPerUnit),
          fuelCharge: parseFloat(values.fuelCharge),
          name: values.name,
          services: values.services,
        },
      },
    })
    editModal.value = false
    handleReset()
  } else {
    await createEquipment.mutate({
      input: {
        input: {
          costPerUnit: parseFloat(values.costPerUnit),
          fuelCharge: parseFloat(values.fuelCharge),
          name: values.name,
          services: values.services,
          categories: values.category,
        },
      },
    })
  }

  showModal.value = false
  await equipmentDataQuery.refetch()
})

const handleDelete = async (item) => {
  await deleteEquipment.mutate({ input: { id: item.id } })
  await equipmentDataQuery.refetch()
}

const handleEdit = (item) => {
  editModal.value = true
  setValues({
    id: item.id,
    name: item.name,
    fuelCharge: item.fuelChargePercentage,
    costPerUnit: item.costPerUnit,
    services: item.services.nodes.map((service) => service.id),
  })
  showModal.value = true
}
</script>
