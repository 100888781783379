<template>
  <Combobox as="div" v-model="selectedItem" multiple>
    <div class="relative">
      <ComboboxInput
        disabled
        class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
        @change="query = $event.target.value"
        :display-value="
          (selectItem) =>
            selectItem.length == 0
              ? 'Add New Item'
              : selectItem.length == 1
              ? selectItem[0]?.name || selectItem[0]?.title
              : `${selectItem[0]?.name || selectItem[0]?.title} ...`
        "
      />
      <ComboboxButton
        v-if="selectedItem.length > 0"
        class="absolute inset-y-0 right-6 flex items-center rounded-r-md px-2 focus:outline-none"
      >
        <span
          class="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-primary-800"
          >{{ selectedItem.length }} selected</span
        >
      </ComboboxButton>

      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
      >
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredItems.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="item in filteredItems"
          :key="item.id"
          :value="item"
          @click="handleTest"
          as="template"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-8 pr-4',
              active ? 'bg-primary-600 text-white' : 'text-gray-900',
            ]"
          >
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ item.name || item.title }}
            </span>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 left-0 flex items-center pl-1.5',
                active ? 'text-white' : 'text-primary-600',
              ]"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'

const props = defineProps({
  options: {
    type: Array,
    default: [],
  },
  resetDropdown: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['updateSelected'])

const items = props.options
const selectedItem = ref([])
const query = ref('')

watch(
  () => props.resetDropdown,
  () => {
    console.log('resetDropdown')
    selectedItem.value = []
  }
)
const filteredItems = computed(() =>
  query.value === ''
    ? items
    : items.filter((item) => {
        return item.name.toLowerCase().includes(query.value.toLowerCase())
      })
)

const handleTest = () => {
  emit('updateSelected', selectedItem.value)
}
</script>
