import { createApp, provide, h } from 'vue/dist/vue.esm-bundler.js'
import { DefaultApolloClient } from '@vue/apollo-composable'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import { router } from '@/router'
import { apolloClient } from '@/helpers/apolloClient.js'
import '@/stylesheets/tailwind.css'
import '@/stylesheets/print.css'
import pinia from '@/stores/store'

import App from '@/components/App.vue'

// Bugsnag configuration
if (import.meta.env.MODE !== 'development') {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    releaseStage: import.meta.env.VITE_ENV,
  })
}

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },

  render: () => h(App),
})

const bugsnagVue = Bugsnag.getPlugin('vue')
if (import.meta.env.MODE !== 'development') {
  app.use(bugsnagVue)
}
app.use(router)
app.use(pinia)

document.addEventListener('DOMContentLoaded', () => {
  app.mount('[data-behavior="vue"]')
})

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails', import.meta.env.MODE)

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
