<template>
  <div>
    <div class="sm:hidden">
      <label for="tabs" class="sr-only">Select a tab</label>
      <select
        id="tabs"
        name="tabs"
        class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
      >
        <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <p
            v-for="tab in tabs"
            @click="handleTabChange(tab)"
            :key="tab.name"
            :class="[
              tab.current
                ? 'border-indigo-500 text-primary-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer',
            ]"
            :aria-current="tab.current ? 'page' : undefined"
          >
            <component
              :is="tab.icon"
              :class="[
                tab.current ? 'text-primary-500' : 'text-gray-400 group-hover:text-gray-500',
                '-ml-0.5 mr-2 h-5 w-5',
              ]"
              aria-hidden="true"
            />
            <span>{{ tab.name }}</span>
          </p>
        </nav>
      </div>
    </div>
  </div>
  <Loader v-if="loading" />
  <div v-else>
    <div class="mt-10" v-if="selected.name == 'Profile'">
      <div class="flex flex-col">
        <div class="border rounded-lg border pb-6 border-gray-200">
          <div
            class="bg-primary-100 flex items-center border-b border-gray-200 justify-between px-7"
          >
            <h1 class="py-5 text-2xl font-semibold text-gray-900">Company Profile</h1>
          </div>
          <div class="px-10 py-14">
            <form @submit.prevent="onSubmit" class="space-y-4">
              <div class="grid grid-cols-2 items-center gap-4 max-w-4xl mx-auto px-16">
                <div class="w-80 col-span-1">
                  <BaseInput
                    name="name"
                    v-model="name"
                    @change="handleNameChange"
                    type="text"
                    label="Company Name"
                    :error="errors.name"
                    required
                  />
                </div>
                <div class="w-80 col-span-1">
                  <BaseInput
                    name="email"
                    v-model="email"
                    @change="handleEmailChange"
                    type="email"
                    label="Company Email"
                    :error="errors.email"
                    required
                  />
                </div>

                <div class="w-80 col-span-1">
                  <BaseInput
                    name="phone"
                    type="tel"
                    v-model="phone"
                    @change="handlePhoneChange"
                    label="Company Phone"
                    :error="errors.phone"
                  />
                </div>

                <div class="w-80 col-span-1">
                  <BaseInput
                    name="website"
                    v-model="website"
                    @change="handleWebsiteChange"
                    type="text"
                    label="Company Website"
                    :error="errors.website"
                    required
                  />
                </div>

                <div class="w-80 col-span-1">
                  <BaseInput
                    name="address"
                    v-model="address"
                    @change="handleAddressChange"
                    type="text"
                    label="Address"
                    :error="errors.address"
                    required
                  />
                </div>

                <div class="w-80 col-span-1">
                  <BaseInput
                    name="city"
                    v-model="city"
                    type="text"
                    @change="handleCityChange"
                    label="City"
                    :error="errors.city"
                    required
                  />
                </div>

                <div class="col-span-1 w-80">
                  <BaseAutocomplete
                    label="Select State"
                    :searchArray="statesQuery.result.value.states.nodes"
                    v-model="state"
                    :required="true"
                  />
                </div>

                <div class="w-80 col-span-1">
                  <BaseInput
                    name="zipcode"
                    type="text"
                    v-model="zipcode"
                    @change="handleZipcodeChange"
                    label="Zip Code"
                    :error="errors.zipcode"
                    required
                  />
                </div>

                <div class="pt-5 flex justify-start col-span-2">
                  <button
                    type="submit"
                    class="inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-10" v-else>
      <div class="border rounded-lg border pb-6 border-gray-200">
        <div class="bg-primary-100 flex items-center border-b border-gray-200 justify-between px-7">
          <h1 class="py-5 text-2xl font-semibold text-gray-900">About {{ companyData.name }}</h1>
        </div>

        <div class="p-10">
          <TextEditor
            title="About Company"
            :showSection="companyData.showAbout"
            :text="companyData.about"
            @update-text="updateEditValues($event, 'about')"
            @update-show-section="updateEditValues($event, 'showAbout')"
          />
        </div>
        <div class="p-10">
          <TextEditor
            title="Company Mission"
            :showSection="companyData.showMission"
            :text="companyData.mission"
            @update-text="updateEditValues($event, 'mission')"
            @update-show-section="updateEditValues($event, 'showMission')"
          />
        </div>
        <div class="p-10">
          <TextEditor
            title="Who we serve"
            :showSection="companyData.showWhoWeServe"
            :text="companyData.whoWeServe"
            @update-text="updateEditValues($event, 'whoWeServe')"
            @update-show-section="updateEditValues($event, 'showWhoWeServe')"
          />
        </div>
        <div class="p-10">
          <TextEditor
            title="Reasons to choose company"
            :showSection="companyData.showReasonsToChoose"
            :text="companyData.reasonsToChoose"
            @update-text="updateEditValues($event, 'reasonsToChoose')"
            @update-show-section="updateEditValues($event, 'showReasonsToChoose')"
          />
        </div>
        <div class="p-10">
          <TextEditor
            title="Terms and Conditions"
            :showSection="companyData.showTermsAndConditions"
            :text="companyData.termsAndConditions"
            @update-text="updateEditValues($event, 'termsAndConditions')"
            @update-show-section="updateEditValues($event, 'showTermsAndConditions')"
          />
        </div>
        <div class="p-10">
          <TextEditor
            title="Reference List"
            :showSection="companyData.showReferenceList"
            :text="companyData.referenceList"
            @update-text="updateEditValues($event, 'referenceList')"
            @update-show-section="updateEditValues($event, 'showReferenceList')"
          />
        </div>
        <div class="grid grid-cols-4 items-center px-12">
          <div class="flex items-center text-sm font-medium text-gray-500">Company Logo</div>
          <div class="p-6 w-96">
            <dd class="bg-primary-50 mt-1 text-sm text-gray-900 sm:mt-0">
              <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                <li class="flex items-center py-3 pl-3 pr-4 text-sm">
                  <div class="flex w-0 flex-1 items-center px-5">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                    <span class="ml-2 w-0 flex-1 truncate"></span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <input
                      id="logo"
                      class="block w-full text-sm text-primary-600 cursor-pointer"
                      type="file"
                      ref="logoFileInput"
                      @change="uploadLogo"
                    />
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </div>

        <div class="grid grid-cols-4 items-center px-12">
          <div class="flex items-center text-sm font-medium text-gray-500">
            Certificate of liability insurance
          </div>
          <div class="p-6 w-96">
            <dd class="bg-primary-50 mt-1 text-sm text-gray-900 sm:mt-0">
              <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                <li class="flex items-center py-3 pl-3 pr-4 text-sm">
                  <div class="flex w-0 flex-1 items-center px-5">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                    <span class="ml-2 w-0 flex-1 truncate"></span>
                  </div>
                  <div class="flex ml-4 flex-shrink-0">
                    <input
                      id="certificate"
                      class="block w-full text-sm text-primary-600 cursor-pointer"
                      type="file"
                      ref="certificateFileInput"
                      @change="uploadCertificate"
                    />
                    <XCircleIcon
                      v-if="companyData.certificateOfLiabilityInsuranceUrl"
                      class="h-8 w-8 text-failure-600 cursor-pointer"
                      @click="removeCertificate"
                    />
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </div>

        <div class="grid grid-cols-4 items-center px-12">
          <div>
            <p class="text-sm font-medium text-gray-500">Company Watermark</p>
            <p class="text-xs font-normal text-gray-600">
              Width should be 600 pixels and height should be 200 pixels
            </p>
          </div>
          <div class="p-6 w-96">
            <dd class="bg-primary-50 mt-1 text-sm text-gray-900 sm:mt-0">
              <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                <li class="flex items-center py-3 pl-3 pr-4 text-sm">
                  <div class="flex w-0 flex-1 items-center px-5">
                    <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                    <span class="ml-2 w-0 flex-1 truncate"></span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <input
                      id="logo"
                      class="block w-full text-sm text-primary-600 cursor-pointer"
                      type="file"
                      ref="watermarkFileInput"
                      @change="uploadWatermark"
                    />
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </div>

        <div class="px-10 py-10 flex justify-start col-span-2">
          <button
            @click="handleUpdateCompanyDetails"
            type="submit"
            class="inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <Toast v-if="toast.showToast" :title="toast.title" />
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import TextEditor from '@/components/layout/TextEditor.vue'
import { object, string, number } from 'yup'
import { useQuery, useMutation } from '@vue/apollo-composable'
import axios from 'axios'

import { getCookie } from '../utils/utility_methods'
import { BuildingOfficeIcon, UserIcon, PaperClipIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import Loader from '@/components/layout/Loader.vue'
import BaseInput from '@/components/layout/BaseInput.vue'
import BaseAutocomplete from '@/components/layout/BaseAutocomplete.vue'
import Toast from '@/components/layout/Toast.vue'
import GET_COMPANY_DATA from '../graphql/queries/getCompanyData.gql'
import GET_STATES from '../../javascript/graphql/queries/getStates.gql'
import CREATE_OWNER from '../graphql/mutations/createCompanyOwner.gql'
import UPDATE_OWNER from '../graphql/mutations/updateCompanyOwner.gql'
import GET_LOCATION_MANAGER from '../graphql/queries/getLocationManagers.gql'
import UPDATE_COMPANY_DETAILS from '../graphql/mutations/updateCompanyDetails.gql'
import UPDATE_ADDRESS from '../graphql/mutations/updateAddress.gql'
import CREATE_ADDRESS from '../graphql/mutations/createAddress.gql'

const companyDataQuery = useQuery(GET_COMPANY_DATA)
const statesQuery = useQuery(GET_STATES)
const locationManagerQuery = useQuery(GET_LOCATION_MANAGER)
const companyOwnerCreate = useMutation(CREATE_OWNER)
const companyOwnerUpdate = useMutation(UPDATE_OWNER)
const updateCompanyDetails = useMutation(UPDATE_COMPANY_DETAILS)
const updateAddress = useMutation(UPDATE_ADDRESS)
const createAddress = useMutation(CREATE_ADDRESS)

const inputObject = ref(null)
const certificateFileInput = ref(null)
const logoFileInput = ref(null)
const watermarkFileInput = ref(null)

const toast = ref({ showToast: false, title: '' })
const companyData = ref(null)
const tabs = ref([
  { name: 'Profile', icon: UserIcon, current: true },
  { name: 'About Company', icon: BuildingOfficeIcon, current: false },
])

const selected = computed(() => tabs.value.filter((item) => item.current)[0])
const loading = computed(
  () =>
    companyDataQuery.loading.value ||
    statesQuery.loading.value ||
    locationManagerQuery.loading.value
)

watch(companyDataQuery.result, (value) => {
  companyData.value = value.organization
  inputObject.value = { id: value.organization.id }
  if (value.organization.owner.nodes[0]) {
    const data = JSON.parse(JSON.stringify(value))
    name.value = data.organization.owner.nodes[0].name
    email.value = data.organization.owner.nodes[0].email
    phone.value = data.organization.owner.nodes[0].phone
    website.value = data.organization.owner.nodes[0].website
    address.value = data.organization.owner.nodes[0].address.address1
    city.value = data.organization.owner.nodes[0].address.city
    zipcode.value = data.organization.owner.nodes[0].address.zipcode
    state.value = data.organization.owner.nodes[0].address.state
  }
})

const validationSchema = object({
  name: string().required(),
  email: string().required(),
  phone: string()
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  website: string().nullable(true),
  address: string().required(),
  city: string().required(),
  zipcode: string().required(),
  state: object().required(),
})

const { handleSubmit, errors } = useForm({
  validationSchema,
})

const { value: name, handleChange: handleNameChange } = useField('name')
const { value: email, handleChange: handleEmailChange } = useField('email')
const { value: phone, handleChange: handlePhoneChange } = useField('phone')
const { value: website, handleChange: handleWebsiteChange } = useField('website')
const { value: city, handleChange: handleCityChange } = useField('city')
const { value: address, handleChange: handleAddressChange } = useField('address')
const { value: zipcode, handleChange: handleZipcodeChange } = useField('zipcode')
const { value: state, handleChange: handleStateChange } = useField('state')

const handleTabChange = (selectedItem) => {
  tabs.value = tabs.value.map((item) =>
    item.name == selectedItem.name ? { ...item, current: true } : { ...item, current: false }
  )
}
const onSubmit = handleSubmit(async (values) => {
  if (!companyDataQuery.result.value.organization.owner.nodes.length) {
    const ownerObject = {
      name: values.name,
      phone: values.phone,
      email: values.email,
      website: values.website,
    }
    const addressResponse = await createAddress.mutate({
      address1: values.address,
      city: values.city,
      zipcode: values.zipcode,
      stateId: values.state.id,
    })
    companyOwnerCreate.mutate({
      input: {
        input: {
          ...ownerObject,
          addressId: addressResponse.data.addressCreate.address.id,
        },
      },
    })
  } else {
    await companyOwnerUpdate.mutate({
      input: {
        input: {
          id: companyDataQuery.result.value.organization.owner.nodes[0].id,
          name: values.name,
          phone: values.phone,
          email: values.email,
          website: values.website,
        },
      },
    })
    await updateAddress.mutate({
      input: {
        input: {
          id: companyDataQuery.result.value.organization.owner.nodes[0].address.id,
          address1: values.address,
          city: values.city,
          zipcode: values.zipcode,
          stateId: values.state.id,
        },
      },
    })
  }
  await companyDataQuery.refetch()
  toast.value = { showToast: true, title: 'Company Profile updated Successfully!' }
})

const handleUpdateCompanyDetails = async () => {
  await updateCompanyDetails.mutate({ input: { input: inputObject.value } })
  await companyDataQuery.refetch()
  toast.value = { showToast: true, title: 'Company Data updated Successfully!' }
}

const updateEditValues = (value, key) => {
  inputObject.value = { ...inputObject.value, [key]: value }
}

const uploadCertificate = async () => {
  const file = certificateFileInput.value.files[0]
  const formData = new FormData()
  formData.append('organization[certificate_of_liability_insurance]', file)
  try {
    await axios.post('/organizations/attach_certificate_of_liability', formData, {
      headers: {
        'X-CSRF-Token': getCookie('csrf_token'),
        'Content-Type': 'multipart/form-data',
      },
    })
    toast.value = { showToast: true, title: 'Certificate updated Successfully!' }
  } catch (error) {
    console.log(error)
  }
}

const uploadLogo = async () => {
  const file = logoFileInput.value.files[0]
  const formData = new FormData()
  formData.append('organization[logo]', file)
  try {
    await axios.post('/organizations/attach_certificate_of_liability', formData, {
      headers: {
        'X-CSRF-Token': getCookie('csrf_token'),
        'Content-Type': 'multipart/form-data',
      },
    })
    toast.value = { showToast: true, title: 'Company logo updated Successfully!' }
  } catch (error) {
    console.log(error)
  }
}

const uploadWatermark = async () => {
  const file = watermarkFileInput.value.files[0]
  const formData = new FormData()
  formData.append('organization[watermark]', file)
  try {
    await axios.post('/organizations/attach_certificate_of_liability', formData, {
      headers: {
        'X-CSRF-Token': getCookie('csrf_token'),
        'Content-Type': 'multipart/form-data',
      },
    })
    toast.value = { showToast: true, title: 'Company Watermark updated Successfully!' }
  } catch (error) {
    console.log(error)
  }
}

const removeCertificate = async () => {
  try {
    await axios.post(
      '/organizations/remove_certificate',
      {},
      {
        headers: {
          'X-CSRF-Token': getCookie('csrf_token'),
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    toast.value = { showToast: true, title: 'Certificate removed Successfully!' }
    await companyDataQuery.refetch()
  } catch (error) {
    console.log(error)
  }
}
</script>
