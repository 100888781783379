<template>
  <Loader v-if="loading" />

  <div class="sm:col-span-2 px-4">
    <h1 class="p-1 bg-primary-200 text-1xl font-bold text-black">{{ service }} Service</h1>
    <draggable
      class="dragArea list-group"
      :list="populatedQuestions"
      :group="{ name: 'people', pull: 'clone', put: false }"
      item-key="id"
      @change="updateRow"
    >
      <template #item="{ element }">
        <div class="list-group-item">
          <li class="border-b-2 flex items-center justify-between py-3 pl-3 pr-4 text-sm">
            {{ processedTemplate(element) }}
          </li>
        </div>
      </template>
    </draggable>
    <div
      v-if="subsectionPopulatedQuestions.length !== 0"
      v-for="section in subsectionPopulatedQuestions"
      class="mt-2"
    >
      <div v-if="section.questions.length !== 0">
        <h1 class="p-1 text-sm font-bold text-black">{{ section.title }}</h1>
        <draggable
          class="dragArea list-group"
          :list="section.questions"
          item-key="id"
          @change="updateRow"
        >
          <template #item="{ element }">
            <div class="list-group-item">
              <li class="border-b-2 flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                {{ element.populatedTemplate }}
              </li>
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <div class="flex justify-between my-3">
      <p>Total Items {{ questions.length + totalSectionQuestions }}</p>
      <p>{{ formatNumber(total) }} ____________ Initial If Accepted</p>
    </div>
    <div class="flex justify-end my-3">
      <p>
        *Price is valid through
        {{ props.validDate ? formatIsoDateTime(props.validDate) : '_________________' }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'
import draggable from 'vuedraggable'
import { useMutation } from '@vue/apollo-composable'

import { formatNumber, formatIsoDateTime } from '../../../utils/utility_methods'
import UPDATE_WRITEUP_QUESTION from '../../../graphql/mutations/updateWriteupQuestion.gql'
import Loader from '@/components/layout/Loader.vue'

const props = defineProps({
  questions: {
    type: Array,
    default: [],
    required: true,
  },
  total: {
    default: 0,
  },
  service: {
    type: String,
  },
  subsection: {
    type: Array,
    default: [],
  },
  validDate: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['refetchWriteupData'])
const loading = ref(false)
const updateWriteupQuestion = useMutation(UPDATE_WRITEUP_QUESTION)

const populatedQuestions = ref([])
const subsectionPopulatedQuestions = ref([])

onMounted(() => {
  populatedQuestions.value = [...props.questions].sort((a, b) => a.position - b.position)
  subsectionPopulatedQuestions.value = props.subsection.map((item) => ({
    title: item.title,
    questions: [...item.questions].sort((a, b) => a.position - b.position),
  }))
})

const totalSectionQuestions = computed(() =>
  subsectionPopulatedQuestions.value.reduce((sum, item) => sum + item.questions.length, 0)
)

const updateRow = async (dragElement) => {
  const questions = props.subsection.find(
    (item) => item.title == dragElement.moved.element.section.name
  )
    ? props.subsection.find((item) => item.title == dragElement.moved.element.section.name)
        ?.questions
    : props.questions
  loading.value = true
  const newPosition = dragElement.moved.newIndex + 1
  const oldPosition = dragElement.moved.oldIndex + 1
  for (const row of questions) {
    if (row.id == dragElement.moved.element.id) {
      await updateWriteupQuestion.mutate({
        input: { input: { id: row.id, position: newPosition } },
      })
    } else if (row.position !== 1 && row.position <= newPosition && row.position > oldPosition) {
      await updateWriteupQuestion.mutate({
        input: { input: { id: row.id, position: row.position - 1 } },
      })
    } else if (row.position < oldPosition && row.position >= newPosition) {
      await updateWriteupQuestion.mutate({
        input: { input: { id: row.id, position: row.position + 1 } },
      })
    }
  }
  emit('refetchWriteupData')
  loading.value = false
}

const processedTemplate = (question) => {
  if (question.templateBoundProperties.find((property) => property.variableName === 'smr')) {
    let resultTemplate = question.template
    question.templateBoundProperties.forEach((property) => {
      const placeholder = `{{${property.variableName}}}`
      if (property.variableName !== 'smr') {
        resultTemplate = resultTemplate.replace(placeholder, property.value)
      } else {
        resultTemplate = resultTemplate.replace(placeholder, '')
      }
    })
    return resultTemplate
  } else {
    return question.populatedTemplate
  }
}
</script>
