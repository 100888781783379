<template>
  <div>
    <button
      @click="toggleAccordion()"
      class="w-full text-start hover:bg-primary-50"
      :aria-expanded="isOpen"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="flex min-w-0 flex-1 items-center">
          <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <p class="truncate text-sm font-medium text-primary-600">
                {{ proposal.title }}
              </p>
              <div class="sm:flex">
                <p class="mt-2 flex items-center text-sm text-gray-500">
                  <EnvelopeIcon
                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span class="truncate">{{ proposal.author.email }}</span>
                </p>
                <p
                  v-if="proposal.publicWorks"
                  class="mt-2 flex items-center text-sm text-gray-500 sm:ml-6"
                >
                  <BriefcaseIcon
                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  Public Work
                </p>
              </div>
            </div>
            <div class="hidden md:block">
              <div>
                <p
                  class="inline-flex rounded-full bg-success-100 px-2 text-xs font-semibold leading-5 text-success-800"
                >
                  {{ proposal.status }}
                </p>
                <div class="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon
                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <p>
                    <time :datetime="proposal.updatedAt">{{
                      Moment(proposal.updatedAt).format('LL')
                    }}</time>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-1 pr-3 text-center">
          <router-link :to="`/estimations/${proposal.id}`" class="block hover:bg-primary-50">
            <p
              class="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-xs font-medium leading-5 text-gray-700 shadow-sm hover:bg-primary-50"
            >
              View
            </p>
          </router-link>
        </div>
        <div
          :id="proposal.id"
          class="w-3 transition-all duration-200 transform"
          :class="{
            'rotate-90': isOpen,
            'rotate-0': !isOpen,
          }"
        >
          <ChevronRightIcon :id="proposal.id" class="h-5 w-5 text-gray-500" aria-hidden="true" />
        </div>
      </div>
    </button>

    <div v-show="isOpen">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Moment from 'moment'
import {
  ChevronRightIcon,
  EnvelopeIcon,
  CalendarIcon,
  MapPinIcon,
  BriefcaseIcon,
} from '@heroicons/vue/20/solid'

const isOpen = ref(false)

const toggleAccordion = () => {
  isOpen.value = !isOpen.value
}

const props = defineProps({
  proposal: {
    type: Object,
  },
})
</script>
